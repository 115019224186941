<template>
  <div>
    <b-card>
      <h1>Hei! Avem vești bune!</h1>
      <hr />
      <b-alert
        class="mt-2"
        variant="warning"
        show
        v-if="
          !status.comunicat_presa ||
          !status.microsite ||
          !status.etichete ||
          !status.afis_a3
        "
      >
        <div class="alert-body">
          <span
            >Atenție – documentele tale au fost generate dar nu într-o formă finală. Nu ai completat anumite informații în secțiunea
            <router-link to="/grantul-meu"
              ><strong>Grantul meu</strong></router-link
            >. (Cel mai probabil - Datele de semnare contract și primire grant sau alte informații). După ce vei completa aceste informații, mesajele de avertizare nu vor mai fi afișate. Îți mulțumim!</span
          >
        </div>
      </b-alert>
      <!--<b-alert class="mt-2" variant="warning" show v-else-if="!m2Paid">-->
        <!--<div class="alert-body">-->
          <!--<span>-->
            <!--ATENȚIE! Nu ai plătit pachetul M2. Confirmă plata accesând-->
            <!--<router-link-->
              <!--:to="{-->
                <!--path: '/',-->
                <!--name: 'pages-account-setting',-->
                <!--params: { to: 'factura-contract' },-->
              <!--}"-->
              <!--&gt;<strong>Factură si Contract</strong></router-link-->
            <!--&gt;.-->
          <!--</span>-->
        <!--</div>-->
      <!--</b-alert>-->
      <h3 v-else>Toate elementele de identitate vizuală sunt pregătite!</h3>

      <section id="card-text-alignment" class="mt-1">
        <b-row class="row">
          <b-col class="column">
            <b-card
              title="Comunicatul tău de presă"
              class="text-center identitate-item"
            >
              <b-img
                class="identitate-item-img"
                :src="
                  require('@/assets/images/m2/identitateVizuala_comunicat-presa.png')
                "
              />

              <b-img
                v-if="!status.comunicat_presa"
                class="warning"
                :src="require('@/assets/images/m2/warning.png')"
              />

              <b-card-text class="text-left">
                Comunicatul de presă este întocmit în conformitate cu art. 6.12.
                din Procedura de implementare a măsurii “Granturi pentru capital
                de lucru acordate IMM-urilor”, cu modelul de comunicat prezentat
                pe imm.gov.ro si cu Manualul de identitate vizuală FSE.
              </b-card-text>
              <a
                href=""
                download
                @click.prevent="downLoadComunicat()"
                class="space-between"
              >
                <div>DESCARCĂ COMUNICATUL IN FORMAT PDF</div>
                <b-img
                  class="pdf-download"
                  :src="require('@/assets/images/m2/pdf-download.png')"
                />
              </a>

              <b-button
                v-show="status.comunicat_presa"
                class="mt-2"
                target="_blank"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                :href="companyId"
              >
                <a>Vezi comunicatul publicat</a>
              </b-button>
              <b-button
                      class="mt-1"
                      target="_blank"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      download
                      href="/m2/obligatii-identitate/comunicat.pdf"
              >
                Obligații identitate vizuală
              </b-button>
            </b-card>
          </b-col>

          <b-col class="column">
            <b-card
              title="Microsite de prezentare"
              class="text-center identitate-item"
            >
              <b-img
                class="identitate-item-img"
                :src="
                  require('@/assets/images/m2/identitateVizuala_website.png')
                "
              />

              <b-img
                v-if="!status.microsite"
                class="warning"
                :src="require('@/assets/images/m2/warning.png')"
              />

              <b-card-text class="text-left">
                Ți-am pregătit un microsite de prezentare al grantului
                obținut.<br />
                Astfel poți trimite adresa site-ului web furnizorilor,
                partenerilor sau il poți distribui pe rețelele sociale.
              </b-card-text>
              <a
                :href="status.link_microsite"
                class="space-between"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>VEZI MICROSITE-ul TĂU</div>
                <div class="icon-wrapper">
                  <feather-icon
                    class="feather-icon"
                    icon="GlobeIcon"
                    size="90"
                  />
                </div>
              </a>
            </b-card>
          </b-col>

          <b-col class="column">
            <b-card
              title="Etichete de inventar"
              class="text-center identitate-item"
            >
              <b-img
                class="identitate-item-img"
                :src="
                  require('@/assets/images/m2/identitateVizuala_etichete.png')
                "
              />

              <b-img
                v-if="!status.etichete"
                class="warning"
                :src="require('@/assets/images/m2/warning.png')"
              />

              <b-card-text class="text-left">
                Pentru informarea publicului cu privire la faptul că mijloacele
                fixe au fost achiziționate în cadrul unui proiect cofinanțat de
                UE, vor fi folosite autocolante sau plăcuțe.
              </b-card-text>
              <a
                href=""
                download
                @click.prevent="downLoadEticheta10()"
                class="space-between"
              >
                <div>DESCARCĂ AUTOCOLANT<br /><strong>10 x 10 cm</strong></div>
                <b-img
                  class="pdf-download"
                  :src="require('@/assets/images/m2/pdf-download.png')"
                />
              </a>
              <a
                href=""
                download
                @click.prevent="downLoadEticheta20()"
                class="space-between"
              >
                <div>DESCARCĂ AUTOCOLANT<br /><strong>20 x 20 cm</strong></div>
                <b-img
                  class="pdf-download"
                  :src="require('@/assets/images/m2/pdf-download.png')"
                />
              </a>
              <b-button
                      class="mt-1"
                      target="_blank"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      download
                      href="/m2/obligatii-identitate/etichete.pdf"
              >
                Obligații identitate vizuală
              </b-button>
            </b-card>
          </b-col>

          <b-col class="column">
            <b-card
              title="Afișul proiectului"
              class="text-center identitate-item"
            >
              <b-img
                class="identitate-item-img"
                :src="require('@/assets/images/m2/identitateVizuala_afis.png')"
              />

              <b-img
                v-if="!status.afis_a3"
                class="warning"
                :src="require('@/assets/images/m2/warning.png')"
              />

              <b-card-text class="text-left">
                Pentru proiecte se va expune public, într-un loc vizibil, dar
                asociat operațiunii, cum ar fi zona de intrare a unei clădiri,
                un afiș cu informații privind proiectul, având dimensiunea
                minimă obligatorie corespunzătoare formatului A3. (Conform MIV).
              </b-card-text>
              <a
                href=""
                download
                @click.prevent="downLoadAfisA3()"
                class="space-between"
              >
                <div>DESCARCĂ AFIȘUL A3 ÎN FORMAT PDF</div>
                <b-img
                  class="pdf-download"
                  :src="require('@/assets/images/m2/pdf-download.png')"
                />
              </a>
              <b-button
                      class="mt-1"
                      target="_blank"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      download
                      href="/m2/obligatii-identitate/afis.pdf"
              >
                Obligații identitate vizuală
              </b-button>
            </b-card>
          </b-col>

          <b-col class="column">
            <b-card
              title="Banner pentru site web"
              class="text-center identitate-item"
            >
              <b-img
                class="identitate-item-img"
                :src="
                  require('@/assets/images/m2/identitateVizuala_banner-website.png')
                "
              />

              <b-img
                v-if="!status.banner"
                class="warning"
                :src="require('@/assets/images/m2/warning.png')"
              />

              <b-card-text class="text-left">
                În cazul în care beneficiarul are un site propriu, acesta
                trebuie să afișeze o scurtă descriere a proiectului, care să
                includă: numele proiectului, scopul și obiectivele proiectului,
                rezultatele preconizate, valoarea totală a acestuia,
                cofinanțare, etc.
              </b-card-text>

              <span v-if="bannerLoading">
                <b-spinner small label="Se generează..."></b-spinner>
              </span>

              <b-form-group
                class="text-left"
                label="Copiază embed-ul de mai jos și inserează-l pe site-ul tău."
                v-else
                label-for="bannerEmbed"
              >
                <b-form-textarea
                  v-model="bannerEmbed"
                  id="bannerEmbed"
                  size="sm"
                  rows="4"
                  placeholder="Code-ul embed pentru site web"
                  onclick="this.focus();this.select()"
                ></b-form-textarea>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </b-card>

    <!--<b-card>-->
      <!--<h1>Toate materialele la tine la birou!</h1>-->

      <!--<div class="row">-->
        <!--<div class="col-xl-12 icons mt-1 mb-1">-->
          <!--<h3 class="mb-1">Ce primești prin curier?</h3>-->
          <!--<b-row class="row">-->
            <!--<b-col class="item col-md-3">-->
              <!--<div class="itemImg">-->
                <!--<b-img :src="require('@/assets/images/m2/autocolant.png')" />-->
                <!--<span>50x</span>-->
              <!--</div>-->
              <!--<span>Autocolant 10cm x 10cm</span>-->
            <!--</b-col>-->
            <!--<b-col class="item col-md-2">-->
              <!--<div class="itemImg">-->
                <!--<b-img :src="require('@/assets/images/m2/autocolant.png')" />-->
                <!--<span>20x</span>-->
              <!--</div>-->
              <!--<span>Autocolant 20cm x 20cm</span>-->
            <!--</b-col>-->
            <!--<b-col class="item col-md-2">-->
              <!--<div class="itemImg">-->
                <!--<b-img :src="require('@/assets/images/m2/autocolant.png')" />-->
                <!--<span>5x</span>-->
              <!--</div>-->
              <!--<span>Afiș A3 Autocolant</span>-->
            <!--</b-col>-->
            <!--<b-col class="item col-md-2">-->
              <!--<div class="itemImg">-->
                <!--<b-img :src="require('@/assets/images/m2/carte.png')" />-->
              <!--</div>-->
              <!--<span>Ghid de implementare M2</span>-->
            <!--</b-col>-->
            <!--&lt;!&ndash;<b-col class="item col-md-2">&ndash;&gt;-->
            <!--&lt;!&ndash;<div class="itemImg">&ndash;&gt;-->
            <!--&lt;!&ndash;<b-img :src="require('@/assets/images/m2/coperta.png')" />&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--&lt;!&ndash;<span>Broșură prezentare M2</span>&ndash;&gt;-->
            <!--&lt;!&ndash;</b-col>&ndash;&gt;-->
            <!--<b-col class="item col-md-3">-->
              <!--<div class="itemImg">-->
                <!--<b-img :src="require('@/assets/images/m2/coperta.png')" />-->
              <!--</div>-->
              <!--<span>Broșură prezentare SMIS</span>-->
            <!--</b-col>-->
          <!--</b-row>-->
        <!--</div>-->

        <!--<b-alert-->
          <!--class="ml-1"-->
          <!--variant="danger"-->
          <!--show-->
          <!--v-if="-->
            <!--!status.comunicat_presa ||-->
            <!--!status.microsite ||-->
            <!--!status.etichete ||-->
            <!--!status.afis_a3-->
          <!--"-->
        <!--&gt;-->
          <!--<div class="alert-body">-->
            <!--<span-->
              <!--&gt;ATENȚIE! Nu ai completat anumite informații în secțiunea-->
              <!--<router-link to="/grantul-meu"-->
                <!--&gt;<strong>Grantul meu</strong></router-link-->
              <!--&gt;. Documentele nu sunt finalizate și nu le putem trimite la-->
              <!--printat.</span-->
            <!--&gt;-->
          <!--</div>-->
        <!--</b-alert>-->
        <!--<b-alert class="ml-1" variant="danger" show v-else-if="!m2Paid">-->
          <!--<div class="alert-body">-->
            <!--<span>-->
              <!--ATENȚIE! Nu ai platit pachetul M2. Documentele nu sunt finalizate-->
              <!--și nu le putem trimite la printat. Confirmă plata accesând-->
              <!--<router-link-->
                <!--:to="{-->
                  <!--path: '/',-->
                  <!--name: 'pages-account-setting',-->
                  <!--params: { to: 'factura-contract' },-->
                <!--}"-->
                <!--&gt;<strong>Factură si Contract</strong></router-link-->
              <!--&gt;.-->
            <!--</span>-->
          <!--</div>-->
        <!--</b-alert>-->
        <!--<div class="col-xl-12" v-else>-->
          <!--<template v-if="!print">-->
            <!--<h5 class="mb-2">-->
              <!--Ești client <M2ManagerPackageText />. Îți mulțumim! Te rugăm să-->
              <!--confirmi că adresa de mai jos și datele de contact sunt corecte.-->
              <!--Apoi, tot ce trebuie să faci este să apeși pe butonul de-->
              <!--confirmare, și ne punem în mișcare.-->
            <!--</h5>-->
            <!--<h3>Datele de livrare</h3>-->
            <!--<validation-observer ref="loginForm" #default="{ invalid }">-->
              <!--<b-form class="auth-login-form" @submit.prevent="sendToPrint">-->
                <!--<b-form-group label="Adresa completă" label-for="address">-->
                  <!--<validation-provider #default="{ errors }" rules="required">-->
                    <!--<b-form-input-->
                      <!--size="lg"-->
                      <!--id="address"-->
                      <!--v-model="address"-->
                      <!--:state="errors.length > 0 ? false : null"-->
                      <!--name="address"-->
                      <!--placeholder="Adresa completă"-->
                    <!--/>-->
                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                  <!--</validation-provider>-->
                <!--</b-form-group>-->

                <!--<b-form-group label="Telefon" label-for="phone">-->
                  <!--<validation-provider #default="{ errors }" rules="required">-->
                    <!--<b-form-input-->
                      <!--size="lg"-->
                      <!--id="phone"-->
                      <!--v-model="phone"-->
                      <!--:state="errors.length > 0 ? false : null"-->
                      <!--name="phone"-->
                      <!--placeholder="Telefon"-->
                    <!--/>-->
                    <!--<small class="text-danger">{{ errors[0] }}</small>-->
                  <!--</validation-provider>-->
                <!--</b-form-group>-->

                <!--<div class="inline-flex">-->
                  <!--<b-button type="submit" variant="primary" :disabled="invalid">-->
                    <!--Datele sunt corecte, trimite curier!-->
                  <!--</b-button>-->
                  <!--<div class="car" :style="sendedToPrint ? 'left: 900%' : ''">-->
                    <!--<b-img-->
                      <!--class="ml-1 carIcon"-->
                      <!--:src="require('@/assets/images/m2/delivery-truck.png')"-->
                    <!--/>-->
                  <!--</div>-->
                <!--</div>-->
              <!--</b-form>-->
            <!--</validation-observer>-->
          <!--</template>-->
          <!--<template v-else>-->
            <!--<h4>-->
              <!--Ai trimis documentele către centrul de printare. În cateva zile le-->
              <!--vei primi la adresa menționată.-->
            <!--</h4>-->
          <!--</template>-->
        <!--</div>-->
      <!--</div>-->
    <!--</b-card>-->

    <!--<PayPopup-->
          <!--v-if="-->
            <!--status.comunicat_presa &&-->
            <!--!loading &&-->
            <!--status.agreement_accepted === null-->
          <!--"-->
          <!--@accepted="confirmAgreement" />-->
  </div>
</template>

<script>
import {
  BRow,
  BBadge,
  BCol,
  BCard,
  BCardText,
  BButton,
  BImg,
  BAlert,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { FormWizard } from 'vue-form-wizard'
import axios from '@axios'
import config from '@/config'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import M2ManagerPackageText from '../m2-pages/components/M2ManagerPackageText'
//import PayPopup from '@/views/pages/account-setting/PayPopup'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BBadge,
    BCol,
    BCard,
    BCardText,
    BButton,
    BImg,
    BAlert,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    FormWizard,
    ToastificationContent,
    BSpinner,
    M2ManagerPackageText,
    //PayPopup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: {
        comunicat_presa: true,
        microsite: true,
        etichete: true,
        banner: true,
        afis_a3: true,
        link_microsite: '',
        agreement_accepted: null
      },
      address: '',
      phone: '',
      sendedToPrint: false,
      companyId: '',
      bannerEmbed: '',
      bannerLoading: true,
      print: [],
      m2Paid: false,
      loading: true,
    }
  },
  created() {

    this.getPrint()
    axios
      .get(config.API_ENDPOINTS.getCompletionStatus)
      .then((response) => {
        this.loading = false
        return this.status = response.data
    })

    axios
      .get(config.API_ENDPOINTS.getBannerEmbed)
      .then(({ data }) => {
        this.bannerEmbed = data
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        this.bannerLoading = false
      })
  },
  mounted() {
    axios
      .get(config.API_ENDPOINTS.getM2data)
      .then(({ data }) => {
        this.companyId =
          'https://smis.ro/stiri/comunicate-presa?cui=' + data.data.company.business_id
        this.address = data.data.company.address
        this.phone = data.data.company.phone
      })
      .catch((error) => console.log(error))
  },
  methods: {
    getPrint() {
      axios
        .get('/m2/get-print')
        .then((response) => {
          this.print = response.data.print
        })
        .catch((error) => {})
    },
    sendToPrint() {
      this.sendedToPrint = true
      axios
        .post('/m2/send-print', { address: this.address, phone: this.phone })
        .then((response) => {
          this.getPrint()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Trimis cu succes',
              icon: 'SendIcon',
              variant: 'success',
              text: 'Materialele au fost trimise catre print.',
            },
          })
        })
        .catch((error) => {})
    },
    downLoadComunicat() {
      this.downloadPDF({
        url: config.API_ENDPOINTS.m2PdfComunicat,
        name: 'comunicat_presa.pdf',
      })
    },
    downLoadEticheta10() {
      this.downloadPDF({
        url: config.API_ENDPOINTS.m2PdfEticheta10,
        name: 'eticheta_inventar_10x10cm.pdf',
      })
    },
    downLoadEticheta20() {
      this.downloadPDF({
        url: config.API_ENDPOINTS.m2PdfEticheta20,
        name: 'eticheta_inventar_20x20cm.pdf',
      })
    },
    downLoadAfisA3() {
      this.downloadPDF({
        url: config.API_ENDPOINTS.m2PdfAfisA3,
        name: 'afis_a3.pdf',
      })
    },
    downloadPDF({ url, name }) {
      axios
        .get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = name
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)

    },
    confirmAgreement() {
      axios
        .post('/m2/accept-agreement')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Înregistrat cu succes',
              icon: 'SendIcon',
              variant: 'success',
              text: 'Îți mulțumim pentru alegerea făcută.',
            },
          })
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              icon: 'SendIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
    },
  },
}
</script>
<style>
.column {
  flex: 1;
  display: flex;
}
.identitate-item > * {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.identitate-item {
  border: 1px solid #01e8c6 !important;
}
.identitate-item-img {
  width: max-content;
  margin: 0 auto;
}
.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  color: #6e6b7b;
}
body.dark-layout .space-between > * {
  color: #b4b7bd;
}
body.dark-layout img.pdf-download {
  filter: grayscale(1) invert(1);
}
.icon-wrapper .feather-icon {
  width: 35px;
  height: 35px;
}
.column .warning {
  width: auto;
  position: absolute;
  right: 20%;
  top: 20%;
}

.icons .item {
  flex-direction: column;
  display: flex;
  text-align: center;
  margin-bottom: 20px;
}
.icons img {
  width: fit-content;
  margin: 0 auto 5px;
}
.itemImg {
  position: relative;
  min-height: 75px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.itemImg > span {
  position: absolute;
  left: 0;
  right: 0;
  top: 18px;
  color: #0cadb3;
  font-size: 18px;
  font-weight: bold;
}
.car {
  position: absolute;
  left: 100%;
  top: 0;
  transition: 3s;
}
.inline-flex {
  display: inline-flex;
  position: relative;
}
</style>
