<template>
    <div>
        <HorecaHeader />
        <div class="row">
			<div class="col-xl-6">
				<div class="card tab-height">
					<div class="card-body">                        
                            <h2>Documente utile</h2>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <ul>
                                        <li><a href="/horeca/oug-224.pdf" download><img src="@/assets/images/horeca/download.png"> OUG 224/2020 Legea 160/2021</a></li>
                                        <li><a href="/horeca/Procedura-HORECA-24.05.2021.doc" download><img src="@/assets/images/horeca/download.png"> Procedura de implementare</a></li>
                                        <li><a href="/horeca/Anexa-1-Formular-inscriere.doc" download><img src="@/assets/images/horeca/download.png"> A1 - Formular de înscriere</a></li>
                                        <li><a href="/horeca/Anexa-2-Contract-de-finantare-26.05.2021.doc" download><img src="@/assets/images/horeca/download.png"> A2 - Model contract de finanțare</a></li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li><a href="/horeca/Anexa-3-Model-Imputernicire.docx" download><img src="@/assets/images/horeca/download.png"> A3 - Model imputernicire</a></li>
                                        <li><a href="/horeca/Anexa-4-Declaratie-ajutor-de-stat.doc" download><img src="@/assets/images/horeca/download.png"> A4 - Declaratie ajutor de stat</a></li>
                                        <li><a href="/horeca/Anexa-5-Declaratie-tip-intreprindere.doc" download><img src="@/assets/images/horeca/download.png"> A5 - Declaratie tip intreprindere</a></li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li><a href="/horeca/Anexa-6-Calcul-intreprinderi.doc" download><img src="@/assets/images/horeca/download.png"> A6 - Calcul intreprinderi</a></li>
                                        <li><a href="/horeca/Anexa-7-Anexa-Raport.docx" download><img src="@/assets/images/horeca/download.png"> A7 - Anexa raport</a></li>
                                        <li><a href="/horeca/Anexa-8-lista-aimmaipe.doc" download><img src="@/assets/images/horeca/download.png"> A8 - Lista AIMMAIPE</a></li>
                                    </ul>
                                </div>
                            </div>
                            <p>Echipa smis.ro face eforturi în actualizarea perioadică a documentelor și informațiilor. Vă rugăm să vizitați și pagina oficială a finanțării.</p>
                        </div>           
                </div>
            </div>
            <div class="col-xl-6">
				<div class="card tab-height">
					<div class="card-body d-flex  ">
                        <div class="title">                           						
						    <h2>Linkuri utile</h2>
                        </div>
                        <div class="d-flex justify-content-between links">
                            <div>
                                <a target="_blank" href="//economie.gov.ro/">
                                    <img src="@/assets/images/m2/logo-guvernul-romaniei.png">
                                    <p>http://economie.gov.ro/</p>
                                </a>
                            </div>
                            <div>
                                <a target="_blank" href="//granturi.imm.gov.ro/">
                                    <img src="@/assets/images/m2/logo-guvernul-romaniei.png">
                                    <p>https://granturi.imm.gov.ro/</p>
                                </a>
                            </div>
                            <div>
                                <a target="_blank" href="//imm.gov.ro/">
                                    <img src="@/assets/images/m2/logo-guvernul-romaniei.png">
                                    <p>http://imm.gov.ro/</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HorecaHeader from './components/HorecaHeader'

    export default {
        
        data: () => ({

        }),
        components: {
            HorecaHeader
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>
h2{
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
}
h2, li a{
    color: #868686;
}
ul li a:hover{
    color: #ff5b48;
}
ul{
    list-style: none;
    padding: 0;
}
ul li{
    position: relative;
    line-height: 22px;
}
ul li, p{
    font-size: 12px;
}
p{
    margin-bottom: 0;
}
.links{
    align-items: center;
    width: 75%;
}
.links img{
    width: 65px;
}
.links a{
    color: #16488b;
    font-size: 13px;
}
.links div{
    text-align: center;
}
.tab-height {
		min-height: 88%;
}
.title{
    width: 20%;
}
@media screen and (max-width: 1500px) {
    .d-flex.card-body{
        flex-direction: column;
    }
    .title{
        width: 100%;
        margin-bottom: 1.5rem;
    }
    .links{
        width: 100%;
    }
    .d-flex.justify-content-between {
        flex-direction: column;
    }
}
@media only screen and (max-width: 757px) {
    .links img{
        width: 40px;
    }
}
</style>

