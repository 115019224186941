import Login from '@/views/pages/authentication/Login'
import Register from '@/views/pages/authentication/Register'
import ForgotPassword from '@/views/pages/authentication/ForgotPassword'
import ResetPassword from '@/views/pages/authentication/ResetPassword'
import EmailConfirmation from '@/views/pages/authentication/EmailConfirmation'

import middleware from '@/middleware'

export default [
  {
    path: '/login',
    name: 'auth-login',
    component: Login,
    beforeEnter: middleware.guest,
    meta: {
      layout: 'full',
    },
  },

  {
    path: '/register',
    name: 'auth-register',
    component: Register,
    beforeEnter: middleware.guest,
    meta: {
      layout: 'full',
    },
  },

  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: ForgotPassword,
    beforeEnter: middleware.guest,
    meta: {
      layout: 'full',
    },
  },

  {
    path: '/reset-password/:token',
    name: 'auth-reset-password',
    component: ResetPassword,
    beforeEnter: middleware.guest,
    meta: {
      layout: 'full',
    },
  },

  {
    path: '/email/verify/:id/:token',
    name: 'auth-verify-email',
    component: EmailConfirmation,
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
