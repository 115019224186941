<template>
	<div>
		<div class="row">
			<div class="col-xl-12">
				<div class="card">
					<div class="card-body">
						<div class="w-100 d-flex justify-content-between">
							<HorecaPageTitle></HorecaPageTitle>
							<div style="align-self: center">
								<p class="m-0 align-right text-obligatii-mare">obligații beneficiari</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xl-4">
				<div class="card tab-height">
					<div class="card-body">
						<h5 class="card-title box-title">
							<span class="text-title">Obligații generale</span> - Menținerea activității
						</h5>
						<div>
							<p class="grant-big-green">Grant mai mic de 200.000 €</p>
							<p class="text-bold mb-0 mt-2">OBLIGAȚIE:</p>
							<p>
								Menținerea activității pentru minim
								<span class="text-bold">6 luni</span> de la data primirii ajutorului
								financiar nerambursabil.
							</p>
						</div>
						<div class="mt-3">
							<p class="grant-big-green">Grant mai mare de 200.000 €</p>
							<p class="text-bold mb-0 mt-2">OBLIGAȚIE:</p>
							<p>
								Menținerea activității pentru minim
								<span class="text-bold">12 luni</span> de la data primirii ajutorului
								financiar nerambursabil.
							</p>
						</div>
						<div class="my-3 text-center">
							<b-img
								class="grafic-obligatii"
								:src="require('@/assets/images/horeca/horecaObligatiiGrafic.png')"
							/>
						</div>
						<div>
							<p>
								Dacă prin acte normative nu se instituie restricţii de natură a impune
								<span class="text-orange"> suspendarea </span> ori întreruperea
								activităţii comerciale. Perioada de suspendare ori întrerupere a
								activităţii comerciale nu se ia în calcul la determinarea termenului de 6
								luni, respectiv 12 de luni.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3">
				<div class="card tab-height">
					<div class="card-body">
						<div>
							<h5 class="card-title box-title">
								<span class="text-title">Plata datoriilor către bugetul central</span>
							</h5>
							<div>
								<p>
									Prin completarea formularului de înscriere beneficiarii își vor lua
									angajamentul de plată a datoriilor restante către bugetul central,
									angajamentul privind menținerea activității pentru 6 luni, respectiv 12
									luni.
								</p>
								<div class="text-center my-2">
									<b-img
										class="img-guv-anaf"
										:src="
											require('@/assets/images/horeca/logo_HorecaObligatiiGuvAnaf.png')
										"
									/>
								</div>
								<h5 class="card-title box-title mb-0">
									<span class="text-title">Procedura - art 6.1 (2) e)</span>
								</h5>
								<p>
									e) [...] solicitantul nu înregistrează obligaţii fiscale restante şi
									alte creanţe bugetare administrate de organul fiscal central, definit
									potrivit art. 1 pct. 31 din Legea nr. 207/2015 privind Codul de
									procedură fiscală, cu modificările şi completările ulterioare. [...] În
									cazul în care se înregistrează astfel de obligaţii restante,
									beneficiarul se obligă să le achite din ajutorul acordat în cadrul
									programului.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-5">
				<div class="card tab-height">
					<div class="card-body">
						<div>
							<h5 class="card-title box-title mb-0">
								<span class="text-title">ELIGIBILITATE BENEFICIARI</span> - HORECA
							</h5>
							<p class="text-green mt-1">PROCEDURA ART. 3.3</p>
							<div class="tipuri-activitati-lista mt-2">
								<p class="mb-0">
									Beneficiarul poate fi: întreprindere autonomă, întreprindere parteneră
									sau întreprindere legată.
								</p>
								<p class="mb-0">
									O întreprindere autonomă este orice întreprindere care nu este
									clasificată ca întreprindere legată sau ca întreprindere parteneră. O
									întreprindere este autonomă dacă deţine mai puţin de 25% din capitalul
									social sau din drepturile de vot (oricare dintre acestea este mai mare)
									în una ori în mai multe întreprinderi sau dacă una ori mai multe
									întreprinderi nu deţin mai mult de 25% din capitalul social sau din
									drepturile de vot ale întreprinderii în cauză.
								</p>
								<p>
									Întreprinderile partenere sunt toate întreprinderile care nu sunt
									clasificate ca întreprinderi legate și între care există următoarea
									relație: întreprinderea (din amonte) deține, individual sau în comun cu
									una ori mai multe întreprinderi legate, 25% sau mai mult din capitalul
									social ori din drepturile de vot ale unei alte întreprinderi (din aval).
								</p>
								<p class="font-weight-bolder my-2">
									Întreprinderile legate sunt întreprinderile între care există oricare
									dintre următoarele raporturi:
								</p>
								<p class="mb-0">
									(a) o întreprindere deţine majoritatea drepturilor de vot ale
									acţionarilor sau ale asociaţilor unei alte întreprinderi;
								</p>
								<p class="mb-0">
									(b) o întreprindere are dreptul de a numi sau revoca majoritatea
									membrilor organelor de administrare, de conducere sau de supraveghere
									ale unei alte întreprinderi;
								</p>
								<p class="mb-0">
									(c) o întreprindere are dreptul de a exercita o influenţă dominantă
									asupra altei întreprinderi în temeiul unui contract încheiat cu
									întreprinderea în cauză sau în temeiul unei prevederi din contractul de
									societate sau din statutul acesteia;
								</p>
								<p class="mb-0">
									(d) o întreprindere care este acţionar sau asociat al unei alte
									întreprinderi şi care controlează singură, în baza unui acord cu alţi
									acţionari sau asociaţi ai acelei întreprinderi, majoritatea drepturilor
									de vot ale acţionarilor sau ale asociaţilor întreprinderii respective.
								</p>
								<p>
									Întreprinderile care întreţin, cu una sau mai multe întreprinderi,
									relaţiile la care se face referire la literele (a)-(d) sunt considerate
									întreprinderi legate.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xl-7">
				<div class="card tab-height">
					<div class="card-body">
						<h5 class="card-title box-title">
							<span class="text-title">Alte obligații</span>
						</h5>
						<div>
							<p>
								Aplicanții au obligația de a urmări informațiile referitoare la Schemă
								postate pe site-ul MEAT pe toată perioada de implementare (înscriere,
								verificare, clarificări, semnare contract, efectuare plăți, monitorizare),
								precum și în contul creat la înscriere, denumit în continuare front office
								(FO).
							</p>
						</div>
						<div class="d-flex justify-content-between my-2 guvro-logos">
							<div>
								<a class="guvernulWidget" href="//economie.gov.ro/" target="_blank">
									<b-img
										class="img-guv"
										:src="require('@/assets/images/m2/logo-guvernul-romaniei.png')"
									/>
									<h6 class="text-logo-guv">http://economie.gov.ro/</h6>
								</a>
							</div>
							<div>
								<a
									class="guvernulWidget"
									href="//granturi.imm.gov.ro/"
									target="_blank"
								>
									<b-img
										class="img-guv"
										:src="require('@/assets/images/m2/logo-guvernul-romaniei.png')"
									/>
									<h6 class="text-logo-guv">https://granturi.imm.gov.ro/</h6>
								</a>
							</div>
							<div>
								<a class="guvernulWidget" href="//imm.gov.ro/ro/" target="_blank">
									<b-img
										class="img-guv"
										left
										:src="require('@/assets/images/m2/logo-guvernul-romaniei.png')"
									/>
									<h6 class="text-logo-guv">http://imm.gov.ro/</h6>
								</a>
							</div>
						</div>
						<div>
							<p>
								Operatorii economici beneficiari de ajutor în cadrul Schemei, vor ţine o
								evidenţă specifică a ajutoarelor primite conform prezentei Scheme, a altor
								scheme de stat şi a altor ajutoare de stat. <span class="font-weight-bolder"> Aceştia sunt obligaţi să
								arhiveze evidenţa specifică şi să o păstreze o perioadă de 10 ani.</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HorecaPageTitle from './components/HorecaPageTitle'
	import HorecaLogo from './components/HorecaLogo'
	import { BCardBody, BImg, BCard } from "bootstrap-vue";

	export default {
		data: () => ({}),
		components: {
			HorecaPageTitle,
			HorecaLogo,
			BCardBody,
			BImg,
			BCard,
		},
		mounted() {},
		methods: {},
	};
</script>

<style scoped>
	h5 {
		color: #898a93 !important;
	}
	p {
		font-size: 13px;
		text-align: justify;
		color: #898a93;
		font-weight: 500;
	}
	.tab-height {
		height: 95% !important;
	}
	.horeca-title {
		max-width: 760px;
		align-self: center;
	}
	.box-title {
		font-size: 13px !important;
	}
	.text-title {
		font-weight: 700;
	}
	.text-bold {
		font-weight: bold;
	}
	.text-orange {
		color: #ff5b48;
	}
	.text-green {
		color: #38d7bf;
		font-weight: bold;
	}
	.text-obligatii-mare {
		color: #ff5745;
		font-weight: bolder;
		font-size: 38px;
		line-height: 37px;
	}
	.grant-big-green {
		color: #38d7bf;
		font-weight: bold;
		font-size: 24px;
	}
	.grafic-obligatii {
		width: 100%;
		height: auto;
	}
	.img-guv-anaf {
		width: 100%;
	}
	.img-guv {
		MAX-width: 60px;
		height: 60px;
		border-radius: 50%;
	}
	.text-logo-guv {
		font-size: 11px;
		color: #16488b;
		margin-top: 10px;
	}
	.tipuri-activitati-lista p {
		font-size: 12px;
	}
	.col-xl-8,
	.col-xl-4 {
		margin-bottom: 0;
	}
    @media only screen and (max-width: 1750px) {
		.guvro-logos {
            flex-direction: column;
        }
	}
</style>
