<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
    v-model="activeTab"
  >
    <!-- account settings tab -->
    <b-tab>
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Setări cont</span>
      </template>

      <account-setting-general />
    </b-tab>

    <!-- change password tab -->
    <b-tab>
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Schimbă parola</span>
      </template>

      <account-setting-password />
    </b-tab>

    <!-- services tab -->
    <b-tab>
      <template #title>
        <feather-icon icon="LinkIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Serviciile mele</span>
      </template>

      <account-setting-services :programs="programs" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="CreditCardIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Factură și contract</span>
      </template>

      <account-invoices :programs="programs" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'

import AccountSettingGeneral from './AccountSettingGeneral'
import AccountSettingPassword from './AccountSettingPassword'
import AccountSettingServices from './AccountSettingServices'
import AccountInvoices from './AccountInvoices'

import axios from '@axios'
import config from '@/config'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingServices,
    AccountInvoices,
  },

  data() {
    return {
      activeTab: 2,
      programs: [],
    }
  },

  created() {
    if (!this.$store.state.auth.user.member) {
      this.activeTab = 2
    } else if(this.$route.params.to === 'factura-contract') {
        this.activeTab = 3
    }
  },

  beforeCreate() {
    axios.get(config.API_ENDPOINTS.packages).then(({ data }) => {
      this.programs = data.data
    })
  },
}
</script>
