import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import authRoutes from './routes/auth'
import errorRoutes from './routes/errors'
import todo from './routes/todo'
import pocuManager from './routes/pocu-manager'
import pages from './routes/pages'

import m2_manager from './routes/m2-manager'
import m2_managerPremium from './routes/m2-manager-premium'
import horeca from './routes/horeca'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...authRoutes,
    ...errorRoutes,
    ...pages,
    ...pocuManager,
    ...todo,
    ...m2_manager,
    ...m2_managerPremium,
    ...horeca,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

export default router
