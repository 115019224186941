export default {
  //API_ROOT: 'http://api.stage.smis.ro/api/v1',
  API_ROOT: process.env.VUE_APP_API,
  API_ENDPOINTS: {
    csrf: '/csrf-cookie',
    login: '/login',
    register: '/register',
    logout: '/logout',
    userData: '/user',
    userCompanies: '/user-companies', 
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    updatePassword: '/user/password',
    updateUserInfo: '/user/profile-information',
    updateUserPicture: '/user/picture',
    updateContext: '/user/update-context', 
    verifyEmail: '/email/verify',
    startM2: '/m2/store',
    getM2data: '/m2/show',
    getBannerEmbed: '/m2/get_banner',
    updateM2data: '/m2/update',
    updateCompanyLogo: '/m2/update-logo',
    getCompletionStatus: '/m2/completion-status',
    m2PdfComunicat: '/m2/pdf/comunicat_presa',
    m2PdfComunicatFinal: '/m2/pdf/comunicat_final_presa',
    postDateM2PdfComunicatFinal: '/m2/pdf/post_date_comunicat_final_presa',
    m2PdfEticheta10: '/m2/pdf/eticheta_inventar_10',
    m2PdfEticheta20: '/m2/pdf/eticheta_inventar_20',
    m2PdfAfisA3: '/m2/pdf/afis_a3',
    requestInvoice: '/request_invoice',
    uploadOP: '/upload_op',
    getUploadedOPs: '/uploaded_ops',
    choosePackage: '/choose_package',
    packages: '/packages',
  },
  HOME_ROUTE: '/',
  MY_ACCOUNT_ROUTE: '/',
  LOGIN_ROUTE: '/login',
}
