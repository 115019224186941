import guest from './rules/guest'
import auth from './rules/auth'
import m2customer from './rules/m2customer'
import pocuCustomer from './rules/pocuCustomer'
import horeca from './rules/horeca'

export default {
  guest,
  auth,
  m2customer,
  pocuCustomer,
  horeca
}
