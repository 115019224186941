<template>
	<div>
		<div class="row">
			<div class="col-xl-12">
				<div class="card">
					<div class="card-body">
						<div class="top-title">
							<HorecaPageTitle></HorecaPageTitle>
							<div>
								<p class="m-0" style="text-align: right">buget disponibil</p>
								<p class="buget-disponibil-bold text-line-hight">500.000.000 €</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xl-4 col-lg-6 col-md-12">
				<div class="card" style="height: 90%">
					<div class="card-body horeca-logo">
						<div class="top text-center">
							<div>
								<HorecaLogo></HorecaLogo>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-3 col-lg-3 col-md-5">
				<div class="card" style="height: 90%">
					<div class="card-body box-zile-ramase">
						<div v-if="daysLeft > 0">
							<p class="text-bold-65">{{daysLeft}}</p>
							<h6 class="text-logo mg-up">
								Zile rămase la dispoziție pentru depunerea cererii de finanțare
							</h6>
						</div>
						<div v-else>
							<p class="text-bold-35">
								Perioada de înscriere a expirat.
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-3 col-lg-3 col-md-5">
				<div class="card" style="height: 90%">
					<div class="card-body">
						<a class="guvernulWidget" href="//granturi.imm.gov.ro" target="_blank">
							<b-img
								width="80"
								height="80"
								rounded
								center
								:src="require('@/assets/images/m2/logo-guvernul-romaniei.png')"
							/>
							<h6 class="text-logo">
								Pentru depunere cerere de finanțare Vizitează platforma
								granturi.imm.gov.ro
							</h6>
						</a>
					</div>
				</div>
			</div>

			<div class="col-xl-2 col-lg-3 col-md-7">
				<div class="col-xl-12 card green-bg">
					<p class="text-white text-margin-top">dată lansare apel</p>
					<p class="text-align-right text-white font-bold">{{startDate}} - ora 10:00</p>
				</div>
				<div class="col-xl-12 card red-bg mb-1">
					<p class="text-white text-margin-top font-bold">dată limită depunere</p>
					<p class="text-align-right text-white font-bold">{{endDate}} - ora 20:00</p>
				</div>
			</div>
		</div>

		<div class="row mb-2">
			<div class="col-xl-4 col-lg-6 col-md-12">
				<div>
					<div class="card" style="height: 80%">
						<div class="card-body">
							<h5 class="card-title box-title">
								<span class="text-title"> HORECA </span> - prezentarea programului
							</h5>
							<p class="text-line-hight mb-2">
								Procedura HORECA prevede oferirea de ajutoare de stat pentru restaurante,
								baruri, hoteluri, pensiuni și alte firme din sectorul HoReCa și al
								turismului.
							</p>
							<p class="text-center font-weight-bold mb-2">
								Înscrierile în cadrul Schemei se vor realiza în două etape distincte:
							</p>
							<p class="text-line-hight mb-2">
								<span class="text-etapa-culoare"> Etapa 1 </span> de creare profil, user
								și parol, unde aplicanții vor înregistra cu semnătură electronică atât
								datele reprezentantului legal/împuternicitului, cât și datele
								întreprinderii eligibile. Beneficiarii care au deja profiluri create
								pentru ajutoarele COVID-19 pe baza OUG nr. 130/2020 vor putea folosi
								respectivul profil în cadrul schemei HoReCa.
							</p>
							<p class="text-line-hight mb-2">
								<span class="text-etapa-culoare"> Etapa 2 </span> de înscriere în cadrul
								Schemei, în care aplicanții, cu profil, user și parolă generate în prima
								etapă vor completa formularul electronic de înscriere. Tot în această
								etapă, beneficiarii vor încărca raportul de expertiză contabilă
								extrajudiciar, semnat electronic de expertul contabil, sau a raportul de
								audit (de asigurare), semnat electronic de auditorul financiar.
							</p>
							<p class="text-line-hight mb-2">
								Potrivit Ordonanţei de urgenţă nr. 224/2020 privind unele măsuri pentru
								acordarea de sprijin financiar pentru întreprinderile din domeniul
								turismului, alimentaţiei publice și organizării de evenimente, a căror
								activitate a fost afectată în contextul pandemiei de COVID-19, cu
								modificările și completările ulterioare, baza de calcul a ajutorului va
								trebui certificată şi asumată de către un expert contabil sau o societate
								de expertiză contabilă, membri CECCAR, selectați şi remunerați de către
								aplicant.
							</p>
						</div>
					</div>
					<div class="card" style="height: 17%">
						<div class="card-body">
							<h5 class="card-title box-title font-weight-light">Parteneri media</h5>
							<div class="d-flex justify-content-between align-items-center flex-wrap parteneriMedia">
								<a target="_blank" href="//start-up.ro/horecamanager-aplicatia-gratuita-pentru-accesarea-de-fonduri-pentru-horeca/">
									<b-img
										class="logo-parteneri"
										:src="require('@/assets/images/horeca/logo_Startup.png')"
									/>
								</a>
								<a target="_blank" href="https://www.startupcafe.ro/finantari/p-schema-horeca-aplicatie-web-ghid-de-aplicare-100-gratuit-dezvoltat-din-fonduri-europene">
									<b-img
										class="logo-parteneri-cafe"
										:src="require('@/assets/images/horeca/logo_startupcafe.png')"
									/>
								</a>
								<a target="_blank" href="//hotnews.ro/stiri-esential-24876270-schema-horeca-aplicatie-web-ghid-aplicare-100-gratuit-dezvoltat-din-fonduri-europene.htm">
									<b-img
										class="logo-parteneri"
										:src="require('@/assets/images/horeca/logo_hotnews.gif')"
									/>
								</a>
								<a target="_blank" href="//comisaruldeprahova.ro/2021/06/23/p-schema-horeca-aplicatie-web-ghid-de-aplicare-100-gratuit-dezvoltat-din-fonduri-europene-esential/">
									<b-img
										class="logo-parteneri"
										:src="require('@/assets/images/horeca/comisarul-de-prahova.png')"
									/>
								</a>
								<a target="_blank" href="//impactlocal.ro/aplicatie-gratuita-pentru-schema-de-ajutor-de-stat/">
									<b-img
										class="logo-parteneri"
										:src="require('@/assets/images/horeca/logo-impact-local.png')"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-xl-3 col-lg-12 col-md-6">
				<div class="card box-height">
					<div class="card-body">
						<h5 class="card-title box-title">
							<span class="text-title">Lucruri necesare pentru aplicare</span>
						</h5>
						<p>Pentru a aplica la Procedura HORECA este necesar să pregătești:</p>
						<p class="text-center mb-0 mt-4 text-etapa-culoare">1. Semnătură digitală</p>
						<p class="text-center">
							(dacă nu ai o semnătură digitală îți poți achiziționa una de la un furnizor
							autorizat)
						</p>
						<div class="mt-4 mb-4">
							<div class="logo-box">
								<a href="//alfatrust.ro/" target="_blank">
									<b-img
										class="logo-necesare"
										:src="require('@/assets/images/horeca/logo_alfatrust.png')"
									/>
								</a>
								<a href="//digisign.ro/" target="_blank">
									<b-img
										class="logo-necesare"
										:src="require('@/assets/images/horeca/logo_digisign.png')"
									/>
								</a>
							</div>
							<div class="logo-box mt-3">
								<a href="//www.transsped.ro/" target="_blank">
									<b-img
										class="logo-necesare"
										:src="require('@/assets/images/horeca/logo_tsp.png')"
									/>
								</a>
								<a href="//certsign.ro/ro/" target="_blank">
									<b-img
										class="logo-necesare"
										:src="require('@/assets/images/horeca/logo_certsign.png')"
									/>
								</a>
							</div>
						</div>
						<p class="text-center mb-0 text-etapa-culoare">
							2. Baza de calcul / raport audit
						</p>
						<p class="text-center">
							(aceste costuri le vei suporta din fonduri proprii inainte de depunerea
							cererii)
						</p>
						<div class="mt-5">
							<div class="logo-flex mt-3">
								<a href="//ceccar.ro/ro/" target="_blank">
									<b-img
										class="logo-bottom"
										:src="require('@/assets/images/horeca/logo_CECCAR.png')"
									/>
								</a>
								<p
									class="text-uppercase text-center text-corpul-experitolor-culoare font-weight-bolder"
								>
									Corpul Experţilor Contabili şi Contabililor Autorizaţi din România
								</p>
							</div>
							<div class="logo-flex mt-3">
								<a href="//cafr.ro/" target="_blank">
									<b-img
										class="logo-bottom"
										:src="require('@/assets/images/horeca/logo_cafr.png')"
									/>
								</a>
								<p
									class="text-uppercase text-center text-camera-auditorilor-culoare font-weight-bolder"
								>
									Camera Auditorilor Financiari din România
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-5 col-lg-5 col-md-6">
				<div class="card" style="height: 44%">
					<div class="card-body">
						<h5 class="card-title box-title">
							<span class="text-title"> Cele mai recente noutăți </span>
							- HORECA
						</h5>
						<p class="mb-3">
							1.06.2021 /
							<span class="font-weight-bold">
								Situația privind înscrierile şi plățile pentru Măsura 2 - Granturi pentru
								Capital de lucru la data
							</span>
							Ministerul Economiei, Antreprenoriatului şi Turismului gestionează schema de
							aju...
						</p>
						<p class="mb-3">
							1.06.2021 /
							<span class="font-weight-bold">
								Situația privind înscrierile şi plățile pentru Măsura 2 - Granturi pentru
								Capital de lucru la data
							</span>
							Ministerul Economiei, Antreprenoriatului şi Turismului gestionează schema de
							aju...
						</p>
						<p>
							1.06.2021 /
							<span class="font-weight-bold">
								Situația privind înscrierile şi plățile pentru Măsura 2 - Granturi pentru
								Capital de lucru la data
							</span>
							Ministerul Economiei, Antreprenoriatului şi Turismului gestionează schema de
							aju...
						</p>
					</div>
				</div>
				<div class="card mb-0" style="height: 50%">
					<div class="card-body" style="text-align: center">
						<div class="logo-box-atentie">
							<h5 class="card-title text-atentie">ATENȚIE!</h5>
							<b-img
								class="logo-bottom"
								:src="require('@/assets/images/horeca/warningSign.png')"
							/>
						</div>
						<p>
							Ministerul Economiei, Antreprenoriatului și Turismului a transmis o
							recomandare către posibilii beneficiari ai ajutorului de stat pentru HORECA,
							ca acesștia să pregătească din timp raportul de expertiză contabilă care
							certifică baza de calcul. Acesta este unul dintre documentele obligatorii ce
							se vor atașa solicitării de finanțare și trebuie întocmit conform
							reglementărilor CECCAR – Standardului profesional nr. 35 „Expertize
							contabile” sau, după caz, a raportului de audit, întocmit conform ISRS 4400
							„Misiuni pe baza procedurilor convenite”.
						</p>
						<router-link
							:to="{
								path: '/horeca-expert-auditor',
								name: 'horeca-expert-auditor',
							}"
							><b-button type="submit" class="buton-submit-setari-cont" variant="primary"
								>Caută gratuit un expert/auditor</b-button
							></router-link
						>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xl-4 col-lg-6 col-md-12"></div>
		</div>
	</div>
</template>

<script>
	import HorecaPageTitle from "./components/HorecaPageTitle";
	import HorecaLogo from "./components/HorecaLogo";
	import {
		BRow,
		BLink,
		BCol,
		BCard,
		BCardText,
		BButton,
		BImg,
		BAlert,
		BMedia,
		BMediaAside,
		BMediaBody,
	} from "bootstrap-vue";
	import Logo from "@core/layouts/components/Logo.vue";
    import moment from 'moment'

	export default {
		components: {
			HorecaPageTitle,
			HorecaLogo,
			Logo,
			BRow,
			BCol,
			BLink,
			BCard,
			BCardText,
			BButton,
			BImg,
			BAlert,
			BMedia,
			BMediaAside,
			BMediaBody,
		},
		data() {
			return {
			    startDate: '29.06.2021',
			    endDate: '26.07.2021',
				daysLeft: ''
			};
		},
		mounted() {
//            let duration = moment.duration(this.endDate.format('DD.MM.YYY').diff(this.startDate.format('DD.MM.YYY')));
//            let hours = duration.asHours();
            let admission = moment(moment(), 'DD-MM-YYYY');
            let discharge = moment(this.endDate, 'DD-MM-YYYY');
            let days = discharge.diff(admission, 'days');
            this.daysLeft = days + 1;
		},
		methods: {},
		computed: {},
		created() {},
	};
</script>
<style scoped>
	.parteneriMedia > a {
		padding: 10px;
	}
	.font-bold{
		font-weight: bold;
	}
	.top-title {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	.box-title {
		font-size: 13px !important;
	}
	h5 {
		color: #898a93 !important;
	}
	.buget-disponibil-bold {
		color: #898a93;
		font-weight: bolder;
		font-size: 38px;
		white-space: nowrap;
	}
	.horeca-logo {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.logo-necesare {
		max-width: 150px;
		height: auto;
		width: 100%;
	}
	.logo-parteneri,
	.logo-parteneri-cafe {
		max-width: 100%;
		height: 35px;
		max-height: -webkit-fill-available;
		object-fit: contain;
	}
	.logo-parteneri-cafe {
		height: 70px;
	}
	.logo-bottom {
		max-width: 60px;
		height: auto;
		margin-right: 20px;
	}
	.logo-box,
	.logo-box-atentie {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.logo-box-atentie {
		justify-content: space-between;
		margin: 0;
		align-items: baseline;
	}
	.box-zile-ramase {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.logo-flex {
		display: flex;
	}
	.box-height {
		height: 97%;
	}
	.text-line-hight {
		line-height: 1.8rem;
	}
	.horeca-title {
		max-width: 760px;
		align-self: center;
	}
	p {
		font-size: 13px;
		text-align: justify;
		color: #898a93;
	}
	h6 {
		color: #898a93 !important;
	}
	.text-center,
	.text-logo {
		text-align: center;
	}
	.text-logo {
		font-size: 14px;
		font-weight: 500;
		margin-top: 10px;
	}
	.text-title {
		font-weight: 700;
	}
	.text-bold-65 {
		font-weight: bold;
		font-size: 65px;
		color: #38d7bf;
		text-align: center;
	}
	.text-bold-35 {
		font-weight: bold;
		font-size: 18px;
		line-height: 32px;
		color: #38d7bf;
		text-align: center;
		margin: 0;
	}
	.text-align-right {
		text-align: right;
		font-style: italic;
		font-size: 20px;
	}
	.mg-up {
		margin-top: 30px;
		margin-bottom: -20px;
	}
	.text-margin-top {
		margin-top: 5px;
	}
	.green-bg {
		background-color: #45efd6 !important;
	}
	.red-bg {
		background-color: #ff8476 !important;
	}
	.text-etapa-culoare {
		color: #eb785a;
		font-weight: bolder;
	}
	.text-corpul-experitolor-culoare {
		color: #1d3460;
	}
	.text-camera-auditorilor-culoare {
		color: #004f39;
	}
	.text-atentie {
		color: #4e4e4e !important;
		font-weight: bolder !important;
		font-size: 22px !important;
	}
	.buton-submit-setari-cont {
		color: #fff;
		background-color: #00dfc2;
		border-color: #00dfc2;
		margin: 35px;
		width: 300px;
		max-width: 100%;
		height: 30px;
		font-weight: bold !important;
		padding: 0 !important;
	}
	.btn-primary:hover,
	.btn-primary:focus,
	.btn-primary.focus,
	.btn-primary:not(:disabled):not(.disabled):active {
		color: #fff;
		background-color: #00dfc2;
		border-color: #00dfc2;
	}
	.col-xl-8,
	.col-xl-4 {
		margin-bottom: 0;
	}
	@media only screen and (max-width: 1450px) {
		.buget-disponibil-bold {
			color: #898a93;
			font-weight: bolder;
			font-size: 34px;
		}
	}
</style>
