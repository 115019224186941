<template>
    <div>
        <PaginaInContructie></PaginaInContructie>
    </div>
    <!--<div>-->
        <!--<div>-->
            <!--<b-row class="row">-->
                <!--<b-col class="column" cols="12" sm="12" md="12" xl="7">-->
                    <!--<div class="card">-->
                        <!--<div class="card-body">-->
                            <!--<h4>Granturi pentru capital de lucru. Monografie contabilă și fiscală</h4>-->
                            <!--<p>Granturile sunt forme de finanțare nerambursabile. Acestea se contabilizează similar oricărei sume primite cu caracter nerambursabil, adică sub formă de subvenții.<br>-->
                                <!--Subvențiile reprezintă resurse financiare nerambursabile primite de o entitate sub rezerva îndeplinirii anumitor condiții, printre care și destinația lor.<br>-->
                                <!--Grantul pentru capital de lucru este o finanțare nerambursabilă și va fi înregistrat în contabilitate urmând regimul subvențiilor prevăzut la pct. 392-404 din Reglementările contabile privind situațiile financiare anuale individuale și situațiile financiare anuale consolidate, aprobate prin OMFP 1802/2014.</p>-->
                            <!--<h5 style="font-weight: bold;" class="coloredText">IMPOZITAREA SUMELOR PROVENITE DIN GRANTUL PENTRU CAPITAL DE LUCRU</h5>-->
                            <!--<h4>Reglementare: OMFP 1802/2014</h4>-->
                            <!--<p><strong>Implicatii fiscale: In cazul platitorilor de impozit pe profit </strong>Legea nr.227/2015 privind Codul Fiscal prevede o lista de venituri neimpozabile. Aceasta lista fiind limitativa iar veniturile din subventii nu sunt incluse, prin urmare aceste venituri sunt impozabile. </p>-->
                            <!--<p>Veniturile din subventii sunt impozabile si acopera chetuielile deductibile, de aceea nu se va obtine pierdere/profit si nu rezulta un impozit de plata. Reglementare:  Art.19 si art.23 din Legea 227/2015 privind Codul Fiscal</p>-->
                            <!--<p><strong>In cazul microintreprinderilor</strong> veniturile din subventii se scad din baza impozabila</p>-->
                            <!--<p>Reglementare:  Art. 53 alin 1, lit. d din Legea 227/2015 privind Codul Fiscal</p>-->
                            <!--<p>Conform pct. 397 din reglementări, contabilitatea proiectelor finanțate din subvenții se ține distinct, pe fiecare proiect și sursă de finanțare, potrivit contractelor încheiate, fără a se întocmi situații financiare anuale distincte pentru fiecare asemenea proiect. Din punct de vedere contabil, potrivit prevederilor pct. 392 , în categoria subvențiilor sunt cuprinse subvențiile aferente activelor și subvențiile aferente veniturilor. Acestea pot fi primite de la: guvernul propriu-zis, agențiile guvernamentale sau alte instituții similare naționale sau internaționale.<br><br>-->
                                <!--În conformitate cu pct. 398, alin. 1 din reglementări, subvențiile se recunosc, pe o bază sistematică, drept venituri ale perioadelor corespunzătoare cheltuielilor aferente pe care aceste subvenții urmează să le compenseze, iar conform pct. 396, alin. 1 din aceleași reglementări, subvențiile nu se recunosc ca venituri până în momentul în care va exista siguranța că entitatea va respecta condițiile impuse de acordarea lor și acestea vor fi primite.<br><br>-->
                                <!--Granturile sunt forme de finanțare nerambursabilă cu caracter complementar, care se contabilizează similar oricărei sume primite cu caracter nerambursabil, adică ca și subvenții.<br><br>-->
                                <!--Din punct de vedere contabil, se poate vorbi atât despre subvenții aferente activelor sau pentru investiții, cât și despre subvenții aferente veniturilor  sau  din  exploatare.  Subvențiile aferente veniturilor, care cuprind toate subvențiile, altele decât cele pentru active. În categoria veniturilor din subvenții de exploatare intră cele aferente cifrei de afaceri, pentru materii prime și materiale consumabile, pentru alte cheltuieli externe, pentru plata personalului, pentru asigurări și protecție socială, pentru alte cheltuieli de exploatare, aferente altor venituri, pentru dobânda datorată.<br><br>-->
                                <!--Aceste forme de ajutor nerambursabil se recunosc, pe o baza sistematică, drept venituri ale perioadelor corespunzătoare efectuării cheltuielilor pe care subvențiile urmează să le compenseze.</p>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</b-col>-->
                <!--<b-col class="column" cols="12" sm="12" md="12" xl="5">-->
                    <!--<div class="card">-->
                        <!--<div class="card-body">-->
                          <!--<p>Material dezvoltat de M&A LINE CONSULT EVOLUTION pentru SMIS</p>-->
                            <!--<hr>-->
                            <!--<div class="customInline flex items-center">-->
                              <!--<div>-->
                                <!--<p class="mb-0">-->
                                  <!--Alina Stanciu<br>-->
                                  <!--Consultant fiscal/Expert fiscal judiciar<br>-->
                                  <!--tel: 0799-346-422<br>-->
                                  <!--<a href="//consultevo.ro" target="_blank">www.consultevo.ro</a>-->
                                <!--</p>-->
                              <!--</div>-->
                              <!--<b-img-->
                                  <!--:src="require('@/assets/images/m2/consultevo-logo.png')"-->
                              <!--/>-->
                            <!--</div>-->
                            <!--<hr>-->
                          <!--<h5 style="font-weight: bold;" class="coloredText">Monografie contabila Grant 2- Capital de lucru</h5>-->
                          <!--<p class="font-weight-bolder">1) Inregistrarea subventiilor de primit conform cererii de finantare depuse, cu suma aferenta finantarii nerambursabile (la data semnarii contractului de finantare):</p>-->
                          <!--<p>a) Daca finantarea este alocata pentru cheltuieli deja efectuate si inregistrate in contabilitate:<br>-->
                            <!--<strong>4452 IMPRUMUTURI NERAMBURSABILE CU CARACTER DE SUBVENTII =<br>-->
                            <!--7416 VEN. DIN SUBVENTII DE EXPLOATARE PENTRU ALTE CHELTUIELI DE EXP</strong>-->
                          <!--</p>-->
                          <!--<p>b) Daca cheltuielile urmeaza a fi efectuate:<br>-->
                            <!--<strong>4452 IMPRUMUTURI NERAMBURSABILE CU CARACTER DE SUBVENTII =<br>-->
                            <!--472 VENITURI INREGISTRATE IN AVANS</strong></p>-->
                          <!--<p><strong>2) Incasarea grantului capital de lucru:</strong></p>-->
                          <!--<p><strong>5121 CONTURI LA BANCA IN LEI  =  4452 IMPRUMUTURI NERAMBURSABILE CU CARACTER DE SUBVENTII</strong></p>-->
                          <!--<p><strong>3) Plati catre furnizori/Bugetul de Stat:</strong></p>-->
                          <!--<p class="font-weight-bolder">-->
                            <!--401 FURNIZORI = 5121 CONTURI LA BANCA IN LEI<br>-->
                            <!--4423 TVA DE PLATA = 	5121 CONTURI LA BANCA IN LEI<br>-->
                            <!--4315 CONTR. DE ASIGURARI SOCIALE   = 5121 CONTURI LA BANCA IN LEI<br>-->
                            <!--4316 CONTR. DE ASIGURARI SOCIALE DE SANATATE  =  5121 CONTURI LA BANCA IN LEI<br>-->
                            <!--436 CONTR. ASIGURATORIE DE MUNCA =  5121 CONTURI LA BANCA IN LEI<br>-->
                            <!--444 IMPOZITUL PE VENITURI DE NATURA SALARIILOR = 5121 CONTURI LA BANCA IN LEI<br>-->
                            <!--4411 IMPOZITUL PE PROFIT = 5121 CONTURI LA BANCA IN LEI<br>-->
                            <!--4418 IMPOZITUL PE VENIT = 5121 CONTURI LA BANCA IN LEI<br>-->
                            <!--446	ALTE IMPOZITE, TAXE SI VARSAMINTE ASIMILATE  =  5121 CONTURI LA BANCA IN LEI-->
                          <!--</p>-->
                          <!--<p class="font-weight-bolder">-->
                            <!--4) Achizitia marfurilor, a materiilor prime, consumabilelor, etc.-->
                          <!--</p>-->
                          <!--<p class="font-weight-bolder">371 MARFURI = 401 FURNIZORI<br>-->
                            <!--301 MATERII PRIME = 401 FURNIZORI<br>-->
                            <!--3028 ALTE MATERIALE CONSUMABILE = 401 FURNIZORI</p>-->
                          <!--<p class="text-danger">4426	TVA DEDUCTIBILA (nu este eligibil din grant)</p>-->
                          <!--<p class="font-weight-bolder">5) Inregistrarea cheltuielilor cu marfa, material prima, consumabile, etc</p>-->
                          <!--<p class="font-weight-bolder">607 CHELT. PRIVIND MARFURILE = 371 MARFURI<br>-->
                            <!--601 CHELT. CU MATERIILE PRIME = 301 MATERII PRIME<br>-->
                            <!--6028 CHELT.CU ALTE MAT.CONSUMABILE = 3028 ALTE MATERIALE CONSUMABILE</p>-->
                          <!--<p class="font-weight-bolder">6) Inregistrarea cheltuielilor cu prestari servicii terti, chirii, etc</p>-->
                          <!--<p class="font-weight-bolder">628 ALTE CHELT. CU SERVICIILE EXECUTATE DE TERTI  = 401 FURNIZORI<br>-->
                            <!--612 CHELT. CU REDEVENTE, LOCATIILE DE GESTIUNE SI CHIRIILE  = 401 FURNIZORI</p>-->
                          <!--<p class="font-weight-bolder">7) Concomitent sumele inregistrate pe cheltuieli se reiau pe venituri:-->
                            <!--472 VENITURI INREGISTRATE IN AVANS =  7416 VEN. DIN SUBVENTII DE EXPLOATARE PT.ALTE CHELTUIELI</p>-->
                          <!--<p class="font-weight-bolder" style="font-style: italic;">Mentiune: Trebuie create analitice pentru o situatie cat mai clara</p>-->
                          <!--<div class="text-center">-->
                            <!--<b-button variant="primary" download href="/m2/Monografie-contabila-Grant-2.pdf">Descarcă Monografia contabilă</b-button>-->
                          <!--</div>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</b-col>-->
            <!--</b-row>-->
        <!--</div>-->
        <!--<YoutubeEmbed link="ZZZL4vtUwdY" />-->
    <!--</div>-->
</template>

<script>
    import { BAlert, BImg, BCol, BRow, BButton } from 'bootstrap-vue'
    import store from '@/store'
    import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'
    import PaginaInContructie from '@/views/pages/horeca/components/PaginaInConstructie.vue'

    export default {
        data: () => ({
            isPaid: false,
            isM2Manager: false
        }),
        components: {
            BAlert,
            BImg,
            BCol,
            BRow,
            BButton,
            YoutubeEmbed,
            PaginaInContructie
        },
        mounted() {
            this.showPayPopupHandler()
            this.isM2ManagerHandler()
        },
        methods: {
            isM2ManagerHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isM2Manager = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.id === 2
                })
            },
            showPayPopupHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isPaid = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.program_id === 1 && key.paid_confirmed !== null
                })
            },
        },
    }
</script>

<style scoped>
    .coloredText {
        color: #01e8c6;
    }
    .monografieItem{
        margin-top: 20px;
    }
    .monografieItem p{
        margin: 0;
    }
    .column {
        flex: 1;
        display: flex;
    }
    .items-center {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @media(max-width: 767px) {
      .customInline{
        flex-direction: column;
        align-items: end;
      }
    }
</style>

