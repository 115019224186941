<template>
		<h5 class="m-0 horeca-title">
			<span class="text-title">HORECA</span> - procedura de implementare a schemei de
			ajutoare de stat pentru restaurante, baruri, hoteluri, pensiuni și alte firme din
			sectorul HoReCa și al turismului.
		</h5>
</template>

<script>
	export default {
		name: "Test",
		created() {},
		data() {
			return {};
		},
		props: {},
		methods: {},
	};
</script>

<style scoped>
	.horeca-title {
		max-width: 760px;
		align-self: center;
	}
	.text-title {
		font-weight: 700;
	}
	h5 {
		color: #898a93 !important;
	}
  template {
    align-self: center;
  }
</style>
