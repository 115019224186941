import middleware from '@/middleware'

export default [
  {
    path: '/pocu/proiectul_meu',
    name: 'pocu-proiectul-meu',
    component: () => import('@/views/pocu/ProiectulMeu.vue'),
    beforeEnter: middleware.pocuCustomer,
    meta: {
      pageTitle: 'Proiectul Meu',
      breadcrumb: [
        {
          text: 'POCU Manager',
        },
        {
          text: 'Proiectul Meu',
          active: true,
        }
      ],
      resource: 'Auth',
      active: true,
    }
  },
  {
    path: '/pocu/proiectul_meu/adauga_proiect',
    name: 'pocu-proiectul-meu-adauga',
    component: () => import('@/views/pocu/AdaugaProiect.vue'),
    beforeEnter: middleware.pocuCustomer,
    meta: {
      pageTitle: 'Adauga Proiect',
      breadcrumb: [
        {
          text: 'POCU Manager',
        },
        {
          text: 'Proiectul Meu',
          to: '/pocu/proiectul_meu',
        },
        {
          text: 'Proiect Nou',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pocu/documentele_mele',
    name: 'pocu-documentele-mele',
    component: () => import('@/views/pocu/DocumenteleMele.vue'),
    beforeEnter: middleware.pocuCustomer,
    meta: {
      pageTitle: 'Documentele Mele',
      breadcrumb: [
        {
          text: 'POCU Manager',
        },
        {
          text: 'Documentele Mele',
          active: true,
        },
      ],
      resource: 'Auth',
      active: true,
    },
  },
  {
    path: '/pocu/prezentare_buget',
    name: 'pocu-prezentare-buget',
    component: () => import('@/views/pocu/PrezentareBuget.vue'),
    beforeEnter: middleware.pocuCustomer,
    meta: {
      pageTitle: 'Prezentare Buget',
      breadcrumb: [
        {
          text: 'POCU Manager',
        },
        {
          text: 'Prezentare Buget',
          active: true,
        },
      ],
      resource: 'Auth',
      active: true,
    },
  },
  {
    path: '/pocu/linii_bugetare',
    name: 'pocu-linii-bugetare',
    component: () => import('@/views/pocu/LiniiBugetare.vue'),
    beforeEnter: middleware.pocuCustomer,
    meta: {
      pageTitle: 'Linii de buget',
      breadcrumb: [
        {
          text: 'POCU Manager',
        },
        {
          text: 'Linii de buget',
          active: true,
        },
      ],
      resource: 'Auth',
      active: true,
    },
  },
  {
    path: '/pocu/executia_bugetara',
    name: 'pocu-executia-bugetara',
    component: () => import('@/views/pocu/ExecutiaBugetara.vue'),
    beforeEnter: middleware.pocuCustomer,
    meta: {
      pageTitle: 'Executia Bugetara',
      breadcrumb: [
        {
          text: 'POCU Manager',
        },
        {
          text: 'Linii de buget',
        },
        {
          text: 'Executia Bugetara',
          active: true,
        },
      ],
      resource: 'Auth',
      active: true,
    },
  },
  {
    path: '/pocu/cheltuieli_avizate',
    name: 'pocu-cheltuieli-avizate',
    component: () => import('@/views/pocu/CheltuieliAvizate.vue'),
    beforeEnter: middleware.pocuCustomer,
    meta: {
      pageTitle: 'Cheltuieli Avizate',
      breadcrumb: [
        {
          text: 'POCU Manager',
        },
        {
          text: 'Linii Bugetare',
        },
        {
          text: 'Cheltuieli Avizate',
          active: true,
        },
      ],
      resource: 'Auth',
      active: true,
    },
  },
  {
    path: '/pocu/export_executie_bugetara',
    name: 'pocu-export-executie-bugetara',
    component: () => import('@/views/pocu/ExportExecutieBugetara.vue'),
    beforeEnter: middleware.pocuCustomer,
    meta: {
      pageTitle: 'Export Executie Bugetara',
      breadcrumb: [
        {
          text: 'POCU Manager',
        },
        {
          text: 'Export Executie Bugetara',
          active: true,
        },
      ],
      resource: 'Auth',
      active: true,
    },
  },
]
