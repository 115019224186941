import Error404 from '@/views/error/Error404'
// import UnderMaintenance from '@/views/pages/miscellaneous/UnderMaintenance.vue'

export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: Error404,
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },

  // {
  //   path: '/error-503',
  //   name: 'misc-under-maintenance',
  //   component: UnderMaintenance,
  //   meta: {
  //     layout: 'full',
  //   },
  // },
]
