<template>
	<div>
		<b-img
			class="newsImg"
			:src="require('@/assets/images/horeca/logoHorecaManager.png')"
		/>
	</div>
</template>

<script>
import {
        BImg
    } from 'bootstrap-vue'
	export default {
    components: {
      BImg,
    },
		name: "Test",
		created() {},
		data() {
			return {};
		},
		props: {},
		methods: {},
	};
</script>

<style scoped>
	.newsImg{
		object-fit:cover;
	}
</style>
