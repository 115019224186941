<template>
  <b-card>
    <!-- form -->
    <ValidationObserver ref="changePasswordForm" #default="{ invalid }">
      <b-form @submit.prevent="updatePassword">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Vechea parolă"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Vechea parolă"
                vid="current_password"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="current_password"
                    name="old-password"
                    autocomplete="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Vechea parolă"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group label-for="account-new-password" label="Noua parolă">
              <validation-provider
                #default="{ errors }"
                name="Noua parolă"
                vid="password"
                rules="required|min:8"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="password"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    autocomplete="new-password"
                    placeholder="Noua parolă"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Reintroduceți noua parolă"
            >
              <validation-provider
                #default="{ errors }"
                name="Reintroduceți Noua parolă"
                vid="password_confirmation"
                rules="required|confirmed:password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="password_confirmation"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    autocomplete="retype-password"
                    placeholder="Noua parolă"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-1 mr-1"
              :disabled="invalid"
            >
              Salvare
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Resetare
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from '@axios'
import config from '@/config'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      current_password: '',
      password: '',
      password_confirmation: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    updatePassword() {
      axios
        .put(config.API_ENDPOINTS.updatePassword, {
          current_password: this.current_password,
          password: this.password,
          password_confirmation: this.password_confirmation,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Parolă schimbată',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Parola a fost schimbată cu succes!',
            },
          })

          //reseting fields
          this.current_password = ''
          this.password = ''
          this.password_confirmation = ''
          this.$refs.changePasswordForm.reset()
        })
        .catch((error) => {
          return this.$refs.changePasswordForm.setErrors(
            error.response.data.errors
          )
        })
    },
  },
}
</script>
