<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            v-show="optionsLocal.picture"
            :src="optionsLocal.picture"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-50 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="md"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Selectează fotografie
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          @input="uploadImage"
          plain
        />
        <!--/ upload button -->

        <!-- reset -->
        <!--<b-button-->
        <!--v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
        <!--variant="outline-secondary"-->
        <!--size="sm"-->
        <!--class="mb-75 mr-75"-->
        <!--@click="profileFile = null"-->
        <!--&gt;-->
        <!--Resetare-->
        <!--</b-button>-->
        <!--/ reset -->
        <b-card-text>Acceptă JPG, GIF sau PNG. Maximum 800kB </b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="updateAccountForm">
      <b-form class="mt-2" @submit.prevent="updateAccountInfo">
        <b-row>
          <!--<b-col sm="6">-->
          <!--<b-form-group-->
          <!--label="Username"-->
          <!--label-for="account-username"-->
          <!--&gt;-->
          <!--<b-form-input-->
          <!--v-model="optionsLocal.username"-->
          <!--placeholder="Username"-->
          <!--name="username"-->
          <!--/>-->
          <!--</b-form-group>-->
          <!--</b-col>-->
          <b-col sm="12">
            <b-form-group label="Nume complet" label-for="account-name">
              <validation-provider
                #default="{ errors }"
                name="Nume"
                vid="name"
                rules="required"
              >
                <b-form-input
                  v-model="optionsLocal.name"
                  name="name"
                  placeholder="Nume și prenume"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  autocomplete="chrome-off"
                  v-model="optionsLocal.email"
                  name="email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Telefon" label-for="account-phone">
              <b-form-input
                v-model="optionsLocal.phone"
                name="phone"
                placeholder="Telefon mobil"
              />
            </b-form-group>
          </b-col>

          <!-- alert -->
          <!--<b-col-->
          <!--cols="12"-->
          <!--class="mt-75"-->
          <!--&gt;-->
          <!--<b-alert-->
          <!--show-->
          <!--variant="warning"-->
          <!--class="mb-50"-->
          <!--&gt;-->
          <!--<h4 class="alert-heading">-->
          <!--Your email is not confirmed. Please check your inbox.-->
          <!--</h4>-->
          <!--<div class="alert-body">-->
          <!--<b-link class="alert-link">-->
          <!--Resend confirmation-->
          <!--</b-link>-->
          <!--</div>-->
          <!--</b-alert>-->
          <!--</b-col>-->
          <!--/ alert -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-2 mr-1"
            >
              Salvare
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Resetare
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from '@axios'
import config from '@/config'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      optionsLocal: {
        name: null,
        email: null,
        picture: null,
        phone: null,
      },
      profileFile: null,
      confirmedEmail: null,
    }
  },

  beforeCreate() {
    axios.get(config.API_ENDPOINTS.userData).then(({ data }) => {
      this.optionsLocal = data.data
      this.confirmedEmail = data.data.email
    })
  },

  methods: {
    resetForm() {
      axios.get(config.API_ENDPOINTS.userData).then(({ data }) => {
        this.optionsLocal = data.data
      })
    },

    updateAccountInfo() {
      axios
        .put(config.API_ENDPOINTS.updateUserInfo, { ...this.optionsLocal })
        .then(() => {
          if (this.optionsLocal.email === this.confirmedEmail) {
            this.$store.dispatch('auth/getUserData')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Informațiile contului au fost salvate cu succes',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          } else {
            this.$store.dispatch('auth/logoutForVerification').then(() => {
              this.$router.replace(config.LOGIN_ROUTE)
            })
          }
        })
        .catch((error) => {
          return this.$refs.updateAccountForm.setErrors(
            error.response.data.errors
          )
        })
    },

    uploadImage() {
      const formData = new FormData()
      formData.append('picture', this.profileFile)

      axios
        .post(config.API_ENDPOINTS.updateUserPicture, formData)
        .then((response) => {
          this.optionsLocal.picture = response.data.picture
        })
        .catch((error) => console.log(error))
    },
  },
}
</script>
