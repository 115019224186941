<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <Logo/>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Confirmare Email</h2>
        <p class="mb-2 text-success">
          {{ status }}
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="{ name: 'auth-login' }"
          >Autentificare</b-button
        >
        <b-img
          class="hideMobile"
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'

import store from '@/store'
import config from '@/config'
import axios from '@axios'
import Logo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    Logo,
    BLink,
    BImg,
    BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
      status: '',
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },

  created() {
    const { id, token } = this.$route.params
    const { expires, signature } = this.$route.query

    axios
      .get(
        `${config.API_ENDPOINTS.verifyEmail}/${id}/${token}?expires=${expires}&signature=${signature}`
      )
      .then((response) => {
        this.status = response.data.status
      })
      .catch((error) => (this.status = response.data.status))
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
@media (max-width: 767px) {
  .misc-wrapper {
    flex-direction: column;
    align-items: baseline !important;
  }
  .misc-wrapper .brand-logo {
    position: static !important;
  }
}
</style>
