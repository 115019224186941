<template>
    <div>
        <div class="card">
            <div class="card-body space-between align-items-center pt-1 pb-1">
                <h2 class="m-0">
                    Callcenter AIMMAIPE: <strong>021.9059</strong>
                </h2>
            </div>
        </div>
        <div>
            <b-row class="row customTwoColumn">
                <b-col cols="12" sm="12" md="6" xl="4" v-for="(agentie, agentieID) in this.horecaAipimm" :key="agentieID">
                    <div class="card" :class="agentieID+1 === agentiiData_imm.id ? 'active' : null">
                        <div class="card-body">
                            <h4 class="card-title border-bottom-primary pb-1">{{agentie.name}}</h4>
                            <p><strong>JUDEȚE ARONDATE:</strong> {{agentie.judete}}</p>
                            <p><strong>Telefon:</strong> {{agentie.telefon}}</p>
                            <p><strong>Fax:</strong> {{agentie.fax}}</p>
                            <p class="mb-0"><strong>Email:</strong> {{agentie.email}}</p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import { BAlert, BImg, BCol, BRow, BFormGroup, BFormInput } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import config from '@/config'
    import axios from '@axios'
    import horecaAipimm from './components/horeca-aipimm.json'
    import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'

    export default {
        data: () => ({
            horecaAipimm,
            agentiiData_imm: {
                id: null,
                name: null,
                cif: null,
            },
            agencyName: '',
            isPaid: false,
            isM2Manager: false,
            immCui: null,
            formData: {
                m2_agency_id: null,
            },
        }),
        components: {
            BAlert,
            BImg,
            BCol,
            BRow,
            BFormGroup,
            vSelect,
            BFormInput,
            YoutubeEmbed
        },
        mounted() {
            this.showImm()
        },
        methods: {
            showImm() {
                axios
                    .get(config.API_ENDPOINTS.getM2data)
                    .then(({ data }) => {
                        this.formData = data.data;
                        this.agentiiData_imm = this.agentii_imm.find(agentie => agentie.id === data.data.m2_agency_id)
                    })
                    .catch((error) => console.log('Error: ', error))
            },
        },
    }
</script>

<style scoped>
    .card-body p span{
        margin-right: 15px;
    }
    .card {
        width: 100%;
    }
    @media(max-width: 1450px) {
        .customTwoColumn{
            display: block;
        }
        .customTwoColumn .column{
            max-width: 100%;
        }
    }
</style>