<template>
    <div>
        <div class="row">
            <div class="col-xl-12">
                <b-alert class="mt-0" variant="danger" show v-if="!isM2Manager">
                    <div class="alert-body">
                  <span>
                    ATENȚIE! Nu ai selectat pachetul M2 Manager. Pentru a vedea această pagină alege pachetul M2 Manager accesând
                    <router-link
                            :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'serviciile-mele' },
                      }"
                    ><strong>Serviciile mele</strong></router-link>.
                  </span>
                    </div>
                </b-alert>
                <b-alert class="mt-0" variant="danger" show v-else-if="!isPaid">
                    <div class="alert-body">
                  <span>
                    Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea <router-link
                          :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'factura-contract' },
                      }"
                  ><strong>Factură si Contract</strong></router-link>. În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager)

                  </span>
                    </div>
                </b-alert>
            </div>
        </div>
        <div v-if="isM2Manager">
            <div class="card">
                <div class="card-body space-between align-items-center pt-1 pb-1">
                    <h2 class="m-0">
                        Callcenter AIMMAIPE: <strong>021.9059</strong>
                    </h2>
                </div>
            </div>
            <b-row class="row customTwoColumn">
                <b-col cols="12" sm="12" md="6" xl="4" v-for="(agentie, agentieID) in this.agentii_imm" :key="agentieID">
                  <div class="card" :class="agentieID+1 === agentiiData_imm.id ? 'active' : null">
                    <div class="card-body">
                      <h4 class="card-title border-bottom-primary pb-1">{{agentie.name}}</h4>
                      <p><strong>JUDEȚE ARONDATE:</strong> {{agentie.judete}}</p>
                      <p><strong>Telefon:</strong> {{agentie.telefon}}</p>
                      <p><strong>Fax:</strong> {{agentie.fax}}</p>
                      <p class="mb-0"><strong>Email:</strong> {{agentie.email}}</p>
                    </div>
                  </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import { BAlert, BImg, BCol, BRow, BFormGroup, BFormInput } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import config from '@/config'
    import axios from '@axios'
    import agentii_imm from '../m2-manager/m2-agencies.json'
    import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'

    export default {
        data: () => ({
            agentii_imm,
            agentiiData_imm: {
                id: null,
                name: null,
                cif: null,
            },
            agencyName: '',
            isPaid: false,
            isM2Manager: false,
            immCui: null,
            formData: {
                m2_agency_id: null,
            },
        }),
        components: {
            BAlert,
            BImg,
            BCol,
            BRow,
            BFormGroup,
            vSelect,
            BFormInput,
            YoutubeEmbed
        },
        mounted() {
            this.showPayPopupHandler()
            this.isM2ManagerHandler()
            this.showImm()
        },
        methods: {
            isM2ManagerHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isM2Manager = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.id === 2
                })
            },
            showPayPopupHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isPaid = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.program_id === 1 && key.paid_confirmed !== null
                })
            },
            showImm() {
                axios
                    .get(config.API_ENDPOINTS.getM2data)
                    .then(({ data }) => {
                        this.formData = data.data;
                        this.agentiiData_imm = this.agentii_imm.find(agentie => agentie.id === data.data.m2_agency_id)
                    })
                    .catch((error) => console.log('Error: ', error))
            },
        },
    }
</script>

<style scoped>
    .active{
      border: 2px solid rgb(1, 232, 198);
      border-radius: 0.428rem;
    }
    .coloredText {
        color: #01e8c6;
    }
    .card-body p span{
        margin-right: 15px;
    }
    .column {
        flex: 1;
        display: flex;
    }
    .card {
        width: 100%;
    }
    .ibanItem{
        display: flex;
        align-items: center;
        padding: 5px 15px;
        font-size: 16px;
    }
    .bankLogo{
        min-width: 35%;
        margin-right: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bt, .unicredit, .cec, .otp, .brom,
    .brd, .raiffeisen, .bcr
    { display: inline-block; background: url('../../../assets/images/m2/banksLogos.png') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }

    .bt { background-position: -0px -0px; width: 142px; height: 31px; }
    .unicredit { background-position: -0px -31px; width: 135px; height: 18px; }
    .cec { background-position: -0px -49px; width: 110px; height: 21px; }
    .otp { background-position: -0px -70px; width: 86px; height: 19px; }
    .brom { background-position: -0px -89px; width: 85px; height: 20px; }
    .brd { background-position: -0px -109px; width: 82px; height: 19px; }
    .raiffeisen { background-position: -0px -128px; width: 76px; height: 20px; }
    .bcr { background-position: -86px -70px; width: 54px; height: 20px; }
    @media(max-width: 1450px) {
        .customTwoColumn{
            display: block;
        }
        .customTwoColumn .column{
            max-width: 100%;
        }
    }
</style>

