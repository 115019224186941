<template>
    <div>
        <HorecaHeader/>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-body class="d-flex align-items-center p-0" style="justify-content: space-between;">
                        <div class="d-flex align-items-center" style="justify-content: space-between;">
                            <div>
                                <b-img
                                        class="warning mr-1"
                                        height="80"
                                        left
                                        :src="require('@/assets/images/horeca/Sigla_CECCAR.png')"
                                />
                            </div>
                            <p class="bold m-0 d-flex mr-1" style = "color: #1d3460; text-align: center; font-size: 14px;">
                                Corpul expertilor contabili si contabililor autorizati din romania
                            </p>
                        </div>
                        <a href="https://ceccar.ro/ro/?page_id=97" target="_blank" class="btn-primary btn button" >
                            Caută expert CECCAR
                        </a>
                    </b-card-body>
                </b-card>

                <b-card>
                    <div class="videoWrapper">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/HYPZ_EkQY58" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </b-card> 
            </b-col>
            <b-col>
                <b-card>
                    <b-card-body class="d-flex align-items-center p-0" style="justify-content: space-between;">
                        <div class="d-flex align-items-center" style="justify-content: space-between;">
                            <div>
                                <b-img
                                    class="warning mr-1"
                                    height="80"
                                    left
                                    :src="require('@/assets/images/horeca/cafr.png')"
                                />
                            </div>
                            <p class="bold m-0 d-flex mr-1" style = "color:#004f39; max-width: 320px; text-align: center; font-size: 14px;">
                            Camera Auditorilor Financiari
                            din România
                            </p>
                        </div>
                        <a href="https://www.cafr.ro/registrul-membrilor-cafr/" target="_blank" class="btn-primary btn button" >
                        Caută auditor CAFR
                        </a>
                    </b-card-body>
                </b-card>
                <b-card>
                    <div class="videoWrapper">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/C9YKPYWMOEc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>                    
                </b-card>
            </b-col>
            <b-col>
                <b-card class="pt-1 inaltime">
                    <h2 class="mb-1">Ai neclarităţi?</h2>
                    <h4 class="mb-2">Ne poți contacta la:</h4>
                    <div class="mb-3 d-flex divContacte justify-content-center align-items-center">
                        <ul class="listaContact mr-4">
                            <li class="mb-2">
                                <b-img
                                  :src="require('@/assets/images/horeca/whatsappv2.png')"
                                  :width="30"  
                                   class="mr-2"
                                />
                                <a href="#">0722 114 395</a>
                            </li>
                            <li class="mb-2">
                                <feather-icon size=30 icon="SmartphoneIcon"  class="mr-2"/>
                                <a target="_blank" href="tel:0722115792">0722 115 792</a>

                            </li>
                             <li class="mb-2">
                                <feather-icon size=30 icon="PhoneIcon"  class="mr-2"/>
                               <a target="_blank" href="tel:0722144395">0722 144 395</a>

                            </li>
                             <li class="mb-2">
                                <feather-icon size=30 icon="MailIcon" class="mr-2"/>
                               <a target="_blank" href="mailto:contact@smis.ro">contact@smis.ro</a>

                            </li>
                        </ul>
                        <b-img :src="require('@/assets/images/horeca/smis.png')" fluid />
                    </div>
                </b-card>
                </b-col>
        </b-row>      
       
    </div>
</template>

<script>
    import { BCardBody, BAlert, BImg, BCol, BRow, BButton, BCard } from 'bootstrap-vue'
    import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'
    import HorecaHeader from '@/views/pages/horeca/components/HorecaHeader.vue'

    export default {
        data: () => ({
        }),
        components: {
            BAlert,
            BImg,
            BCol,
            BRow,
            BButton,
            YoutubeEmbed,
            BCard,
            BCardBody,
            HorecaHeader
        },
        mounted() {

        },
        methods: {
            
        },
    }
</script>
   
<style scoped>
    .alertParagraph{
        font-size:13px;
        /* vertical-align: center; */
    }
    .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 1px);
    height: 100%;
  }
  .button{
      min-width: 28%;
      width: 28%;
      padding:10px;
  }
    .bold{
        font-weight: bold;
        text-transform: uppercase;
    }
.minHeight{
    min-height: 119px;
}
.listaContact{
    font-weight: bold;
    list-style-type: none;
    font-size: 20px;
}

.listaContact a{
    color:#8a8a8a;
}
.inaltime{
    min-height: 450px;
}
.divContacte{
    padding-top: 53px;
}
</style>

