<template>
    <div>
        <div class="videoWrapper">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/3xxohvWN0N4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>

    export default {
        data: () => ({

        }),
        components: {
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>
    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
    }
    .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 1px);
        height: 100%;
    }
</style>

