<template>
	<b-card>
		<b-card-body
			class="inline-flex p-0 d-flex justify-content-between align-items-center"
		>
            <HorecaPageTitle></HorecaPageTitle>
			<horeca-logo />
		</b-card-body>
	</b-card>
</template>
<script>
	import HorecaPageTitle from './HorecaPageTitle'
import { BCardBody, BImg, BCard } from "bootstrap-vue";
	import HorecaLogo from "@/views/pages/horeca/components/HorecaLogo.vue";
	export default {
		components: {
    HorecaPageTitle,
			BCardBody,
			BImg,
			BCard,
			HorecaLogo,
		},
	};
</script>