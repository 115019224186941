<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-alert class="mt-0" variant="danger" show v-if="!isM2Manager">
          <div class="alert-body">
                  <span>
                    ATENȚIE! Nu ai selectat pachetul M2 Manager. Alege pachetul accesând
                    <router-link
                            :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'serviciile-mele' },
                      }"
                    ><strong>Serviciile mele</strong></router-link>.
                  </span>
          </div>
        </b-alert>
        <b-alert class="mt-0" variant="danger" show v-else-if="!isPaid">
          <div class="alert-body">
                  <span>
                    Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea <router-link
                          :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'factura-contract' },
                      }"
                  ><strong>Factură si Contract</strong></router-link>. În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager)

                  </span>
          </div>
        </b-alert>
      </div>
    </div>
    <div v-if="isM2Manager">
        <div class="card">
            <div class="flex card-body">
                <div class="info">
                    <p>Venim in sprijinul beneficiarilor de Măsura 2 cu o listă de furnizori ce livrează produse sau servicii
                        personalizate și în concordanță cu procedura de implementare. (plata OP, etc)</p>
                </div>
                <div class="contact-furnizor">
                    <p class="email-furnizor">Ești furnizor? Trimite-ne un e-mail la <a href="mailto:office@smis.ro">office@smis.ro</a>
                        Listarea este gratuită</p>
                </div>
            </div>
        </div>
        <div class="row">
			<div class="col-xl-6 col-lg-12 col-md-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="left col-sm-12 col-md-7">
								<div class="date-contact">
									<p>Email: <a href="mailto:office@growingpath.ro">office@growingpath.ro / teostan1@yahoo.com</a></p>
									<p>Telefon: <a href="tel:0752215814">0752.215.814</a></p>
									<p>Nume persoana de contact: <strong>Stan Teodor</strong></p>
								</div>
								<div class="beneficii mt-3">
									<p><strong>Beneficii pentru beneficiarii de granturi:</strong></p>
									<ul class="beneficii-list mt-1">
										<li>- raspunsuri la clarificări, derulare contract</li>
										<li>- analiza cheltuieli eligibile pe activitatea dvs.</li>
										<li>- întocmire raport de progres</li>
									</ul>
								</div>
							</div>
							<div class="right col-sm-12 col-md-5">
								<a class="text-center" href="https://growingpath.ro/" target="_blank">
									<b-img
										fluid
										class="ml-1 mr-1"
										:src="require('./img/logo-gp.png')"
									/>
									<div class="red-text mt-3">Consultanță accesare fonduri europene</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="left col-sm-12 col-md-7">
                                <div class="date-contact">
                                    <p>Email: <a href="mailto:madalin.eftimie@evomag.ro">madalin.eftimie@evomag.ro</a></p>
                                    <p>Telefon: <a href="tel:0733966123">0733.966.123</a></p>
                                    <p>Nume persoana de contact: <strong>Eftimie Madalin</strong></p>
                                </div>
                                <div class="beneficii mt-3">
                                    <p><strong>Beneficii pentru beneficiarii de granturi:</strong></p>
                                    <ul class="beneficii-list mt-1">
                                        <li>- consilier dedicat</li>
                                        <li>- trasport gratuit</li>
                                        <li>- discounturi preferentiale</li>
                                        <li>- prioritate in services.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="right col-sm-12 col-md-5">
                                <a class="text-center" href="https://www.evomag.ro/" target="_blank">
                                    <b-img
                                        fluid
                                        class="ml-1 mr-1"
                                        :src="require('./img/evoMag.png')"
                                    />
                                    <div class="red-text mt-3">Echipamente IT și<br> bunuri diverse</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
			<div class="col-xl-6 col-lg-12 col-md-12">
				<div class="card">
					<div class="card-body">
						<div class="row">
							<div class="left col-sm-12 col-md-7">
								<div class="date-contact">
									<p>Email: <a href="mailto:resurseumane.serviciiHR@gmail.com">resurseumane.serviciiHR@gmail.com</a></p>
									<p>Telefon: <a href="tel:0756591041">0756.591.041</a></p>
									<p>Nume persoana de contact: <strong>Liliana Ardeleanu</strong></p>
								</div>
								<div class="beneficii mt-3">
									<p><strong>Beneficii pentru beneficiarii de granturi:</strong></p>
									<ul class="beneficii-list mt-1">
										<li>- raspunsuri la clarificări, derulare contract</li>
										<li>- formulare contestații</li>
										<li>- analiza cheltuieli eligibile pe activitatea dvs.</li>
										<li>- completare raport de progres</li>
									</ul>
								</div>
							</div>
							<div class="right col-sm-12 col-md-5">
								<a class="text-center" href="https://servicii-intreprinderi.ro/" target="_blank">
									<b-img
										fluid
										class="ml-1 mr-1"
										:src="require('./img/servicii-intreprinderi.png')"
									/>
									<div class="blue-text mt-3">
										Intocmire raport de progres<br>
										Formulare contestații<br>
										Consultanță implementare
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
            <div class="col-xl-6 col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="left col-sm-12 col-md-7">
                                <div class="date-contact">
                                    <p>Email: <a href="mailto:liz.moldovan@amazingsoft.ro">liz.moldovan@amazingsoft.ro</a></p>
                                    <p>Telefon: <a href="tel:0747306316">0747.306.316</a></p>
                                    <p>Nume persoana de contact: <strong>Liz Moldovan</strong></p>
                                </div>
                                <div class="beneficii mt-3">
                                    <p><strong>Beneficii pentru beneficiarii de granturi:</strong></p>
                                    <ul class="beneficii-list mt-1">
                                        <li>- consultanta gratuita</li>
                                        <li>- prioritate in ofertare</li>
                                        <li>- prioritate in dezvoltare</li>
                                        <li>- tarife preferentiale</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="right col-sm-12 col-md-5">
                                <a class="text-center" href="https://amazingsoft.ro" target="_blank">
                                    <b-img
                                            fluid
                                            class="ml-1 mr-1"
                                            :src="require('./img/logo-amazing-soft.png')"
                                    />
                                    <div class="blue-text mt-3">
                                        Dezvoltatoare software<br>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BImg, BAlert } from 'bootstrap-vue'
import store from '@/store'

export default {
  data: () => ({
    isPaid: false,
    isM2Manager: false
  }),
  components: {
    BImg,
    BAlert,
  },
  mounted() {
      this.showPayPopupHandler()
      this.isM2ManagerHandler()
  },
  methods: {
      isM2ManagerHandler() {
          let allPlans = store.getters['auth/user'].plans
          this.isM2Manager = !!allPlans.find(function (key) {
              // program_id === 1 is M2 Manager Program
              // program_id === 2 is POCU Manager Program
              return key.id === 2
          })
      },
      showPayPopupHandler() {
          let allPlans = store.getters['auth/user'].plans
          this.isPaid = !!allPlans.find(function (key) {
              // program_id === 1 is M2 Manager Program
              // program_id === 2 is POCU Manager Program
              return key.program_id === 1 && key.paid_confirmed !== null
          })
      },
  },
}
</script>

<style scoped>
.flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.info{
    max-width: 45%;
    text-align: center;
}
.left {
    padding: 15px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact-furnizor{
    width: 100%;
    max-width: 416px;
    text-align: center;
}
.email-furnizor{
    padding: 5px;
    border: 1px solid #858585;
    border-radius: 2px;
}
.flex p{
    margin: 0;
}
.date-contact p{
    margin: 0;
    text-align: center;
}
.beneficii-list{
    list-style: none;
    padding: 0;
}
.beneficii{
    text-align: center;
}
.right{
    border-left: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.red-text{
    color: #bc2025;
    text-align: center;
    font-weight: 700;
}
.blue-text{
    color: #2356a9;
    text-align: center;
    font-weight: 700;
}
@media(max-width:767px) {
    .contact-furnizor, .info{
        width: 100%;
        max-width: 100%;
    }
    .info{
        margin-bottom: 10px;
    }
    .flex.card-body{
        display: block;
    }
}
</style>

