<template>
    <div>
        <horeca-header class="mb-1"/>
        <b-card class="mb-1">
            <b-img
                    class="warning mr-1"
                    width="50"
                    left
                    :src="require('@/assets/images/horeca/File-info-icon.png')"
            />
            <p class="mb-0 alertParagraph p-0">
            Această secțiune va fi disponibilă imediat ce va demara procesul de implementare odata cu informații actualizate din partea finanțatorului.
               <br> Vă mulțumim!    
            </p>
        </b-card>
        <div class="imagine pt-2">
        <b-img 
         :src="require('@/assets/images/horeca/inConstructie.png')"
         
        />
        </div>
        
    </div>
</template>

<script>
    import { BCardBody,  BImg, BCard } from 'bootstrap-vue'
    import HorecaHeader from '@/views/pages/horeca/components/HorecaHeader.vue'
    export default {
        data: () => ({

        }),
        components: {
            HorecaHeader,
            BImg,
            BCard,
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>
.alertParagraph{
        font-size:13px;
        /* vertical-align: center; */
    }

   .imagine{
       display: flex;
       justify-content: center;
   } 
</style>

