<template>
  <div>
    <b-alert class="mt-0" variant="danger" show v-if="!isM2Manager">
      <div class="alert-body">
            <span>
              ATENȚIE! Nu ai selectat pachetul M2 Manager. Alege pachetul accesând
              <router-link
                      :to="{
                  path: '/',
                  name: 'pages-account-setting',
                  params: { to: 'serviciile-mele' },
                }"
              ><strong>Serviciile mele</strong></router-link>.
            </span>
      </div>
    </b-alert>
    <b-alert class="mt-0" variant="danger" show v-else-if="!m2Paid">
      <div class="alert-body">
                  <span>
                    Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea <router-link
                          :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'factura-contract' },
                      }"
                  ><strong>Factură si Contract</strong></router-link>. În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager)

                  </span>
      </div>
    </b-alert>
    <div v-if="isM2Manager">
      <div class="card">
        <div class="card-body space-between align-items-center pt-1 pb-1">
          <h2 class="m-0">
            Modul de transmitere solicitări de clarificări către finanțator
          </h2>
          <b-img
                  class="newsImg"
                  width="70"
                  height="70"
                  rounded
                  right
                  :src="require('@/assets/images/m2/logo-guvernul-romaniei.png')"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mb-3" ref="form">
          <div
            class="card"
            v-for="(item, index) in questions"
            :id="item.id"
            :key="item.id"
          >
            <div class="card-body">
              <h3 class="card-title">
                Întrebarea {{ index + 1 }}
                <b-button
                  v-if="index >= 1"
                  style="margin-top: -7px"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="vgt-pull-right"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Sterge</span>
                </b-button>
              </h3>
              <div class="mt-2">
                <b-form-group label-for="Intrebare">
                  <b-form-textarea
                    id="Intrebare"
                    type="text"
                    placeholder="Scrie aici întrebarea"
                    v-model="item.question"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
          <b-col class="space-between">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeateAgain"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Adaugă întrebare</span>
            </b-button>
            <b-button
              class="ml-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              @click="generateDocument"
            >
              <feather-icon icon="CheckIcon" class="mr-25" />
              <span>Generează solicitarea</span>
            </b-button>
          </b-col>
        </div>
        <div class="col-lg-8">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Solicitările mele</h4>
              <div class="mt-2">
                <vue-good-table
                  v-if="rows && !loading"
                  :columns="columns"
                  :rows="rows"
                  :theme="theme"
                  :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                  }"
                  :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                  }"
                  :sort-options="{
                      enabled: false,
                      initialSortBy: {field: 'created_at', type: 'desc'}
                    }"
                  :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                  }"
                >
                  <template slot="table-row" slot-scope="props">
                    <!-- Column: Name -->
                    <span
                      v-if="props.column.field === 'fullName'"
                      class="text-nowrap"
                    >
                      <b-avatar :src="props.row.avatar" class="mx-1" />
                      <span class="text-nowrap">{{ props.row.fullName }}</span>
                    </span>

                    <!-- Column: Status -->
                    <span v-else-if="props.column.field === 'status'">
                      <b-badge
                        class="badge badge-info"
                        v-if="props.row.doc_answer"
                        >Clarificată</b-badge
                      >
                      <b-badge
                        class="badge badge-light-success"
                        v-else-if="props.row.sent_to"
                        >Trimisă</b-badge
                      >
                      <b-badge
                        class="badge badge-light-warning"
                        v-else-if="props.row.doc_question"
                        >Generată</b-badge
                      >
                    </span>

                    <span v-else-if="props.column.field === 'trimite'">
                      <b-dropdown-item
                        @click="showModal(props.row)"
                        style="list-style-type: none"
                      >
                        <feather-icon
                          :style="props.row.sent_to ? 'color:#28c76f' : ''"
                          icon="SendIcon"
                          class="mr-50"
                          size="28"
                        />
                      </b-dropdown-item>
                    </span>
                    <span v-else-if="props.column.field === 'created_at'">{{
                      props.row.created_at | moment('DD.MM.YYYY - HH:mm')
                    }}</span>
                    <span v-else-if="props.column.field === 'download'">
                      <b-dropdown-item
                        @click="downloadDoc(props.row)"
                        style="list-style-type: none"
                      >
                        <feather-icon
                          icon="DownloadIcon"
                          class="mr-50"
                          size="28"
                        />
                      </b-dropdown-item>
                    </span>
                    <span
                      v-else-if="props.column.field === 'upload'"
                      style="display: flex"
                    >
                      <b-form-file
                        type="file"
                        :placeholder="
                          !props.row.doc_answer ? 'Încarcă' : 'Reîncarcă'
                        "
                        :id="'file' + props.row.id"
                        :ref="'file' + props.row.id"
                        v-on:change="uploadDoc($event, props.row.id)"
                      />
                      <b-dropdown-item
                        @click="downloadAnswer(props.row)"
                        style="list-style-type: none"
                        v-if="props.row.doc_answer"
                      >
                        <feather-icon
                          icon="DownloadIcon"
                          class="mr-50"
                          size="28"
                        />
                      </b-dropdown-item>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Afișare</span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['3', '5', '10']"
                          class="mx-1"
                          @input="
                            (value) =>
                              props.perPageChanged({ currentPerPage: value })
                          "
                        />
                        <span class="text-nowrap"
                          >pe pagină. Total {{ props.total }} solicitări.</span
                        >
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="
                            (value) => props.pageChanged({ currentPage: value })
                          "
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        v-model="modalShow"
        id="modal-lg"
        hide-footer
        size="lg"
        ok-title=""
        modal-class="modal-primary"
        centered
        title="Unde trimitem solicitarea ta? (te rugăm bifează)"
      >
        <b-card-text style="padding: 20px 0 0 10px">
          <div class="row">
            <div class="col-sm-6">
              <div class="row">
                <b-img
                  class="newsImg"
                  width="80"
                  height="80"
                  rounded
                  :src="require('@/assets/images/m2/logo-guvernul-romaniei.png')"
                />
                <div class="col-sm-9" style="padding-top: 20px">
                  Ministerul Economiei Antreprenoriatului<br>si Turismului
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <b-img
                  class="newsImg"
                  width="80"
                  height="80"
                  rounded
                  :src="require('@/assets/images/m2/logo-guvernul-romaniei.png')"
                />
                <div class="col-sm-9" style="padding-top: 5px">
                  Agenția pentru Întreprinderi Mici și Mijlocii,<br> Atragere de Investiții și<br>
                  Promovare a Exportului {{ agencies.county }}
                </div>
              </div>
            </div>
          </div>
        </b-card-text>
        <div class="row mb-2">
          <p class="col-sm-12 errorMessage">
            {{ errorMessage }}
          </p>
          <b-form class="col-sm-6">
            <b-form-checkbox
              :disabled="!m2Paid"
              v-model="selected"
              :value="item.email"
              :key="item.id"
              v-for="item in ministries"
            >
              {{ item.email }}
            </b-form-checkbox>
          </b-form>
          <b-form class="col-sm-6">
            <b-form-checkbox
              :disabled="!m2Paid"
              v-model="selected"
              :value="item"
              :key="item"
              v-for="item in agencies.email"
            >
              {{ item }}
            </b-form-checkbox>
          </b-form>
          <b-button
              variant="primary"
              class="mt-2 ml-1"
              :disabled="loading || !m2Paid"
              type="submit"
              @click="this.sendQuestion">
              <span v-if="loading">
                <b-spinner small label="Loading..."></b-spinner> &nbsp; Se trimite email-ul...
              </span>
              <span v-else>Trimite solicitarea</span>
          </b-button>
        </div>
      </b-modal>
    </div>
    <YoutubeEmbed link="6QAezgjbVdQ" />
  </div>
</template>

<script>
import axiosIns from '../../../libs/axios'
import { VueGoodTable } from 'vue-good-table'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'

import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BImg,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BModal,
  VBModal,
  BBadge,
  BFormCheckbox,
  BForm,
  BSpinner,
  BAlert
} from 'bootstrap-vue'
import store from '@/store'

export default {
  name: 'Clarifications',
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BImg,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BModal,
    BBadge,
    BFormCheckbox,
    BForm,
    ToastificationContent,
    BSpinner,
    BAlert,
    YoutubeEmbed
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  mixins: [heightTransition],
  data() {
    return {
      errorMessage: '',
      pageLength: 10,
      theme: store.state.appConfig.layout.skin === 'dark' ? 'nocturnal' : '',
      dir: false,
      columns: [
        {
          label: 'Data',
          field: 'created_at',
          width: '200px',
        },
        {
          label: 'Trimite',
          field: 'trimite',
          width: '150px',
        },
        {
          label: 'Status',
          field: 'status',
          width: '150px',
        },
        {
          label: 'Descarcă',
          field: 'download',
          width: '150px',
        },
        {
          label: 'Încarcă răspuns',
          field: 'upload',
          width: '250px',
        },
      ],
      rows: [],
      searchTerm: '',
      loading: false,
      nextTodoId: 2,
      modalShow: false,
      sent_id: null,
      selected: ['office@smis.ro'],
      agencies: [],
      ministries: [
        {
          id: 1,
          email: 'comunicare@economie.gov.ro',
        },
        {
          id: 2,
          email: 'petitii@imm.gov.ro',
        },
        {
          id: 3,
          email: 'office.minister@economie.gov.ro',
        }
      ],
      questions: [
        {
          id: 1,
          question: null,
        },
      ],
      m2Paid: false,
      isM2Manager: false,
    }
  },
  mounted() {
    this.initTrHeight()
    this.showPayPopupHandler()
    this.isM2ManagerHandler()
  },
  created() {
    this.getData()
    this.getAgencies()
    if (localStorage.getItem('Questions')) {
      this.questions = JSON.parse(localStorage.getItem('Questions'))
    }

    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  watch: {
    questions: {
      deep: true,
      handler() {
        localStorage.setItem('Questions', JSON.stringify(this.questions))
      },
    },
  },
  methods: {
    showToast(variant, title, msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: 'BellIcon',
          text: msg,
          variant,
          link
        },
      })
    },
    downloadDoc(item) {
      axiosIns
        .post('/clarification-download', item, { responseType: 'blob' })
        .then((response) => {
          let blob = new Blob([response.data])
          let a = document.createElement('a')
          a.style = 'display: none'
          document.body.appendChild(a)
          let url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = item.doc_question
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((error) => {})
    },
    uploadDoc(event, id) {
      let formData = new FormData()
      formData.append('file', event.target.files[0])
      formData.append('id', id)
      axiosIns
        .post('/clarification-upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.getData()
          this.showToast('success', 'Notificare', response.data.message)
        })
        .catch((e) => {
          this.errors.push(e)
        })
    },
    downloadAnswer(item) {
      axiosIns
        .post('/clarification-download-answer', item, { responseType: 'blob' })
        .then((response) => {
          let blob = new Blob([response.data])
          let a = document.createElement('a')
          a.style = 'display: none'
          document.body.appendChild(a)
          let url = window.URL.createObjectURL(blob)
          a.href = url
          a.download = item.doc_answer
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch((error) => {})
    },
    showModal(item) {
      this.errorMessage = ''
      if (!item.sent_to) {
        this.modalShow = true
        this.sent_id = item.id
      } else {
        this.showToast('primary', 'Notificare', 'Emailul a fost deja trimis!')
      }
    },
    sendQuestion(item) {
        if(this.selected.length > 0) {
            this.loading = true
            this.errorMessage = ''
            axiosIns
                .post('/clarification-sent', {
                    sent_id: this.sent_id,
                    sent_to: this.selected,
                })
                .then((response) => {
                    this.modalShow = false
                    this.getData();
                    this.showToast('success', 'Notificare', response.data.message)
                })
                .catch((e) => {
                    this.errors.push(e)
                    this.modalShow = false
                })
        } else {
            this.errorMessage = 'Alege cel putin o adresa de email.'
        }
    },
    generateDocument() {
      axiosIns
        .post('/clarification-generate', { questions: this.questions })
        .then((response) => {
          this.getData()
          if (response.data.error) {
            this.showToast('danger', 'Eroare', response.data.error)
          } else {
            this.showToast('success', 'Notificare', response.data.message)
            localStorage.removeItem('Questions')
            this.questions = [{ id: 1, question: null }]
          }
        })
        .catch((e) => {
          this.errors.push(e)
        })
    },
    getData() {
      this.loading = true
      axiosIns
        .get('/clarification-list')
        .then((response) => {
          this.rows = response.data.clarifications
          this.loading = false
        })
        .catch((e) => {
          this.errors.push(e)
          this.loading = false
        })
    },
    repeateAgain() {
      this.questions.push({
        id: (this.nextTodoId += this.nextTodoId),
      })
    },
    removeItem(index) {
      this.questions.splice(index, 1)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
          if(this.$refs.form) {
              this.trSetHeight(this.$refs.form.scrollHeight)
          }
      })
    },
    getAgencies() {
      axiosIns
        .get('/agency-list')
        .then((response) => {
          this.agencies = response.data.agencies_list
        })
        .catch((e) => {
          this.errors.push(e)
        })
    },
    isM2ManagerHandler() {
        let allPlans = store.getters['auth/user'].plans
        this.isM2Manager = !!allPlans.find(function (key) {
            return key.id === 2
        })
    },
    showPayPopupHandler() {
        let allPlans = store.getters['auth/user'].plans
        this.m2Paid = !!allPlans.find(function (key) {
            return key.program_id === 1 && key.paid_confirmed !== null
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.errorMessage{
  color: red;
}
</style>
