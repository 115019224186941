<template>
  <div v-if="!loading && this.formData">
    <div class="row">
      <div class="col-xl-12" v-if="this.formData.company.name">
        <div class="card">
          <div class="card-body space-between">
            <div>
              <h3 class="mb-0">{{formData.company.name}}</h3>
              <h5 class="m-0 mt-1" v-if="this.formData.contract_signed">Beneficiar M2 prin contract M-{{formData.rue_no}} / {{formData.contract_signed | moment('DD.MM.YYYY') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="consultant">
      <div class="col-xl-12" v-if="this.formData.company.name">
        <div class="card">
          <div class="card-body space-between p-0"  style="border: 2px solid #01e8c6; border-radius: 0.428rem;">
            <vue-good-table
                style="width: 100%;"
                :rows="companies"
                :columns="columns"
                :line-numbers="false"
                :pagination-options="{
                  enabled: true,
                  position: 'bottom',
                  mode: 'records',
                  perPage: 30,
                  perPageDropdown: [10, 30, 50, 100, 200, 500],
                  dropdownAllowAll: true,
                }"
                :search-options="{
                  enabled: true,
                  skipDiacritics: true,
                  placeholder: 'Caută in toate coloanele',
                }"
                  >
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-xl-6 col-lg-6 col-md-12">
        <div class="card" style="height: 92%">
          <div class="card-body">
            <div class="top space-between" v-if="!this.pressFinalPublished">
              <div>
                <h4 class="card-title">Mai ai la dispozitie:</h4>
                <p class="daysRemaining mb-0">{{this.days_left}}<span> zile</span></p>
              </div>
              <div>
                <!-- apex chart -->
                <vue-apex-charts
                        type="radialBar"
                        height="250"
                        width="165"
                        :options="supportTrackerRadialBar.chartOptions"
                        :series="[this.percentage]"
                />
              </div>
            </div>
            <div class="mt-2 mb-4" v-else>
              <h2>Felicitări pentru finalizarea implementării!</h2>
            </div>
            <div>
              <p class="text mt-1 mb-0">Pentru a cheltui banii și a transmite documentele de implementare în raportul de progres din aplicația granturi.imm.gov.ro, Conform Procedurii de Implementare, art 6. alin 6.8 (3)</p>
            </div>
          </div>
          <div class="card-footer">
            <div class="space-between">
              <div>
                <p class="mb-0">Data începerii proiectului</p>
                <h4 class="mb-0">{{ this.formData.grant_received }}</h4>
              </div>
              <div class="text-center">
                <p class="mb-0">Data curentă</p>
                <h4 class="mb-0">{{ getCurrentDate() }}</h4>
              </div>
              <div class="text-right">
                <p class="mb-0">Data expirării termenului de 180 de zile</p>
                <h4 class="mb-0">{{ this.end_project }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="boxViziteaza" class="col-xl-3 col-lg-3 col-md-5">
        <div class="card" style="height: 92%">
          <div class="card-body">
            <a class="guvernulWidget" href="http://granturi.imm.gov.ro" target="_blank">
              <b-img
                      class="newsImg"
                      width="80"
                      height="80"
                      rounded
                      left
                      :src="require('@/assets/images/m2/logo-guvernul-romaniei.png')"
              />
              <p class="m-0 mt-2">Vizitează platforma<br>
                <strong>granturi.imm.gov.ro</strong></p>
            </a>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-lg-3 col-md-7">
        <b-link to="/" class="card text-center" style="height: 92%">
          <div class="card-body">
            <h4 class="card-title mt-1">Contul meu</h4>
            <M2ManagerLogos :height="55" class="mt-1 mb-1"></M2ManagerLogos>
            <p class="mt-1 mb-2">powered by</p>
            <Logo class="logoContulMeu mb-1" />
          </div>
        </b-link>
      </div>
    </div>

    <div class="row" v-if="data">
      <div class="col-xl-6 col-lg-7 col-md-12" v-if="data.news">
        <div class="card row2Height">
          <div class="card-body">
            <h4 class="card-title">Știri și comunicate MEEMA</h4>
            <div class="fixedHeightScroll mt-2" v-if="data.news.length">
              <b-media
                  v-if="news.title"
                  v-for="(news,index) in sortedNewsItems"
                  :key="news.id"
                  no-body
                  :class="index? 'mt-2':''"
              >
                <b-media-aside class="mr-2">
                  <a :href="news.url" target="_blank">
                    <b-img
                            class="newsImg"
                            :src="news.picture_url"
                            width="200"
                            rounded
                    />
                  </a>
                </b-media-aside>
                <b-media-body>
                  <h6 class="news-title">
                    <a :href="news.url" target="_blank" v-if="news.title">{{ news.title | truncate(150) }}</a>
                  </h6>
                  <span class="text-muted" v-if="news.date">
                        {{ news.date | moment('DD.MM.YYYY') }}
                  </span>
                  <div v-if="news.description">{{ news.description | truncate(80) }}</div>
                </b-media-body>
              </b-media>
            </div>
            <div class="mt-2" v-else>
              Momentan nu sunt disponibile știri și comunicate MEEMA.
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-lg-12 col-md-6" v-if="data.faq">
        <div class="card row2Height">
          <div class="card-body">
            <h4 class="card-title">Întrebări și răspunsuri</h4>
            <div class="fixedHeightScroll mt-2" v-if="data.faq.length">
              <app-collapse
                      id="faq-payment-qna"
                      accordion
                      type="margin"
                      class="mt-2"
              >

                <app-collapse-item
                        v-for="(data, index) in data.faq"
                        :key="index"
                        :title="data.question"
                >
                  {{ data.answer }}
                </app-collapse-item>

              </app-collapse>
            </div>
            <div class="mt-2" v-else>
              Momentan nu sunt disponibile întrebări și răspunsuri.
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-lg-5 col-md-6">
        <div class="card row2Height">
          <div class="card-body">
            <h4 class="card-title">Documente utile</h4>
            <div class="fixedHeightScroll mt-2" v-if="data.documents.length">
              <b-media
                      v-for="(document,index) in data.documents"
                      :key="document.id"
                      no-body
                      :class="index? 'mt-2':''"
              >
                <b-media-aside class="mr-2">
                  <a href="#" @click="download(document)">
                    <b-img
                            :src="require('@/assets/images/m2/pdf-download.png')"
                    />
                  </a>
                </b-media-aside>
                <b-media-body>
                  <h6 class="blog-recent-post-title">
                    <a href="#" @click="download(document)">Descarca</a>
                  </h6>
                  <div>{{ document.title}}</div>
                </b-media-body>
              </b-media>
            </div>
            <div class="mt-2" v-else>
              Momentan nu sunt disponibile documente utile.
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
    import axiosIns from '../../../libs/axios'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import {
        BRow, BLink, BCol, BCard, BCardText, BButton, BImg, BAlert, BMedia, BMediaAside, BMediaBody
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import { $themeColors } from '@themeConfig'
    import store from '@/store/index'
    import config from '@/config'
    import Logo from '@core/layouts/components/Logo.vue'
    import M2ManagerLogos from './components/M2ManagerLogos'
    import moment from 'moment'
    import { VueGoodTable } from 'vue-good-table'
    export default {
        name: 'PrezentareGenerala',
        components: {
            VueGoodTable, M2ManagerLogos, Logo, VueApexCharts, BRow, BCol, BLink, BCard, BCardText, BButton, BImg, BAlert,  AppCollapseItem, AppCollapse, BMedia, BMediaAside, BMediaBody
        },
        data() {
            return {
                loading: false,
                consultant: false,
                data: [],
                companies: [],
                formData: {
                    company: {
                        id: null,
                        name: null,
                        business_id: null,
                    },
                    estimated_value: null,
                    co_finance_value: null, // valoare_cofinantare
                    m2_agency_id: null,
                    rue_no: null,
                    received: null,
                    grant_received: " - ",
                    contract_signed: " - ",
                },
                end_project: null,
                days_left: null,
                percentage: 0,

                // Chart
                supportTrackerRadialBar: {
                    chartOptions: {
                        plotOptions: {
                            radialBar: {
                                size: 0,
                                offsetY: -30,
                                startAngle: -150,
                                endAngle: 150,
                                hollow: {
                                    size: '50%',
                                },
                                track: {
                                    background: 'transparent',
                                    strokeWidth: '100%',
                                },
                                dataLabels: {
                                    value: {
                                        offsetY: -8,
                                        color: '#63cab3',
                                        fontSize: '1.4rem',
                                    },
                                },
                            },
                        },
                        colors: [$themeColors.danger],
                        fill: {
                            type: 'gradient',
                            gradient: {
                                shade: 'dark',
                                type: 'horizontal',
                                shadeIntensity: 0.5,
                                gradientToColors: [$themeColors.primary],
                                inverseColors: true,
                                opacityFrom: 1,
                                opacityTo: 1,
                                stops: [0, 100],
                            },
                        },
                        stroke: {
                            dashArray: 6,
                        },
                        labels: [''],
                    },
                },
                columns: [
                    {
                        label: 'Nume Companie',
                        field: "name",
                    },
                    {
                        label: 'CUI',
                        field: 'cui',
                        type: 'number',
                    },
                    {
                        label: 'RUE',
                        field: 'm2Project.rue_no',
                        type: 'number',
                    },
                    {
                        label: 'Valoare estimată',
                        field: 'm2Project.estimated_value',
                        type: 'number',
                    },
                    {
                        label: 'Valoare cofinanțare',
                        field: 'm2Project.co_finance_value',
                        type: 'number',
                    },
                    {
                        label: 'Contract semnat',
                        field: 'm2Project.contract_signed',
                        type: 'date',
                        dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
                        dateOutputFormat: 'dd.MM.yyyy'
                    },
                    {
                        label: 'Primire grant',
                        field: 'm2Project.grant_received',
                        type: 'date',
                        dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
                        dateOutputFormat: 'dd.MM.yyyy',
                    },
                    {
                        label: 'Dată limită',
                        field: this.limitDate,
//                        type: 'date',
//                        dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
//                        dateOutputFormat: 'dd.MM.yyyy',
                    },
                    {
                        label: 'Zile rămase',
                        field: this.daysLeft,
//                        type: 'date',
//                        dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
//                        dateOutputFormat: 'dd.MM.yyyy',
                    },
                ],
                pressFinalPublished: null,
            }
        },
        mounted() {
            axiosIns
                .get(config.API_ENDPOINTS.getM2data)
                .then(({ data }) => {
                    if (data.data && data.data.terms_accepted) {
                        this.terms_accepted = data.data.terms_accepted
                        this.storedData = data.data
                        this.formData = data.data
                        this.pressFinalPublished = data.data.press_final_published;
                        this.end_project = moment(this.formData.grant_received).add(180, 'days').format("DD.MM.YYYY")

                        if(this.end_project !== 'Invalid date') {
                            let endProject = moment(this.end_project, 'DD-MM-YYYY');
                            let today = moment();
                            this.days_left = endProject.diff(today, 'days') + 1;
                            this.formData.grant_received = moment(data.data.grant_received).format('DD.MM.YYYY');
                        } else {
                            this.formData.grant_received = " - ";
                            this.end_project = " - ";
                            this.days_left = 180;
                            this.percentage = 100;
                        }
                        let days = 180;
                        let days_left = this.days_left;
                        this.percentage = parseInt((days_left * 100) / days);
                    } else {
                        this.formData.grant_received = " - ";
                        this.end_project = " - "
                        this.days_left = 180;
                        this.percentage = 100;
                    }
                })
                .catch((error) => console.log('Error: ', error))
        },
        methods: {
            getCurrentDate(){
                let today = new Date();
                let dd = String(today.getDate()).padStart(2, '0');
                let mm = String(today.getMonth() + 1).padStart(2, '0');
                let yyyy = today.getFullYear();

                today = dd + '.' + mm + '.' + yyyy;
                return today;
            },
            getData(){
                this.loading = true;
                axiosIns.get("/dashboard")
                    .then(response => {
                        this.data = response.data;
                        this.loading = false;
                    })
                    .catch(e => {
                        this.errors.push(e);
                        this.loading = false;
                    })
            },
            download(doc){
                axiosIns.post('/document-download', doc, {responseType: 'blob'})
                    .then(response => {
                        let blob = new Blob([response.data]);
                        let a = document.createElement("a");
                        a.style = "display: none";
                        document.body.appendChild(a);
                        let url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = doc.document;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch(error => {})
            },
            getAllCompanies() {
                this.loading = true;
                axiosIns.get(config.API_ENDPOINTS.userCompanies).then(({ data }) => {
                    if(data.data.length > 1) {
                        this.consultant = true;
                    }
                    this.loading = false;
                    this.companies = data.data
                    // console.log('selectedCompany', this.$store.state.auth.user.company_id);
                })
            },
            limitDate(rowObj) {
                //console.log('this.formData.grant_received', rowObj.m2Project.grant_received);
                if(rowObj.m2Project && rowObj.m2Project.grant_received) {
                    let limitDate = moment(rowObj.m2Project.grant_received).add(180, 'days').format("DD.MM.YYYY")
                    return limitDate;
                } else {
                    return '';
                }
            },
            daysLeft(rowObj) {
                if(rowObj.m2Project && rowObj.m2Project.grant_received) {

                    let limitDate = moment(rowObj.m2Project.grant_received).add(180, 'days').format("DD.MM.YYYY")
                    if (limitDate !== 'Invalid date') {
                        let endProject = moment(limitDate, 'DD-MM-YYYY');
                        let today = moment();
                        let days = endProject.diff(today, 'days') + 1;
                        console.log('endProject', endProject);
                        return days;
                    }
                }
            }
        },
        computed: {
            sortedNewsItems: function() {
                return this.data.news.sort( ( a, b) => new Date(b.date) - new Date(a.date))
            }
        },
        created() {
            this.getData();
            this.getAllCompanies()
        }, 
    }
</script>
<style scoped>
  .row2Height {
    height: 95%
  }
  #boxViziteaza .card-body{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .newsImg{
    object-fit:cover;
  }
  .daysRemaining {
    color: #63cab3;
    font-size: 60px;
    font-weight: bold;
  }
  .daysRemaining span{
    color: #9f9f9f;
    font-size: 26px;
  }
  .guvernulWidget{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .guvernulWidget p{
    word-break: break-word;
    text-align: center;
    color: #63cab3;
    font-size: 16px;
  }
  .opacity-50{opacity: 0.5}
  .logoContulMeu{
    display: inline-flex;
  }
  .logoContulMeu img{
    width: 120px
  }
  .logoContulMeu h1{
    font-size: 2rem;
  }
  .fixedHeightScroll{
      max-height: 500px;
      overflow-y: auto;
  }
</style>
