<template>
    <div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body space-between">
                        <img src="@/assets/images/horeca/implementare-horeca.png" class="w-100">
                    </div>
                </div>
            </div>
        </div>

<div class="card">	
  <div class="row">
    <div class="col-md-6">
      <div class="card-body">
        <div class="echipa-smis font-bold"><p>Vezi mai jos pașii explicați de echipa</p><img src="@/assets/images/horeca/echipa-smis.png"></div>
        <div class="procedura mb-3"><p class="font-bold mb-0">Procedura de înscriere în cadrul Schemei (conform procedură)</p><img src="@/assets/images/horeca/guvernul-romaniei.png"></div>
        <p>5.1. (1) Înscrierea în cadrul Schemei, creare profil, user, parolă şi completarea formularului de înscriere în vederea obţinerii finanţării se fac on-line, folosind link-ul <a target="_blank" class="font-weight-bold" href="http://granturi.imm.gov.ro">http://granturi.imm.gov.ro.</a> <br>
  Aplicanții au obligația de a urmări informațiile referitoare la Schemă postate pe site-ul MEAT pe toată perioada de implementare (înscriere, verificare, clarificări, semnare contract, efectuare plăți, monitorizare), precum și în contul creat la înscriere, denumit în continuare front office (FO).</p>
        <h2 class="title-center mt-4">ETAPA 1</h2>
        <p class="text-gray mt-2">Etapa 1 de creare profil, user și parolă, unde aplicanții vor înregistra cu semnătură electronică, atât datele reprezentantului legal/împuternicitului, conform Anexei nr. 3, cât și datele întreprinderii eligibile, conform art. 3 din prezenta procedură.<br>
  Beneficiarii care și-au creat deja profil, user și parolă în cadrul platformei în vederea primirii unui ajutor de stat în baza OUG nr. 130/2020 privind unele măsuri pentru acordarea de sprijin financiar din fonduri externe nerambursabile, aferente Programului operational Competitivitate 2014-2020, în contextual crizei provocate de COVID-19, precum și alte măsuri în domeniul fondurilor europene, cu modificările și completările ulterioare, vor putea folosi respectivul profil în cadrul prezentei Scheme.
  </p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card-body">
        <h2 class="title-center">ETAPA 2</h2>
        <p class="text-gray mt-1">Etapa 2 de înscriere în cadrul Schemei, în care aplicanții, cu profil, user și parolă generate în prima etapă, vor completa formularul electronic de înscriere, conform Anexei nr. 1 la prezenta procedură, vor încărca declarații pe proprie răspundere, conform anexelor nr. 4-6 la prezenta procedură (declarație ajutor de stat, declarație tip întreprindere, formular de calcul pentru întreprinderi partenere sau legate), vor încărca copie după certificatul de clasificare pentru structurile de primire cu funcțiuni de cazare, copie după autorizația de funcționare sau după certificatul de clasificare pentru structurile de alimentație publică, copie după autorizația sanitar- veterinară pentru unitățile de alimentație publică mobile, copie după certificatul constatator eliberat de Oficiul Național Registrul Comerțului pentru organizatorii de evenimente și copie după atestat pentru ghizii turistici, respectiv copie după licență pentru agențiile de turism. Prin completarea formularului de înscriere beneficiarii își vor lua angajamentul de plată a datoriilor restante către bugetul central, angajamentul privind menținerea activității pentru 6 luni, respectiv 12 luni, își vor asuma pe proprie răspundere că întreprinderea nu era în dificultate la 31 decembrie 2019 sau că întreprinderea era în dificultate la 31 decembrie 2019 în condițiile excepției de la art. 3 alin (4) și (5) din OUG nr. 224/2020, cu modificările și completările ulterioare, că nu este în insolvență și că nu are decizie de recuperare emisă de Comisia Europeană/alt furnizor de ajutor de stat/Consiliul Concurenței, conform art. 3 alin (6) din OUG nr. 224/2020, cu modificările și completările ulterioare.<br>
        Tot în această etapă, beneficiarii vor încărca raportul de expertiză contabilă, întocmit conform ISAE 3000 (revizuit), alte misiuni de asigurare decât auditurile sau revizuirile, informațiilor financiare istoric emis de International Auditing and Assurance Standards Board (IAASB) și cu Manualul privind Codul etic internațional pentru profesioniștii contabili (ediția 2018), emis de International Ethics Standards Board for Accountants (IESBA), din cadrul International Federation of Accountants (FAC)/raportul de audit, întocmit conform ISRS 4400 „Misiuni pe baza procedurilor convenite”, împreună cu anexa la acesta (raportul sinteză), asumat de expertul contabil/auditorul financiar. Anexa la raport va fi realizată conform Anexei nr. 7 la prezenta procedură.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body">
    <h3 class="title">Checklist-ul meu în 7 pași simpli</h3>
    <div class="row">
      <div class="col-xl-4 card-body">
      <ul class="step-list">
        <li>
          <feather-icon
              class="mr-25"
              :color="steps.includes('one') ? 'green' : 'red'"
              :icon="steps.includes('one') ? 'CheckCircleIcon' : 'XCircleIcon'"
          />
          Pasul 1 - Semnătura digitală</li>
        <li>
          <feather-icon
              class="mr-25"
              :color="steps.includes('two') ? 'green' : 'red'"
              :icon="steps.includes('two') ? 'CheckCircleIcon' : 'XCircleIcon'"
          />
          Pasul 2 - Crearea contului în platforma de granturi
        </li>
        <li>
          <feather-icon
              class="mr-25"
              :color="steps.includes('three') ? 'green' : 'red'"
              :icon="steps.includes('three') ? 'CheckCircleIcon' : 'XCircleIcon'"
          />
          Pasul 3 - Adăugarea companiei în cont
        </li>
        <li><feather-icon
            class="mr-25"
            :color="steps.includes('four') ? 'green' : 'red'"
            :icon="steps.includes('four') ? 'CheckCircleIcon' : 'XCircleIcon'"
        />
          Pasul 4 - Analiza eligibilității companiei
        </li>
        <li>
          <feather-icon
              class="mr-25"
              :color="steps.includes('five') ? 'green' : 'red'"
              :icon="steps.includes('five') ? 'CheckCircleIcon' : 'XCircleIcon'"
          />
          Pasul 5 - Pregătirea documentelor companiei (2019 - 2020)
        </li>
      </ul>
      </div>
      <div class="col-xl-4 card-body">
        <ul class="step-list">
            <li>
              <feather-icon
                  class="mr-25"
                  :color="steps.includes('six') ? 'green' : 'red'"
                  :icon="steps.includes('six') ? 'CheckCircleIcon' : 'XCircleIcon'"
              />
              Pasul 6 - Obținerea raportului de expertiză contabilă / audit
            </li>
            <li>
              <feather-icon
                class="mr-25"
                :color="steps.includes('seven') ? 'green' : 'red'"
                :icon="steps.includes('seven') ? 'CheckCircleIcon' : 'XCircleIcon'"
            />
              Pasul 7 - Completarea cererii de finanțare
            </li>
      </ul>
      </div>
      <div class="col-xl-4 card-body">
        <ul class="step-none">
            <li><strong>RUE</strong> - Obținerea numărului RUE și urmărirea aplicației tale</li>
            <li><strong>Contract</strong> - Semnarea contractului de finanțare</li>
            <li><strong>Implementare</strong> - Implementarea programului</li>
          <li><strong>Raportare cheltuieli</strong> - Implementarea programului <i>(*conform procedurii, nu exista momentan obligatii in acest sens)</i></li>
      </ul>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body p-0"
       :class="{'active':steps.includes('one')}"
  >
    <div class="pas-big-div">
      <div class="pas-div">
        <div class="step-title">
          <h2 class="title-center">PASUL</h2>
          <div class="big mt-3"><p>1</p></div>
        </div>

        <div class="checkboxes">
          <b-form-group>
            <b-form-checkbox
                name="check-button"
                class="mb-1"
                switch
                inline
                v-model="steps"
                @click.native="handleActive"
                value="one"
            >
              Am deja semnătură
            </b-form-checkbox>
            <b-form-checkbox
                name="check-button"
                switch
                inline
                v-model="steps"
                @click.native="handleActive" e
                value="one"
            >
              Am completat acest pas
            </b-form-checkbox>
          </b-form-group>
        </div>

      </div>
      <div class="info-div">
        <h3>OBȚINEREA UNEI SEMNĂTURI DIGITALE</h3>
        <p class="mb-0">Pentru crearea contului in platforma de granturi este necesară existența unei semnături digitale / electronice. Semnătura electronică calificată este acea semnătură electronică care se bazează pe un certificat calificat pentru semnăturile electronice, conform Regulamentului european 910/2014, emis de un prestator de servicii de încredere calificat. Certificatul calificat de semnătură electronică este obligatoriu atât pentru înregistrarea în sistem, cât şi pentru semnarea cererii de finanţare și  asigură autenticitatea, integritatea și non-repudierea documentelor depuse. Dacă nu aveți o semnătură digitală achiziționați una de la cei 4 furnizori acreditați de mai jos.</p>
        <div class="pircing">
          <a target="_blank" href="//digisign.ro" class="product">
            <div class="product-img"><img src="@/assets/images/horeca/logo_digisign.png"></div>
            <p class="digi">29,9 €</p>
          </a>
          <a target="_blank" href="//www.transsped.ro" class="product">
            <div class="product-img"><img src="@/assets/images/horeca/logo_tsp.png"></div>
            <p class="tsp">30 €</p>
          </a>
          <a target="_blank" href="//alfatrust.ro" class="product">
            <div class="product-img"><img src="@/assets/images/horeca/logo_alfatrust.png"></div>
            <p class="alfa">35 €</p>
          </a>
          <a target="_blank" href="//certsign.ro/ro/" class="product">
            <div class="product-img"><img src="@/assets/images/horeca/logo_certsign.png"></div>
            <p class="cert">47 €</p>
          </a>
        </div>
        <p class="font-weight-light italic mb-0">Prețurile sunt exprimate în euro și nu conțin TVA. Echipa SMIS nu deține relații contractuale sau de publicitate cu niciunul dintre cei patru furnizori. Prețurile sun cele publice afișate
          în oferta furnizorilor de servicii de certificare la data de 17.06.2021. Alegerea furnizorului este exclusiv a dumneavoastră.</p>
      </div>
      <div class="time-div">
        <div class="info-timp">
          <img src="@/assets/images/horeca/timp.png">
          <p>1 - 3 zile</p>
        </div>
        <div class="info-timp">
          <img src="@/assets/images/horeca/pret.png">
          <p>172 - 288 lei</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-body p-0"
       :class="{'active':steps.includes('two')}"
  >
    <div class="pas-big-div">
      <div class="pas-div">
        <div class="step-title">
          <h2 class="title-center">PASUL</h2>
          <div class="big mt-3"><p>2</p></div>
        </div>

        <div class="checkboxes">
          <b-form-group>
            <b-form-checkbox
                name="check-button"
                class="mb-1"
                switch
                inline
                v-model="steps"
                @click.native="handleActive"
                value="two"
            >
              Am deja cont
            </b-form-checkbox>
            <b-form-checkbox
                name="check-button"
                switch
                inline
                v-model="steps"
                @click.native="handleActive"
                value="two"
            >
              Am completat acest pas
            </b-form-checkbox>
          </b-form-group>
        </div>

      </div>
      <div class="info-div">
        <h3>CREAREA CONTULUI ÎN PLATFORMA DE GRANTURI</h3>
        <div class="video-info">
          <div class="text-left">
            <p class="mb-0">Dacă ai deja un cont prin care ai depus cerere pentru Măsura 1 sau Măsura 2 nu este necesar să îți creezi un alt cont.</p>
            <p class="mt-1 mb-1">(5.2. (1) Beneficiarii care și-au creat deja profil, user și parolă în cadrul platformei în vederea primirii unui ajutor de stat în baza OUG nr. 130/2020 privind unele măsuri pentru acordarea de sprijin financiar din fonduri externe nerambursabile, aferente Programului operational Competitivitate 2014-2020, în contextual crizei provocate de COVID-19, precum și alte măsuri în domeniul fondurilor europene, cu modificările și completările ulterioare, vor putea folosi respectivul profil în cadrul prezentei Scheme.)</p>
            <b-img
                fluid
                :src="require('@/assets/images/horeca/pas-2.png')"
            />
            <p class="mt-1 mb-1">Aplicația electronică aferentă primei etape de înscriere (creare profil, user și parolă) va rămâne deschisă pe toată perioada înscrierilor în cadrul Schemei. Aplicația electronică aferentă celei de a doua etape de înscriere în cadrul Schemei va fi deschisă începând cu ora 10.00 a primei zile de înscriere, timp de 10 zile lucrătoare de la demararea înscrierii, până la ora 20.00 a ultimei zile de înscriere, cu posibilitatea de prelungire a termenului până la epuizarea bugetului.
            </p>
          </div>
          <div class="video-right">
            <div class="video-title mb-1">
              <p class="mb-0">Tutorial - crearea contului în platforma de granturi</p>
              <img src="@/assets/images/horeca/echipa-smis.png">
            </div>
            <div class="videoWrapper">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/XTkNVIvVcTU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="time-div">
        <div class="info-timp">
          <img src="@/assets/images/horeca/timp.png">
          <p>20 minute</p>
        </div>
        <div class="info-timp">
          <img src="@/assets/images/horeca/pret.png">
          <p>fără costuri</p>
        </div>
        <div class="info-timp gov">
          <img src="@/assets/images/horeca/guvernul-romaniei.png">
          <p>Crează cont nou</p>
          <p><a target="_blank" href="http://granturi.imm.gov.ro">granturi.imm.gov.ro</a></p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card">
        <div class="card-body p-0"
             :class="{'active':steps.includes('three')}"
        >
          <div class="pas-big-div">
            <div class="pas-div">
              <div class="step-title">
                <h2 class="title-center">PASUL</h2>
                <div class="big mt-3"><p>3</p></div>
              </div>

              <div class="checkboxes">
                <b-form-group>
                  <b-form-checkbox
                      name="check-button"
                      switch
                      v-model="steps"
                      @click.native="handleActive"
                      value="three"
                  >
                    Am completat acest pas
                  </b-form-checkbox>
                </b-form-group>
              </div>

            </div>
            <div class="info-div">
              <h3>INTRODUCEREA COMPANIEI TALE IN PLATFORMĂ</h3>
              <div class="video-info">
                <div class="text-left">
                  <p class="mb-3 mt-1 bold">Loghează-te în contul creat la pasul anterior și adaugă companii la contul tău.</p>
                  <p class="mt-1 mb-1 bold">Pași pentru adăugarea companiei:</p>
                  <ul class="pl-0 mb-4 list-steps">
                    <li>1. Înscriere PJ / CUI - Declarația de reprezentant legal / împuternicit</li>
                    <li>2. Completarea informațiilor despre companie / dimensiune</li>
                    <li>3. Încadrarea în categoria IMM</li>
                    <li>4. Declarația privind ajutoarele de stat</li>
                    <li>5. Informațiile despre acționari / asociați / titulari</li>
                    <li>6. Atașarea de documente suplimentare</li>
                  </ul>
                  <p class="mt-1 mb-1 bold">Vă recomandăm să parcurgeți mai întâi tutorialul alăturat</p>
                  <img class="horeca-logo" src="@/assets/images/horeca/logoHorecaManager.png">
                </div>
                <div class="video-right">
                  <div class="video-title mb-1">
                    <p class="mb-0">Tutorial - introducerea companiilor în platfroma de granturi</p>
                    <img src="@/assets/images/horeca/echipa-smis.png">
                  </div>
                  <div class="videoWrapper">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/zXDXZHRRy9Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="time-div">
              <div class="info-timp">
                <img src="@/assets/images/horeca/timp.png">
                <p>30 minute</p>
              </div>
              <div class="info-timp">
                <img src="@/assets/images/horeca/pret.png">
                <p>fără costuri</p>
              </div>
              <div class="info-timp gov">
                <img src="@/assets/images/horeca/guvernul-romaniei.png">
                <p>Login - contul tău</p>
                <p><a target="_blank" href="http://granturi.imm.gov.ro">granturi.imm.gov.ro</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>

<div class="card">
        <div class="card-body p-0"
             :class="{'active':steps.includes('four')}"
        >
          <div class="pas-big-div">
            <div class="pas-div">
              <div class="step-title">
                <h2 class="title-center">PASUL</h2>
                <div class="big mt-3"><p>4</p></div>
              </div>

              <div class="checkboxes">
                <b-form-group>
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="steps"
                      @click.native="handleActive"
                      value="four"
                  >
                    Am completat acest pas
                  </b-form-checkbox>
                </b-form-group>
              </div>

            </div>
            <div class="info-div">
              <h3>ANALIZA ELIGIBILITĂȚII COMPANIEI TALE</h3>
              <div class="video-info">
                <div class="text-left">
                  <p class="mb-1 mt-1 bold text-cyan">Asigură-te că firma ta este eligibilă.</p>
                  <p class="mt-1 mb-1 bold">La momentul aplicării trebuie să te asiguri că ai autorizat unul din codurile CAEN eligibil, cod/uri CAEN pentru care s-a realizat baza de calcul.</p>
                  <p class="mt-1 mb-1">Codul / rile CAEN trebuie autorizate la locația menționată în aplicația informatică de granturi, la secțiunea - LOCAȚIE DE IMPLEMENTARE</p>
                  <p class="mt-1 mb-1">Codul CAEN eligibil poate fi Principal sau Secundar.</p>
                  <p class="mt-1 mb-1">Codul CAEN principal se regasește pe Certificatul de înregistrare al companiei.</p>
                  <p class="mt-1 mb-5">Codurile CAEN secundare se regăsesc în actul constitutiv. Atenție însă, există posibilitatea ca aceste coduri CAEN sa nu fie însă autorizate. Pentru verificare puteți utiliza infoCERT - online iar costul verificării este de 20 lei.</p>
                  <p class="mt-1 mb-1 bold">Vă recomandăm să parcurgeți mai întâi tutorialul alăturat</p>
                </div>
                <div class="video-right">
                  <div class="video-title mb-1">
                    <p class="mb-0">Tutorial - Obținere certificat ONRC online</p>
                    <img src="@/assets/images/horeca/echipa-smis.png">
                  </div>
                  <div class="videoWrapper">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/AdHzJf8F30c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="time-div">
              <div class="info-timp">
                <img src="@/assets/images/horeca/timp.png">
                <p>20 minute</p>
              </div>
              <div class="info-timp">
                <img src="@/assets/images/horeca/pret.png">
                <p>20 lei* <span class="font-weight-light optional">(opțional)</span></p>
              </div>
              <div class="info-timp onrc">
                <img src="@/assets/images/horeca/logo-onrc.png">
                <p>Vizitează</p>
                <p><a target="_blank" href="http://granturi.imm.gov.ro">portal.onrc.ro</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>

<div class="card">
        <div class="card-body p-0"
             :class="{'active':steps.includes('five')}"
        >
          <div class="pas-big-div">
            <div class="pas-div">
              <div class="step-title">
                <h2 class="title-center">PASUL</h2>
                <div class="big mt-3"><p>5</p></div>
              </div>

              <div class="checkboxes">
                <b-form-group>
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="steps"
                      @click.native="handleActive"
                      value="five"
                  >
                    Am pregătit documentele
                  </b-form-checkbox>
                </b-form-group>
              </div>

            </div>
            <div class="info-div">
              <h3>PREGĂTIREA DOCUMENTELOR COMPANIEI (2019 - 2020)</h3>
              <div class="video-info">
                <div class="text-left">
                  <p class="mb-3 mt-1 bold text-cyan">Aplicanții vor încărca in platforma (după caz):</p>
                  <ul class="pl-1 mb-4">
                    <li><strong>Certificat de clasificare</strong></li>
                    <p>pentru structurile de primire cu funcțiuni de cazare</p>
                    <li><strong>Autorizația de funcționare sau certificatul de clasificare</strong></li>
                    <p>pentru structurile de alimentație publică,</p>
                    <li><strong>Autorizația sanitar- veterinară</strong></li>
                    <p>pentru unitățile de alimentație publică mobile (foodtruck)</p>
                    <li title="Acest document nu mai este necesar (update 22 iunie) MEAT, STS si ONRC  au identificat soluția tehnică pentru verificarea automată a istoricului autorizării codului CAEN."><strong><s>Certificatul constatator eliberat de ONRC</s></strong></li>
                    <p>pentru organizatorii de evenimente</p>
                    <li><strong>Atestat ghizid turistic, respectiv licență</strong></li>
                    <p>pentru agențiile de turism.</p>
                    <li><strong>Raportul de audit / expertiză contabilă + Anexa 7</strong></li>
                  </ul>
                </div>
                <div class="video-right">
                  <p class="mb-3 mt-1 bold text-cyan">Documente generate in mod automat de platforma de granturi:</p>
                  <ul class="pl-1 mb-2">
                    <li><strong>Anexa 1 - formular înscriere</strong></li>
                    <li><strong>Anexa 2 - contract de finanțare</strong></li>
                    <li><strong>Anexa 4 - declarație ajutor de stat</strong></li>
                    <li><strong>Anexa 5 - declarație tip întreprindere</strong></li>
                    <li><strong>Anexa 6 - calcul întreprinderi</strong></li>
                  </ul>
                  <p class="mb-3 mt-1 bold text-cyan">Alte documente</p>
                  <ul class="pl-1 mb-4" style="list-style-type: none; padding-left:0 !important;">
                    <li><a style="color: #6e6b7b;" href="/horeca/Anexa-3-Model-Imputernicire.docx" download><img src="@/assets/images/horeca/download.png"> <strong>Anexa 3 - împuternicire</strong></a></li>
                    <li><a style="color: #6e6b7b;" href="/horeca/Anexa-7-Anexa-Raport.docx" download><img src="@/assets/images/horeca/download.png"> <strong>Anexa 7 - se depune obligatoriu împreună cu Raportul de audit / Expertiza contabilă</strong></a></li>
                  </ul>
                </div>
              </div>
              <div class="footer-step">
                <p>Este necesar ca documentele să fie semnate digital cu același dispozitiv token (semnatură digitală) cu care s-a creat contul.</p>
              </div>
            </div>
            <div class="time-div">
              <div class="info-timp">
                <img src="@/assets/images/horeca/timp.png">
                <p>20 minute</p>
              </div>
              <div class="info-timp">
                <img src="@/assets/images/horeca/pret.png">
                <p>fără costuri</p>
              </div>
            </div>
          </div>
        </div>
      </div>

<div class="card">
        <div class="card-body p-0"
             :class="{'active':steps.includes('six')}"
        >
          <div class="pas-big-div">
            <div class="pas-div">
              <div class="step-title">
                <h2 class="title-center">PASUL</h2>
                <div class="big mt-3"><p>6</p></div>
              </div>

              <div class="checkboxes">
                <b-form-group>
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="steps"
                      @click.native="handleActive"
                      value="six"
                  >
                    Am obținut raportul
                  </b-form-checkbox>
                </b-form-group>
              </div>

            </div>
            <div class="info-div">
              <h3>OBȚINEREA RAPORTULUI DE EXPERTIZĂ CONTABILĂ / AUDIT</h3>
              <div class="video-info">
                <div class="text-left">
                  <p class="mb-2 mt-1 bold text-cyan">Aplicanții vor încărca in platforma (după caz) :</p>
                  <p class="mb-2 black text-title">RAPORTUL DE EXPERTIZĂ CONTABILĂ</p>
                  <p class="mb-2">Raportul de expertiză contabilă, va fi întocmit conform ISAE 3000 (revizuit), alte misiuni de asigurare decât auditurile sau revizuirile, informațiilor financiare istoric emis de International Auditing and Assurance Standards Board (IAASB) și cu Manualul privind Codul etic internațional pentru profesioniștii contabili (ediția 2018), emis de International Ethics Standards Board for Accountants (IESBA), din cadrul International Federation of Accountants (FAC). În aceste condiții, Ministerul Economiei face apel la potențialii beneficiari să pregătească din timp raportul de expertiză contabilă, întocmit conform reglementărilor CECCAR – Standardului profesional nr. 35 „Expertize contabile”</p>
                  <p class="mb-2 black text-title">RAPORTUL DE AUDIT</p>
                  <p class="mb-2">Raportul de audit, va fi întocmit conform ISRS 4400 „Misiuni pe baza procedurilor convenite”, împreună cu anexa la acesta (raportul sinteză), asumat de auditorul financiar. Anexa la raport va fi realizată conform Anexei nr. 7 la prezenta procedură.</p>
                </div>
                <div class="video-right">
                  <div class="atentie">
                    <p class="bold">Este necesar ca documentele să fie semnate electronic
                      de expertul contabil/societatea de expertiză contabilă/
                      auditorul financiar/firma de audit</p>
                    <img class="warning-sign" src="@/assets/images/horeca/warningSign.png">
                  </div>
                  <p class="mb-0 mt-3 bold">Identificarea unui auditor / expert contabil acreditat</p>
                  <p  class="mb-2 text-small">Dacă nu ai găsit încă un auditor financiar / expert contabil pentru a realiza Raportul de expertiză îți punem la dispoziție un modul dedicat identificării acestor experți.</p>
                  <div class="contabili">
                    <div class="contabili-left">
                      <div class="contabil">
                        <img class="warning-sign" src="@/assets/images/horeca/logo_CECCAR.png">
                        <p class="text-blue">Corpul Experţilor Contabili şi Contabililor Autorizaţi din România</p>
                      </div>
                      <div class="contabil">
                        <img class="warning-sign" src="@/assets/images/horeca/logo_cafr.png">
                        <p class="text-green">Camera Auditorilor Financiari din România</p>
                      </div>
                    </div>
                    <router-link
                        :to="{
                          path: '/horeca-expert-auditor',
                          name: 'horeca-expert-auditor',
                        }"
                    ><b-button type="submit" class="buton-submit-setari-cont" variant="primary"
                    >Caută gratuit un expert</b-button
                    ></router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="time-div">
              <div class="info-timp">
                <img src="@/assets/images/horeca/timp.png">
                <p>1-7 zile</p>
              </div>
              <div class="info-timp">
                <img src="@/assets/images/horeca/pret.png">
                <p>1.200 - 6.000 lei</p>
              </div>
            </div>
          </div>
        </div>
      </div>

<div class="card">
        <div class="card-body p-0"
             :class="{'active':steps.includes('seven')}"
        >
          <div class="pas-big-div">
            <div class="pas-div">
              <div class="step-title">
                <h2 class="title-center">PASUL</h2>
                <div class="big mt-3"><p>7</p></div>
              </div>

              <div class="checkboxes">
                <b-form-group>
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="steps"
                      @click.native="handleActive"
                      value="seven"
                  >
                    Am finalizat acest pas
                  </b-form-checkbox>
                </b-form-group>
              </div>

            </div>
            <div class="info-div">
              <h3>COMPLETAREA ȘI TRANSMITEREA CERERII DE FINANȚARE</h3>
              <div class="video-info">
                <div class="text-left">
                  <!--<p class="mb-2 mt-1 bold text-cyan">Această secțiune va fi disponibilă în curând.</p>-->
                  <p class="mb-4 mt-2 black text-title">Tutorial HORECA - Oficial - Ministerul Economiei</p>
                  <!--<p class="mb-2">Vă ținem la curent prin e-mail în acest sens</p>-->
                  <div class="col-xl-7 col-lg-7 col-md-7">
                      <div class="col-xl-12 card green-bg">
                        <p class="text-white text-margin-top">dată lansare apel</p>
                        <p class="text-align-right text-white font-w-bold">29.06.2021 - ora 10:00</p>
                      </div>
                      <div class="col-xl-12 card red-bg mb-1">
                        <p class="text-white text-margin-top">dată limită depunere</p>
                        <p class="text-align-right text-white font-w-bold">26.07.2021 - ora 20:00</p>
                      </div>
                    <div class="col-xl-12 card gray-bg mb-1">
                      <div class="flexed">
                        <img src="@/assets/images/horeca/guvernul-romaniei.png">
                        <div class="right">
                          <p class="text-gov mb-0"><a target="_blank" href="https://granturi.imm.gov.ro">Login - contul tău</a></p>
                          <p class="text-gov-small mb-0"><a target="_blank" href="https://granturi.imm.gov.ro">granturi.imm.gov.ro</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                <div class="video-right">
                  <div class="videoWrapper">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/3xxohvWN0N4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div class="time-div">
              <div class="info-timp">
                <img src="@/assets/images/horeca/timp.png">
                <p>5 minute</p>
              </div>
              <div class="info-timp">
                <img src="@/assets/images/horeca/pret.png">
                <p>fără costuri</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
  import {
      BForm,
      BFormGroup,
      BFormCheckbox,
      BImg,
      BButton
  } from 'bootstrap-vue'
    import HorecaLogo from './components/HorecaLogo'
    import Logo from '@core/layouts/components/Logo.vue'

    export default {
        components: {
            HorecaLogo,
            Logo,
            BForm,
            BFormGroup,
            BFormCheckbox,
            BImg,
            BButton
        },
        data() {
            return {
              steps: [],
            }
        },
        mounted() {
        },
        methods: {
            handleActive(e) {
              const content = e.target;
              if(content.value === undefined) {
                return;
              } else {
                if(this.steps.includes(e.target.value)) {
                  this.steps.splice(this.steps.indexOf(e.target.value), 1);
                } else {
                  this.steps.push(e.target.value);
                }
              }
              localStorage.setItem('Steps', JSON.stringify(this.steps));
            }
        },
        computed: {

        },
        created() {
          if (localStorage.getItem('Steps')) {
            this.steps = JSON.parse(localStorage.getItem('Steps'))
          }
        },
    }
</script>
<style scoped>
    .font-w-bold{
      font-weight: bold !important;
    }
    .card-body {
      border: 1px solid transparent;
    }
    .card-body.active {
      border: 1px solid #34d1ba;
      border-radius: 7px;
    }
    .card-body.active .info-div, .card-body.active .time-div {
      background-color: #b3f8ee;
    }
    .pas-big-div > div {
      padding: 1rem;
    }
    .videoWrapper {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
    }
    .green-bg {
      background-color: #45efd6 !important;
    }
    .red-bg {
      background-color: #ff8476 !important;
    }
    .gray-bg{
      background-color: #f1f1f1 !important;
    }
    .gray-bg img{
      max-height: 50px;
    }
    .flexed{
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 15px 0px;
    }
    .right .text-gov a{
      color: #004990;
      font-weight: 600;
      font-size: 17px;
    }
    .right .text-gov-small a{
      color: #004990;
      font-weight: 600;
      font-size: 12px;
    }
    p.text-gov-small.mb-0{
      text-align: center;
    }
    .videoWrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 1px);
      height: 100%;
    }
    .tutorial-soon{
      display: flex;
      align-items: flex-end;
    }
     .card>.row>.card-body{
    padding-left: 2.5rem;
    padding-right: 2.5rem; 
  }

  .borderLeftRight{
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    padding: 0 20px
  }
  .echipa-smis{
    display: flex;
    align-content: center;
  }
  .info-div .text-align-right{
    text-align: right;
    font-style: italic;
    font-size: 20px;
  }
  .text-margin-top {
    margin-top: 5px;
  }
  .procedura{
   display: flex;
   align-items: flex-end;
   justify-content: space-between;
  }
  .echipa-smis img{
    max-height: 20px;
    margin-left: 5px;
  }
  .procedura img{
    height: 100%;
  }
  .title-center{
    font-weight: 900;
    text-align: center;
  }
 .title{
   font-weight: 900;
   font-size: 18px;
   color: #898a93;
 }
  .text-gray{
    font-weight: 500;
  }
  .step-list{
    list-style: none;
    padding: 0;
  }
  .pas-big-div{
    display: flex;
  }
  .pas-div{
    width: 14%;
    border-right: 1px solid #dbdbdb;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .time-div{
    width: 15%;
    border-left: 1px solid #dbdbdb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
  }
  .info-div{
    width: 71%;
  }
  .time-div p{
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    color: #898a93;
  }
  .info-timp{
    text-align: center;
    max-width: 80%;
  }
  .info-timp:not(:first-child){
    margin-top: 20px;
  }
  .info-div{
    padding: 20px 40px 0px 40px;
  }
  .pas-big-div .title-center{
    font-size: 18px;
    color: #898a93;
    padding-top: 10px;
  }
  .big{
    font-size: 70px;
    font-weight: 900;
    text-align: center;
    color: #34d1ba;
  }
  .big p{
    text-align: center;
  }
  .info-div h3{
    font-size: 18px;
    color: #898a93;
    padding-bottom: 10px;
  }
  .info-div p{
    font-size: 13px;
    font-weight: 600;
  }
  .pircing{
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 30px 0px;
  }
  .product{
    text-align: center;
  }
  .product p{
    font-size: 29px;
    font-weight: 700;
    text-align: center;
  }
  .product-img{
    min-height: 70px;
  }
  .digi{
    color: #b3303f;
  }
  .tsp{
    color: #003d73;
  }
 .alfa{
   color: #231f20;
 }
 .cert{
   color: #d9291b;
 }
 .italic{
   font-style: italic;
 }
 .step-none{
   list-style: none;
 }
 .video-info{
   display: flex;
   justify-content: space-between;
 }
 .video-info .bold{
   font-weight: 600;
 }
 .video-title{
   display: flex;
   align-items: flex-end;
   justify-content: space-between;
 }
 .video-info p{
   font-weight: 400;
 }
 .tutorial-soon{
   height: 100%;
 }
  img.tutorial-indisponibil{
   border: 1px solid #c1c1c1;
   padding: 100px;
    width: 100%;
    height: 88%;
    object-fit: contain;
 }
 .text-left{
   width: 50%;
   margin-right: 29px;
 }
 .video-right{
   width: 50%;
 }
 .video-right .text-small{
   font-size: 11px;
 }
 .text-left p{
   line-height: 1.3em;
 }
 .text-left p:first-child{
   font-weight: 600;
 }
 p{
   text-align: justify;
 }
 .gov p{
   color: #004990;
   text-align: center;
   margin: 0;
 }
 .gov a{
   color: inherit;
 }
 .gov p:nth-child(2){
   font-size: 18px;
   font-weight: 900;
   margin-top: 10px;
 }
 .gov p{
   font-size: 13px;
   font-weight: 600;
 }
  .onrc p{
    color: #ff7e6f;
    text-align: center;
    margin: 0;
  }
  .onrc a{
    color: inherit;
  }
  .onrc p:nth-child(2){
    font-size: 18px;
    font-weight: 900;
    margin-top: 10px;
  }
  .onrc p{
    font-size: 13px;
    font-weight: 600;
  }
  .font-bold{
    font-weight: 600;
  }
    ul.list-steps{
    list-style: none;
  }
  img.horeca-logo{
    max-width: 245px;
  }
  .text-cyan{
    color: #34d1ba;
  }
  .text-left .black{
    font-weight: 900;
  }
  .info-timp p{
    text-align: center;
  }
  .optional {
    font-size: 15px;
  }
  img.warning-sign{
    max-width: 14%;
  }
  .atentie{
    display: flex;
    justify-content: space-between;
  }
  .atentie p{
    max-width: 400px;
  }
  .contabili{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .contabil{
    display: flex;
  }
  .contabil img{
    max-width: 14%;
    object-fit: contain;
    margin-right: 15px;
  }
  .contabil p{
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    letter-spacing: -0.5px;
    max-width: 230px;
  }
  .contabil .text-blue{
    color: #1d3460;
  }
  .contabil .text-green{
    color: #004f39;
  }
</style>
