<template>
  <div class="row">
    <div
      v-for="(document, index) in documents"
      :key="index"
      class="half"
      :class="document.dimension"
    >
      <div class="row">
        <div class="col-xl-8">
          <div class="card cardInside">
            <div class="card-body pb-0">
              <h4 class="card-title pb-1 document-title">
                {{ document.title }}
              </h4>
              <p class="text-justify mb-0">
                {{ document.description }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <a :href="document.link" download>
            <div class="card cardInside">
              <div class="card-body space-between justify-content-center">
                <div class="text-center downloadInner">
                  <h4>{{ document.action }}</h4>
                  <b-img
                    :src="require(`@/assets/images/m2/${document.imgUrl}.png`)"
                    alt="Descarcare Document"
                    fluid
                  />
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  name: 'DescarcareDocument',
  props: {
    documents: {},
  },
  components: {
    BImg,
  },
}
</script>

<style>
.document-title {
  border-bottom: 1px solid #ccc;
  font-size: 1.2rem !important;
}
.cardInside {
  height: 100%;
}
.half {
  margin-bottom: 20px;
}
.col-xl-8,
.col-xl-4 {
  margin-bottom: 10px;
}
.downloadInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}
</style>
