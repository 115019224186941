<template>
    <b-badge class="badge badge-info">
        {{getPlans.some((plan) => plan.id === 1)
        ? 'M2 Manager Standard'
        : 'M2 Manager Premium'}}
    </b-badge>
</template>

<script>
    import config from '@/config'
    import axiosIns from '@/libs/axios'
    import {
        BBadge
    } from 'bootstrap-vue'
    export default {
        components: {
            BBadge
        },
        computed: {
            getPlans() {
                return this.$store.state.auth.user
                    ? this.$store.state.auth.user.plans
                    : []
            },
        },
        data() {
            return {
                company: []
            }
        },
        created() {

        },
        mounted() {
            axiosIns
                .get(config.API_ENDPOINTS.getM2data)
                .then(({ data }) => {
                    this.company = data.data.company;
                })
        }
    }

</script>