import MonografiaContabila from '@/views/pages/horeca/MonografiaContabila'
import CautaExpertAuditor from '@/views/pages/horeca/CautaExpertAuditor'
import PrezentareProgram from '@/views/pages/horeca/PrezentareProgram'
import IntrebariFrecvente from '@/views/pages/horeca/IntrebariFrecvente'
import Eligibilitate from '@/views/pages/horeca/Eligibilitate' 
import Obligații from '@/views/pages/horeca/Obligații'
import PasiDepunere from '@/views/pages/horeca/PasiDepunere'
import CalculeazaAjutor from '@/views/pages/horeca/CalculeazaAjutor'
import DocumenteProcedura from '@/views/pages/horeca/DocumenteProcedura' 
import StiriProceduraHoreca from '@/views/pages/horeca/StiriProceduraHoreca'
import ContacteAIPIMM from '@/views/pages/horeca/ContacteAIPIMM'
import TutorialVideoDepunere from '@/views/pages/horeca/TutorialVideoDepunere'  
import IdentitateVizuală from '@/views/pages/horeca/IdentitateVizuală'
import GrantulMeu from '@/views/pages/horeca/GrantulMeu'
import TutorialRaportProgres from '@/views/pages/horeca/TutorialRaportProgres'
import FormulareClarificări from '@/views/pages/horeca/FormulareClarificări'


import middleware from '@/middleware'

export default [
    {
        path: '/horeca-prezentare-program',
        name: 'horeca-prezentare-program',
        component: PrezentareProgram,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Prezentare program',
            breadcrumb: [
                {
                    text: 'Prezentare program',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-eligibilitate',
        name: 'horeca-eligibilitate',
        component: Eligibilitate,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Eligibilitate',
            breadcrumb: [
                {
                    text: 'Eligibilitate',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-obligatii',
        name: 'horeca-obligatii',
        component: Obligații,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Obligații',
            breadcrumb: [
                {
                    text: 'Obligații',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-calculeaza-ajutor',
        name: 'horeca-calculeaza-ajutor',
        component: CalculeazaAjutor,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Calculează ajutor',
            breadcrumb: [
                {
                    text: 'Calculează ajutor',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-expert-auditor',
        name: 'horeca-expert-auditor',
        component: CautaExpertAuditor,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Caută expert / auditor',
            breadcrumb: [
                {
                    text: 'Caută expert / auditor',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-documente-procedura',
        name: 'horeca-documente-procedura',
        component: DocumenteProcedura,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Documente procedură',
            breadcrumb: [
                {
                    text: 'Documente procedură',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-stiri-procedura',
        name: 'horeca-stiri-procedura',
        component: StiriProceduraHoreca,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Știri procedura Horeca',
            breadcrumb: [
                {
                    text: 'Știri procedura Horeca',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-pasi-depunere',
        name: 'horeca-pasi-depunere',
        component: PasiDepunere,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Pași pentru depunere',
            breadcrumb: [
                {
                    text: 'Pași pentru depunere',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-contacte-aimmaipe',
        name: 'horeca-contacte-aimmaipe',
        component: ContacteAIPIMM,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Contacte AIMMAIPE',
            breadcrumb: [
                {
                    text: 'Contacte AIMMAIPE',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-tutoriale-video-depunere',
        name: 'horeca-tutoriale-video-depunere',
        component: TutorialVideoDepunere,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Tutorial video depunere',
            breadcrumb: [
                {
                    text: 'Tutorial video depunere',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-intrebari-implementare',
        name: 'horeca-intrebari-implementare',
        component: IntrebariFrecvente,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Întrebări implementare',
            breadcrumb: [
                {
                    text: 'Întrebări implementare',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-monografia-contabila',
        name: 'horeca-monografia-contabila',
        component: MonografiaContabila,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Monografia Contabilă',
            breadcrumb: [
                {
                    text: 'Monografia Contabilă',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-identitate-vizuala',
        name: 'horeca-identitate-vizuala',
        component: IdentitateVizuală,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Identitate vizuală',
            breadcrumb: [
                {
                    text: 'Identitate vizuală',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-grantul-meu',
        name: 'horeca-grantul-meu',
        component: GrantulMeu,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Grantul meu',
            breadcrumb: [
                {
                    text: 'Grantul meu',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-tutorial-raport-progres',
        name: 'horeca-tutorial-raport-progres',
        component: TutorialRaportProgres,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Tutorial raport progres',
            breadcrumb: [
                {
                    text: 'Tutorial raport progres',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-formulare-clarificari',
        name: 'horeca-formulare-clarificari',
        component: FormulareClarificări,
        beforeEnter: middleware.horeca,
        meta: {
            pageTitle: 'Formulare clarificări',
            breadcrumb: [
                {
                    text: 'Formulare clarificări',
                    active: true,
                },
            ],
        },
    },
]
