<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <Logo/>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Înregistrare cont 🚀 </b-card-title>
          <!--<b-card-text class="mb-2">-->
          <!--Make your app management easy and fun!-->
          <!--</b-card-text>-->

          <!-- form -->
          <ValidationObserver ref="registerForm" #default="{ invalid }">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <b-form-group label="Nume complet" label-for="register-name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="name"
                    name="register-name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nume prenume"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- phone -->
              <b-form-group label="Telefon" label-for="register-email">
                <validation-provider
                        #default="{ errors }"
                        name="Phone"
                        vid="phone"
                >
                  <b-form-input
                          type="number"
                          id="register-phone"
                          v-model="userPhone"
                          name="register-phone"
                          autocomplete=""
                          :state="errors.length > 0 ? false : null"
                          placeholder="0722222222"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="Email" label-for="register-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    autocomplete="username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="Parolă">
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      autocomplete="new-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="register-confirm-password"
                label="Confirmare Parolă"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirmare Parolă"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-confirm-password"
                      v-model="confirmPassword"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-confirm-password"
                      autocomplete="retype-password"
                      placeholder="············"
                    />
                    <!--<b-input-group-append is-text>-->
                    <!--<feather-icon-->
                    <!--:icon="passwordToggleIcon"-->
                    <!--class="cursor-pointer"-->
                    <!--@click="togglePasswordVisibility"-->
                    <!--/>-->
                    <!--</b-input-group-append>-->
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Termeni și condiții"
                  :rules="{ required: { allowFalse: false } }"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Sunt de acord cu
                    <b-link @click="showTAndCModal">termenii și condițiile</b-link>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
              >
                <span v-if="loading">
                  <b-spinner small label="Loading..."></b-spinner> &nbsp; Va
                  rugam asteptati
                </span>
                <span v-else>Înregistrare cont</span>
              </b-button>
            </b-form>
          </ValidationObserver>

          <p class="text-center mt-2">
            <span>Ai deja un cont?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Logare</span>
            </b-link>
          </p>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->

          <b-modal
                  v-model="tAndCModalShow"
                  id="modal-lg"
                  size="lg"
                  ok-only
                  ok-title="Sunt de acord"
                  modal-class="modal-primary"
                  centered
                  title="Termeni și condiții"
                  @ok="status = true"
          >
            <TermsAndConditions />
          </b-modal>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BSpinner,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BModal
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'

import TermsAndConditions from '../m2-manager/TermsAndConditions.vue'

import config from '@/config'
import { startSession, logIn } from '@/auth'
import axios from '@axios'
import Logo from '@core/layouts/components/Logo.vue'

export default {
  components: {
    Logo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BSpinner,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    // validations
    ValidationProvider,
    ValidationObserver,
    TermsAndConditions
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      name: '',
      userEmail: '',
      userPhone: '',
      password: '',
      confirmPassword: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      loading: false,
      tAndCModalShow: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(async (success) => {
        if (success) {
          this.loading = true
          await startSession()

          axios
            .post(config.API_ENDPOINTS.register, {
              name: this.name,
              email: this.userEmail,
              phone: this.userPhone,
              password: this.password,
              password_confirmation: this.confirmPassword,
            })
            .then(() => {
              this.loading = false
              this.$store.dispatch('auth/logoutForVerification').then(() => {
                this.$router.replace(config.LOGIN_ROUTE)
              })
            })
            .catch((error) => {
              this.$refs.registerForm.setErrors(error.response.data.errors)
              this.loading = false
            })
        }
      })
    },
    showTAndCModal() {
        this.tAndCModalShow = true;
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.modal-lg {
  max-width: 70% !important;
}
@media(max-width: 1000px) {
  #modal-lg{
    padding-left: 0 !important;
  }
  .modal-lg {
    max-width: 95% !important;
  }
}
</style>
