<template>
  <b-card>
    <h4 class="mb-2">
      {{
        getPlans.some((plan) => plan.id === 1)
          ? 'Îți mulțumim pentru alegera aplicației M2manager '
          : 'Îți mulțumim pentru alegera aplicației M2manager Premium'
      }}
    </h4>
    <div class="row">
      <div class="col-lg-8">
        <h1>{{ company.name }} - {{ company.business_id }}</h1>
        <div class="mt-2">
          <p>Solicită documentele tale, accesând link-urile de mai jos:</p>
          <b-button
            variant="primary"
            class="mr-3"
            :disabled="loading || error !== null || invoice_requested !== null"
            @click="sendInvoiceRequest()"
          >
            <span v-if="loading">
              <b-spinner small label="Loading..."></b-spinner> &nbsp; Vă rugăm
              așteptați
            </span>
            <span v-else>{{ buttonText }}</span>
          </b-button>
          <b-button
            variant="outline-primary"
            @click="OPFormVisible = !OPFormVisible"
          >
            Confirmă plata
          </b-button>
        </div>
        <div class="text-danger pt-2" v-if="error">
          <small>{{ error }}</small>
        </div>
      </div>
      <div class="col-lg-4">
        <M2ManagerLogos :class="right" />
      </div>
    </div>
    <div v-show="OPFormVisible">
      <div class="mt-2" v-if="uploadedOPs.length > 0">
        <p>Fișiere încărcate anterior</p>
        <ul>
          <li v-for="uploadedOP in uploadedOPs" :key="uploadedOP.id">
            <a
              href=""
              download
              @click.prevent="
                downloadOPFile({
                  url: `/m2/download_op/${uploadedOP.uuid}`,
                  name: uploadedOP.original_filename,
                })
              "
              >{{ uploadedOP.original_filename }}
            </a>
          </li>
        </ul>
      </div>
      <b-card-text class="p-2 mt-2 border">
        <b-form-group label="Ordin de plată:">
          <b-form-file
            placeholder="Alege OP pentru încărcat"
            drop-placeholder="Trage aici OP-ul..."
            id="ordinPlata"
            size="md"
            v-model="OPFile"
          ></b-form-file>
        </b-form-group>
        <b-form-group>
          <b-button variant="primary" class="mr-3" @click="uploadOP()">
            <span v-if="loadingUpload">
              <b-spinner small label="Loading..."></b-spinner> &nbsp; Vă rugăm
              așteptați
            </span>
            <span v-else>Încarcă OP</span>
          </b-button>
        </b-form-group>
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import config from '@/config'
import store from '@/store'
import axiosIns from '../../../libs/axios'
import M2ManagerLogos from '../m2-pages/components/M2ManagerLogos'
import {
  BCard,
  BButton,
  BCardText,
  BImg,
  BSpinner,
  BFormGroup,
  BFormFile,
} from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BButton,
    BCardText,
    BImg,
    BSpinner,
    BFormGroup,
    BFormFile,
    M2ManagerLogos,
  },
  data() {
    return {
      company: [],
      right: 'float-right',
      loading: true,
      loadingUpload: false,
      error: null,
      OPFormVisible: false,
      OPFile: null,
      uploadedOPs: [],
      invoice_requested: null,
      invoice_sent: null,
    }
  },
  computed: {
    getPlans() {
      return store.state.auth.user ? store.state.auth.user.plans : []
    },

    buttonText() {
      return this.invoice_sent
        ? `Contract trimis la ${this.invoice_requested}`
        : this.invoice_requested
        ? `Solicitat la ${this.invoice_requested}`
        : `Solicită contract și factura`
    },
  },

  created() {
    this.refreshStore()
  },
  mounted() {
    axiosIns
      .get(config.API_ENDPOINTS.getM2data)
      .then(({ data }) => {
        this.company = data.data.company
      })
      .catch(() => {
        this.error = 'Nu am putut asocia contul tău cu o companie'
      })
    this.getRequestStatus()
    this.loadOPs()
  },
  methods: {
    sendInvoiceRequest() {
      this.loading = true
      axiosIns
        .post(config.API_ENDPOINTS.requestInvoice)
        .then(({ data }) => {
          this.invoice_requested = data.invoice_requested
          this.refreshStore()
        })
        .catch((err) => {
          this.error = err.message
        })
        .finally(() => {
          this.loading = false
        })
    },

    getRequestStatus: function () {
      this.invoice_requested =
        store.state.auth.user.plans.length &&
        store.state.auth.user.plans[0].invoice_requested
      this.invoice_sent =
        store.state.auth.user.plans.length &&
        store.state.auth.user.plans[0].invoice_sent
    },

    refreshStore() {
      this.loading = true
      store.dispatch('auth/getUserData').then(() => {
        this.getRequestStatus()
        this.loading = false
      })
    },

    uploadOP() {
      if (this.OPFile) {
        this.loadingUpload = true
        const formData = new FormData()
        formData.append('file', this.OPFile)

        axiosIns
          .post(config.API_ENDPOINTS.uploadOP, formData)
          .then((response) => {
            this.loadOPs()
            this.OPFile = null
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.loadingUpload = false
          })
      }
    },

    loadOPs() {
      axiosIns
        .get(config.API_ENDPOINTS.getUploadedOPs)
        .then(({ data }) => {
          this.uploadedOPs = data.data
        })
        .catch((err) => console.log(err))
    },

    downloadOPFile({ url, name }) {
      axiosIns
        .get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = name
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },
  },
}
</script>

