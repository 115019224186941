<template>
  <div>
    <b-card>
      <h4 class="mb-0">
        {{
          getPlans.length
            ? 'Următoarele pachete sunt asociate contului tău:'
            : 'Nu există niciun pachet asociat contului tău!'
        }}
      </h4>
      <small v-if="!getPlans.length" class="text-secondary">
        Pentru a alege un pachet, alege din meniul alăturat
        <b>Serviciile mele</b>
      </small>
    </b-card>

    <b-card
      v-show="getPlans.some(plan => plan.program_id === program.id)"
      v-for="program in programs"
      :key="program.id"
    >
      <div v-for="pkg in program.packages" :key="pkg.id">
        <div v-if="getPlans.find(plan => plan.id === pkg.id)">
          <account-package
            :plan="getPlans.find(plan => plan.id === pkg.id)"
            :packg="pkg"
          ></account-package>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'
import AccountPackage from './AccountPackage'
import { BCard } from 'bootstrap-vue'
export default {
  components: {
    BCard,
    AccountPackage,
  },
  props: ['programs'],

  computed: {
    getPlans() {
      return store.state.auth.user ? store.state.auth.user.plans : []
    },
  },

  created() {
    store.dispatch('auth/getUserData')
  },
}
</script>
