<template>
  <div>
    <form-wizard
      color="#01e8c6"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Trimite"
      back-button-text="Înapoi"
      next-button-text="Continuă"
      class="mb-3"
      ref="wizard"
      @on-complete="formSubmitted"
    >
      <!-- account details tab -->
      <tab-content
        class="px-3"
        title="Termeni și condiții"
        :before-change="validationForm"
      >
        <validation-observer ref="accountRules" tag="form">
          <b-row class="text-center">
            <b-col md="12">
              <h1>Salut!</h1>
              <br />
              <h3>Îți mulțumim pentru că alegi să utilizezi M2manager.</h3>
              <br />
              <h3>
                Instrumentul de implementare se adresează atât firmelor care au
                primit grantul pentru capital de lucru, cât și firmelor ce
                urmează a primi grantul. În cele ce urmează te rugăm să
                completezi câteva informații. Acestea ne ajută in configurarea
                profilului tău si sunt necesare aplicației de implementare.
              </h3>
              <br />
              <h3>Citește mai jos Termenii si condițiile.</h3>
              <br />
            </b-col>

            <div class="overflow-auto h-12 border p-1 text-justify">
              <TermsAndConditions />
            </div>
            <b-form-group class="mx-auto mt-2">
              <b-form-checkbox
                id="agree_tnc"
                v-model="agree_tnc"
                name="checkbox-1"
              >
                Am citit și sunt de acord cu termenii si condițiile de mai sus
              </b-form-checkbox>
              <small class="text-success" v-if="terms_accepted"
                >Acceptat în data de
                {{ new Date(terms_accepted).toLocaleString('ro-RO') }}</small
              >
            </b-form-group>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content title="Compania ta" :before-change="validationFormInfo">
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-1">
              <h5 class="mb-0">Compania ta</h5>
              <small class="text-muted"
                >Completează datele companiei tale</small
              >
              <h5 class="mt-1" v-if="this.formData.company.name">
                Bine ai venit! Firma ta este
                <strong class="mt-2" style="color: #01e8c6">{{
                    this.formData.company.name
                  }}</strong>
              </h5>
            </b-col>
            <b-col xl="4" lg="4">
              <b-form-group label="CUI" label-for="cui">
                <validation-provider
                  #default="{ errors }"
                  name="CUI"
                  vid="company.business_id"
                  rules="required"
                >
                  <b-input-group size="lg">
                    <b-form-input
                      :disabled="!!formData.company.id"
                      id="cui"
                      v-model="formData.company.business_id"
                      placeholder="CUI"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-button variant="primary" @click="getByCui"
                      >Interogare</b-button
                    >
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] || cui_info }}</small>
                  <!-- <small class="text-danger">{{ cui_info }}</small> -->
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xl="4" lg="4">
              <b-form-group
                label="Valoare estimată grant"
                label-for="valoare_grant"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Valoare estimată grant"
                  rules="required"
                >
                  <b-input-group size="lg" append="LEI">
                    <b-form-input
                      :disabled="!!formData.company.id"
                      class="text-right"
                      id="valoare_grant"
                      v-model="formData.estimated_value"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xl="4" lg="4">
              <validation-provider
                #default="{ errors }"
                name="valoare cofinantare"
                rules="required"
              >
                <b-form-group
                  label="Valoare cofinanțare"
                  label-for="valoare_cofinantare"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-input-group size="lg" append="LEI">
                    <b-form-input
                      :disabled="!!formData.company.id"
                      class="text-right"
                      id="valoare_cofinantare"
                      v-model="formData.co_finance_value"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                    />
                  </b-input-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col xl="4" lg="4" class="mb-1">
              <b-form-group label="Numar RUE aplicatie" label-for="rue">
                <validation-provider
                  #default="{ errors }"
                  name="rue"
                  rules="required"
                >
                  <b-input-group size="lg">
                    <b-form-input
                      :disabled="!!formData.company.id"
                      type="number"
                      id="rue"
                      v-model="formData.rue_no"
                      placeholder="Numar RUE aplicatie"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xl="4" lg="4" class="mb-1">
              <b-form-group
                label="Agentia ta pentru IMM"
                label-for="agentia_imm"
              >
                <validation-provider
                  #default="{ errors }"
                  name="agentia_imm"
                  rules="required"
                >
                  <b-form-group size="lg">
                    <v-select
                      class="selectInput"
                      :disabled="!!formData.company.id"
                      v-model="agency"
                      label="name"
                      item-value="id"
                      :options="agentii_imm"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12" class="my-1">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="received"
              >
                <b-form-group
                  label="Ai primit grantul pentru capital de lucru?"
                >
                  <b-form-radio-group
                    v-model="formData.received"
                    :options="[
                      {
                        text: 'Da, am primit grantul',
                        value: 'da',
                        disabled: false,
                      },
                      {
                        text: 'Nu, nu am primit grantul',
                        value: 'nu',
                        disabled: false,
                      },
                      {
                        text: 'Nu stiu să răspund',
                        value: 'nu_stiu',
                        disabled: false,
                      },
                    ]"
                    value-field="value"
                    text-field="text"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="12" class="mb-1" v-if="formData.received == 'da'">
              <validation-provider
                      #default="{ errors }"
                      name="data_semnare_contract"
                      rules="required"
              >
                <b-form-group>
                  <label>Data semnare contract </label>
                    <feather-icon
                        @click="dataSemnarePopup = true"
                        size="22"
                        class="ml-1"
                        icon="InfoIcon"
                        color="#e84141"
                    />
                  <b-form-datepicker
                      placeholder="Alege data"
                      :start-weekday="1"
                      :date-format-options="{
                        year: 'numeric',
                        day: 'numeric',
                        month: 'numeric',
                  }"
                      v-model="formData.contract_signed"
                      local="ro-RO"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12" class="mb-1" v-if="formData.received == 'da'">
              <validation-provider
                      #default="{ errors }"
                      name="data_primire_grant"
                      rules="required"
              >
                <b-form-group>
                    <label>Data la care ai primit grantul</label>
                    <feather-icon
                        @click="dataPrimiriiPopup = true"
                        size="22"
                        class="ml-1"
                        icon="InfoIcon"
                        color="#e84141"
                    />
                  <b-form-datepicker
                      id="datepicker-placeholder"
                      placeholder="Alege data"
                      :start-weekday="1"
                      :date-format-options="{
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      }"
                      v-model="formData.grant_received"
                      local="ro-RO"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address  -->
      <tab-content
        title="Detaliile companiei"
        :before-change="validationFormAddress"
      >
        <h5 class="mb-2">
          Bine ai venit! Firma ta este
          <strong style="color: #01e8c6">{{
            this.formData.company.name
          }}</strong>
        </h5>
        <validation-observer ref="addressRules" tag="form">
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="address"
                rules="required"
              >
                <b-form-group
                  label="Locația de implementare a proiectului"
                  label-for="valoare_cofinantare"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-input-group size="lg">
                    <b-form-input
                      id="address"
                      v-model="formData.company.address"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Adresă - stradă, număr, oraș"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider #default="{ errors }" name="website">
                <b-form-group
                  label="Site web (dacă există)"
                  label-for="website"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-input-group size="lg">
                    <b-form-input
                      id="website"
                      v-model="formData.company.website"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Site web"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-group
                  label="E-mail"
                  label-for="email"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-input-group size="lg">
                    <b-form-input
                      id="email"
                      v-model="formData.company.email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="E-mail"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <b-form-group
                  label="Telefon"
                  label-for="phone"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-input-group size="lg">
                    <b-form-input
                      type="number"
                      id="phone"
                      v-model="formData.company.phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Telefon"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="contact"
                rules="required"
              >
                <b-form-group
                  label="Nume complet persoană contact"
                  label-for="contact"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-input-group size="lg">
                    <b-form-input
                      id="contact"
                      v-model="formData.company.contact"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nume complet persoană contact"
                    />
                  </b-input-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-media no-body>
                <b-media-aside v-if="this.formData.company.logo">
                  <b-img
                    rounded
                    :src="this.formData.company.logo"
                    height="67"
                  />
                </b-media-aside>

                <b-media-body class="mt-20 ml-20">
                  <validation-provider #default="{ errors }" name="logo">
                    <b-form-group
                      label="Logo companie"
                      label-for="logo"
                      :state="errors.length > 0 ? false : null"
                    >
                      <b-form-file
                        size="lg"
                        v-model="logo"
                        placeholder="Alege logo-ul companiei"
                        drop-placeholder="Trage aici logo-ul..."
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
      <b-modal
          v-model="dataSemnarePopup"
          id="dataSemnarePopup"
          size="1252"
          modal-class="modal-error"
          title="Unde poți găsi data semnarii contractului?"
          centered
          hide-footer
      >
          <b-img
              fluid
              center
              :src="require('@/assets/images/m2/contract-semnat.png')"
          />
      </b-modal>
      <b-modal
          v-model="dataPrimiriiPopup"
          id="modal-md"
          size="542"
          modal-class="modal-error"
          title="Unde poți găsi data primirii grantului?"
          centered
          hide-footer
      >
          <b-img
              fluid
              center
              :src="require('@/assets/images/m2/contract-platit.png')"
          />
      </b-modal>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import TermsAndConditions from './TermsAndConditions.vue'

import grantM2 from './m2-grant.json'
import agentii_imm from './m2-agencies.json'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormRadioGroup,
  BFormDatepicker,
  BInputGroup,
  BButton,
  BInputGroupAppend,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BModal
} from 'bootstrap-vue'

import axios from '@axios'
import config from '@/config'

export default {
  components: {
    TermsAndConditions,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    vSelect,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormDatepicker,
    BInputGroup,
    BButton,
    BInputGroupAppend,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    ToastificationContent,
    BModal
  },
  data() {
    return {
      dataSemnarePopup: false,
      dataPrimiriiPopup: false,
      grantM2,
      cui_info: '',
      logo: null,
      agentii_imm,
      agree_tnc: false,
      terms_accepted: null,
      agency: null,
      formData: {
        company: {
          id: null,
          name: null,
          business_id: null,
          contact: null,
          address: null,
          website: null,
          email: null,
          phone: null,
          logo: null,
        },
        estimated_value: null,
        co_finance_value: null, // valoare_cofinantare
        m2_agency_id: null,
        rue_no: null,
        received: null,
        grant_received: null,
        contract_signed: null,
      },
      storedData: null,
    }
  },
  mounted() {
    axios
      .get(config.API_ENDPOINTS.getM2data)
      .then(({ data }) => {
        if (data.data && data.data.terms_accepted) {
          this.terms_accepted = data.data.terms_accepted
          this.agree_tnc = true
          this.$refs.wizard.changeTab(0, 1)
          this.storedData = data.data
          this.formData = data.data
        }
      })
      .catch((error) => console.log(error))
  },

  watch: {
    agency: function (updatedAgency) {
      this.formData.m2_agency_id = updatedAgency.id
    },

    'storedData.m2_agency_id': function () {
      this.agentii_imm.map((agentie) => {
        if (this.formData.m2_agency_id === agentie.id) {
          return (this.agency = agentie)
        }
      })
    },

    // formData: {
    //   deep: true,
    //   handler() {
    //     console.log(
    //       this.$refs.infoRules.errors.length,
    //       this.$refs.addressRules.errors.length
    //     )
    //   },
    // },
  },
  methods: {
    getByCui() {
      if (this.formData.company.business_id) {
        const { grantM2 } = this.grantM2
        let filter = grantM2.filter(
          (firma) => firma.cui === this.formData.company.business_id
        )

        if (filter.length === 1) {
          this.formData.estimated_value = filter[0].grant
          this.formData.co_finance_value = (filter[0].grant * 15) / 100
          this.formData.rue_no = filter[0].rue
          this.cui_info = ''
          this.formData.company.name = filter[0].firma

          this.agentii_imm.map((agentie) => {
            if (filter[0].Judet === agentie.county) {
              return (this.agency = agentie)
            }
          })
        } else {
          this.cui_info = 'CUI-ul introdus nu se află printre beneficiari.'

          // Empty fields
          this.formData.estimated_value = ''
          this.formData.co_finance_value = ''
          this.formData.co_finance_value = ''
          this.formData.rue_no = ''
          this.formData.company.name = ''
        }
      } else {
        this.cui_info = 'Introdu CUI-ul companiei tale';

        // Empty fields
        this.formData.estimated_value = ''
        this.formData.co_finance_value = ''
        this.formData.co_finance_value = ''
        this.formData.rue_no = ''
        this.formData.company.name = ''
      }
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Salvat cu success',
          icon: 'EditIcon',
          variant: 'success',
          text: 'Datele introduse au fost salvate',
        },
      })
      // Redirect to Identitate vizuala after form submitted
      this.$router.push('/m2-manager/identitate-vizuala')
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(() => {
          // terms were not previously accepted and are accepted now
          if (!this.terms_accepted && this.agree_tnc) {
            axios
              .post(config.API_ENDPOINTS.startM2, {})
              .then(() => {
                this.terms_accepted = new Date().toLocaleString('ro-RO')
                resolve(true)
              })
              .catch((error) => {
                console.log(error)
                reject()
              })
            // terms have been accepted previously
          } else if (this.terms_accepted) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      this.getByCui();
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            if ({ ...this.formData } !== { ...this.storedData }) {
              axios
                .patch(config.API_ENDPOINTS.updateM2data, this.formData)
                .then(({ data }) => {
                  this.storedData = data.data
                  this.formData = data.data
                  this.$root.$emit('newCompanyCreated')
                  resolve(true)
                })
                .catch((error) => {
                  console.dir(error)
                  this.$refs.infoRules.setErrors(error.response.data.errors)
                    window.location.reload()
                })
            }
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            if ({ ...this.formData } !== { ...this.storedData }) {
              axios
                .patch(config.API_ENDPOINTS.updateM2data, this.formData)
                .then(({ data }) => {
                  this.storedData = data.data
                  this.formData = data.data
                  resolve(true)
                })
                .catch((error) => console.log(error))

              this.uploadLogo()
            }
          } else {
            reject()
          }
        })
      })
    },

    uploadLogo() {
      if (this.logo) {
        const fd = new FormData()
        fd.append('logo', this.logo)

        axios
          .post(config.API_ENDPOINTS.updateCompanyLogo, fd)
          .then((response) => {
            this.formData.company.logo = response.data.logo
          })
          .catch((error) => console.log(error))
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.h-12 {
  height: 300px;
}
.selectInput > .vs__dropdown-toggle{
  padding: 6px 0;
}
.vs--disabled .vs__search {
  background: transparent;
}
div.modal .modal-dialog.modal-1252 {
    width: 100% !important;
    max-width: 1252px;
}
div.modal .modal-dialog.modal-542 {
    width: 100% !important;
    max-width: 542px;
}
</style>
