<template>
  <div class="mt-0 mb-3">
    <hr v-if="!sectionTitle">
    <div class="card mt-0 mb-1">
      <div class="card-body space-between align-items-center pt-1 pb-1">
        <h2 class="m-0">
          {{(sectionTitle ? sectionTitle : 'Prezentarea modulului')}}
        </h2>
      </div>
    </div>

    <div class="videoWrapper">
      <iframe width="560" height="315" :src="linkEmbed" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>

import { $themeColors } from '@themeConfig'

export default {
  name: 'YoutubeEmbed',
  props: {
    link : '',
    sectionTitle : '',
  },
  data() {
    return {
      linkEmbed: `https://www.youtube.com/embed/${this.link}`,
    }
  },
}

</script>

<style scoped>
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 1px);
    height: 100%;
  }
</style>
