<template>
    <b-img
            :src="getImageSrc"
            alt="M2 Manager"
            class="props.class"
            height="props.height"
    />
</template>

<script>
    import {
        BImg
    } from 'bootstrap-vue'
    export default {
        components: {
            BImg
        },
        computed: {
            getPlans() {
                return this.$store.state.auth.user
                    ? this.$store.state.auth.user.plans
                    : []
            },
            getImageSrc() {
                if(this.getPlans.some((plan) => plan.id === 1)) {
                    return require('@/assets/images/m2/m2-logo-basic.png');
                } else if (this.getPlans.some((plan) => plan.id === 2)) {
                    return require('@/assets/images/m2/m2-logo.png');
                } else if (this.getPlans.some((plan) => plan.id === 3)) {
                    return require('@/assets/images/m2/m2-logo-premium.png')
                }
            }
        }
    }

</script>