<template>
    <div v-if="!this.loading">
        <div class="row">
            <div class="col-xl-12">
                <b-alert class="mt-0" variant="danger" show v-if="!isM2Manager">
                    <div class="alert-body">
                  <span>
                    ATENȚIE! Nu ai selectat pachetul M2 Manager. Pentru a vedea această pagină alege pachetul M2 Manager accesând
                    <router-link
                            :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'serviciile-mele' },
                      }"
                    ><strong>Serviciile mele</strong></router-link>.
                  </span>
                    </div>
                </b-alert>
                <b-alert class="mt-0" variant="danger" show v-else-if="!isPaid">
                    <div class="alert-body">
                  <span>
                    Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea <router-link
                          :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'factura-contract' },
                      }"
                  ><strong>Factură si Contract</strong></router-link>. În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager)

                  </span>
                    </div>
                </b-alert>
            </div>
        </div>
        <b-row class="row" v-if="isM2Manager">
            <b-col v-if="!hasFinished" class="column" cols="12" sm="12" md="6" xl="4">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title border-bottom-primary pb-1" style="text-align: right; color: #01e8c6">
                            Data Finalizarii</h4>
                        <div v-if="this.companyId">
                            <p class="text-2xl">Ai Finalizat implementarea?</p>
                            <p class="font-weight-bolder" style="font-size: 25px"> Felicitări!</p>
                            <div class="mt-5 mb-5">
                                <h4 class="card-title">Timp rămas pentru finalizare:</h4>
                                <p class="daysRemaining">{{this.days_left}}<span> zile</span></p>
                            </div>
                            <div class="mb-5 mt-5">
                                <b-card-text class="text-justify">
                                    Alege data de finalizare a proiectului. Te rugăm să te asiguri mai întâi că ai trimis Raportul de progres în platforma granturi.imm.gov.ro
                                </b-card-text>
                            </div>

                            <div>
                                <b-form-group label="Data finalizare proiect:">
                                    <b-form-datepicker
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="ro"
                                            :max="new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())"
                                            id="datepicker"
                                            v-model="data.dataFinala"
                                            @input="erroareDataFinala = ''"
                                    ></b-form-datepicker>
                                </b-form-group>
                                <p class="text-danger">{{ erroareDataFinala }}</p>
                            </div>

                            <div class="button-container" style="text-align: center">
                                <b-button class="mt-1" variant="primary" @click="saveFinalReleaseDate">
                                    Data este corectă, generează comunicatul
                                </b-button>
                            </div>
                        </div>
                        <div v-else>
                            <h4 class="mt-5">
                                Această secțiune este disponibilă după adăugarea companiei. Adaugă o companie din secțiunea
                                <router-link to="/grantul-meu"><strong>Grantul meu</strong></router-link>
                                .
                            </h4>
                        </div>
                    </div>
                </div>
            </b-col>

            <b-col v-else class="column" cols="12" sm="12" md="6" xl="4">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title border-bottom-primary pb-1" style="text-align: right; color: #01e8c6">
                            Comunicatul tău</h4>

                        <div class="mb-2 mt-2">
                            <b-img class="identitate-item-img" center
                                   :src="require('@/assets/images/m2/identitateVizuala_comunicat-presa.png')"/>
                        </div>

                        <div class="mb-2 mt-2">
                            <b-card-text class="text-justify">
                                Comunicatul de presă este întocmit în conformitate cu art. 6.12 din Proedura de implementare a măsurii "Granturi pentru capital de lucru acordate IMM-urilor" , cu modelul de comunicat prezentat pe imm.gov.ro și cu Manualul de identitate vizuală FSE.
                            </b-card-text>
                        </div>

                        <div>
                            <a href="" download @click.prevent="downLoadComunicatFinal()" class="space-between">
                                <div>DESCARCĂ COMUNICATUL IN FORMAT PDF</div>
                                <b-img class="pdf-download" :src="require('@/assets/images/m2/pdf-download.png')"/>
                            </a>
                        </div>

                        <div class="mt-1 space-between align-center">
                            <b-button
                                    class="mr-1"
                                    target="_blank"
                                    variant="primary"
                                    :href="companyId"
                            >
                                <a>Vezi comunicatul publicat</a>
                            </b-button>
                            <b-button
                                    target="_blank"
                                    variant="outline-primary"
                                    download
                                    href="/m2/obligatii-identitate/comunicat.pdf"
                            >
                                Obligații identitate vizuală
                            </b-button>
                        </div>
                        <div class="mt-2">
                            <b-form-group label="Schimbă data comunicatului final:">
                                <b-form-datepicker
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                        locale="ro"
                                        :max="new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())"
                                        id="datepicker"
                                        v-model="data.dataFinala"
                                        @input="erroareDataFinala = ''"
                                ></b-form-datepicker>
                            </b-form-group>
                            <p class="text-danger">{{ erroareDataFinala }}</p>
                        </div>

                        <div class="button-container" style="text-align: center">
                            <b-button class="mb-1" variant="primary" @click="saveFinalReleaseDate">
                                Data este corectă, regenerează comunicatul
                            </b-button>
                        </div>
                    </div>
                </div>
            </b-col>

            <b-col class="column" cols="12" sm="12" md="6" xl="4">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title border-bottom-primary pb-1" style="text-align: right; color: #01e8c6">
                            Mulțumirile noastre</h4>
                        <div>
                            <h4 class="mb-1 mt-1"> Salut, <span style="color: #01e8c6"> {{this.customerName}} </span>
                            </h4>
                            <b-card-text class="text-justify">
                                Numele meu este <span class="font-weight-bolder">Alexandru Picioruș</span>
                                și reprezint în calitate de fondator &amp; project manager platforma smis.ro.
                            </b-card-text>

                            <b-card-text class="text-justify">
                                Îți mulțumim pentru utilizarea platformei smis.ro și a aplicației M2manager! Echipa noastră va dezvolta și alte aplicații de implementare pentru proiectele finanțate din fonduri nerambursabile. Te vom ține la curent cu activitatea noastă.
                            </b-card-text>

                            <div style="display:flex; align-items:center; justify-content: space-between;">
                                <b-card-text>
                                    Îți mulțumim, <br>
                                    Echipa SMIS
                                </b-card-text>
                                <b-card-text>
                                    <b-img fluid :src="require('@/assets/images/m2/semnatura-alex.png')"/>
                                </b-card-text>
                            </div>

                        </div>
                        <div class="mt-3">
                            <b-img fluid center :src="require('@/assets/images/m2/echipa.png')"/>
                        </div>
                    </div>
                </div>
            </b-col>

            <b-col class="column" cols="12" sm="12" md="6" xl="4">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title border-bottom-primary pb-1" style="text-align: right; color: #01e8c6">
                            Feedback-ul tău</h4>
                        <div v-if="!this.isFormSubmitted">
                            <div v-if="showForm">
                                <h4 class="text-center"><span class="font-weight-bolder"> Încântat? :) </span>
                                    sau nu prea? :(  </h4>
                                <b-card-text class="text-left">

                                </b-card-text>

                                <b-card-text class="mt-1 text-justify">
                                    Feedback-ul tău e foarte important pentru noi! Ne ajută în a livra pe viitor și alte aplicații de implementare. Îți mulțumim!
                                </b-card-text>
                            </div>
                            <b-form v-if="showForm" class="auth-login-form" @submit.prevent="saveReview">
                                <div class="mt-2">
                                    <b-form-group label-for="Intrebare">
                                        <b-form-textarea
                                                id="Intrebare"
                                                v-model="review.reviewText"
                                                type="text"
                                                rows="5"
                                                name="review"
                                                placeholder="Mesajul tău"
                                        />
                                    </b-form-group>
                                </div>
                                <div>
                                    <b-form-rating id="rating-lg-no-border" v-model="review.stars" color="#01e8c6"
                                                   class="mb-2" no-border size="lg"></b-form-rating>
                                </div>
                                <div style="text-align: center">
                                    <b-button
                                            type="submit"
                                            class="mt-1"
                                            target="_blank"
                                            variant="primary"
                                            :disabled="loadingFeedback"
                                    >
                                    <span v-if="loadingFeedback">
                                    <b-spinner small label="Loading..."></b-spinner> &nbsp; Vă rugăm
                                    așteptați
                                    </span>
                                        <span v-else>Transmite feedback-ul tău</span>
                                    </b-button>
                                </div>
                            </b-form>
                            <b-badge class="mt-2 badge badge-light-success" v-else>
                                Recenzia a fost trimisă! Îți mulțumim!
                            </b-badge>
                            <div class="mt-3 mb-2">
                                <b-img width="150" center :src="require('@/assets/images/m2/m2-logo.png')"/>
                            </div>
                        </div>
                        <b-badge class="mt-2 badge badge-light-success" v-else>Îți mulțumim pentru recenzie!</b-badge>
                        <b-card-text class="text-center" style="font-size: 10px">
                            Vă mulțumim pentru feedback. Prin completarea mesajului vă oferiți acceptul  dumneavoastră pentru publicarea pe  www.smis.ro
                        </b-card-text>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal
                v-if="!showForm"
                v-model="feedbackForm"
                id="modal-md"
                size="542"
                modal-class="modal-error"
                centered
                hide-footer
        >
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title border-bottom-primary pb-1" style="text-align: right; color: #01e8c6">
                        Feedback-ul tău</h4>
                    <div v-if="!this.isFormSubmitted">
                        <div v-if="showForm">
                            <h4 class="text-center"><span class="font-weight-bolder"> Încântat? :) </span>
                                sau nu prea? :(  </h4>
                            <b-card-text class="text-left">

                            </b-card-text>

                            <b-card-text class="text-justify">
                                Feedback-ul tău e foarte important pentru noi! Ne ajută în a livra pe viitor și alte aplicații de implementare. Îți mulțumim!
                            </b-card-text>
                        </div>
                        <b-form v-if="showForm" class="auth-login-form" @submit.prevent="saveReview">
                            <div class="mt-2">
                                <b-form-group label-for="Intrebare">
                                    <b-form-textarea
                                            id="Intrebare"
                                            v-model="review.reviewText"
                                            type="text"
                                            rows="5"
                                            name="review"
                                            placeholder="Mesajul tău"
                                    />
                                </b-form-group>
                            </div>
                            <div>
                                <b-form-rating id="rating-lg-no-border" v-model="review.stars" color="#01e8c6"
                                               class="mb-2" no-border size="lg"></b-form-rating>
                            </div>
                            <div style="text-align: center">
                                <b-button
                                        type="submit"
                                        class="mt-1"
                                        target="_blank"
                                        variant="primary"
                                        :disabled="loadingFeedback"
                                >
                                <span v-if="loadingFeedback">
                                <b-spinner small label="Loading..."></b-spinner> &nbsp; Vă rugăm
                                așteptați
                                </span>
                                    <span v-else>Transmite feedback-ul tău</span>
                                </b-button>
                            </div>
                        </b-form>
                        <b-badge class="mt-2 badge badge-light-success" v-else>Recenzia a fost trimisă! Îți mulțumim!
                        </b-badge>
                        <div class="mt-2 mb-2">
                            <b-img width="150" center :src="require('@/assets/images/m2/m2-logo.png')"/>
                        </div>
                    </div>
                    <b-badge class="mt-2 badge badge-light-success" v-else>Îți mulțumim pentru recenzie!</b-badge>
                    <b-card-text class="text-center" style="font-size: 10px">
                        Vă mulțumim pentru feedback. Prin completarea mesajului vă oferiți acceptul  dumneavoastră pentru publicarea pe  www.smis.ro
                    </b-card-text>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import axiosIns from '../../../libs/axios'
    import config from '@/config'
    import store from '@/store/index'
    import {
        BBadge,
        BCard,
        BCol,
        BCardText,
        BFormDatepicker,
        BFormGroup,
        BFormTextarea,
        BFormRadio,
        BFormInput,
        BFormRating,
        BButton,
        BImg,
        BAlert,
        BRow,
        BForm,
        BSpinner,
        BModal
    } from 'bootstrap-vue'
    import moment from 'moment'
    import axios from '@/libs/axios'
    import ToastificationContent from '@core/components/toastification/ToastificationContent'

    export default {
        components: {
            BBadge,
            BCard,
            BCol,
            BCardText,
            BFormDatepicker,
            BFormGroup,
            BFormTextarea,
            BFormRadio,
            BFormInput,
            BFormRating,
            BButton,
            BImg,
            BAlert,
            BRow,
            BForm,
            BSpinner,
            BModal
        },
        data() {
            return {
                isPaid: false,
                isM2Manager: false,
                showForm: true,
                loading: true,
                loadingFeedback: false,
                days_left: null,
                data: {
                    dataFinala: null
                },
                review: {
                    reviewText: '',
                    stars: 5,
                },
                erroareDataFinala: '',
                customerName: store.state.auth.user.name,
                pressFinalPublished: null,
                isFormSubmitted: false,
                companyId: '',
                feedbackForm: false,
                hasFinished: false
            }
        },
        created() {

        },
        mounted() {
            this.showPayPopupHandler()
            this.isM2ManagerHandler()
            this.getFinalReleaseDate()
            this.getReview()
            axiosIns
                .get(config.API_ENDPOINTS.getM2data)
                .then(({data}) => {
                    if (data.data && data.data.terms_accepted) {
                        this.companyId =
                            'https://smis.ro/stiri/comunicate-presa?cui=' + data.data.company.business_id
                        this.terms_accepted = data.data.terms_accepted
                        this.formData = data.data;
                        this.pressFinalPublished = data.data.press_final_published;

                        this.end_project = moment(this.formData.grant_received).add(180, 'days').format("DD.MM.YYYY")

                        if (this.end_project !== 'Invalid date') {
                            let endProject = moment(this.end_project, 'DD-MM-YYYY');
                            let today = moment();
                            this.days_left = endProject.diff(today, 'days') + 1;
                            this.formData.grant_received = moment(data.data.grant_received).format('DD.MM.YYYY');
                        } else {
                            this.formData.grant_received = " - ";
                            this.end_project = " - ";
                            this.days_left = 180;
                            this.percentage = 100;
                        }
                        let days = 180;
                        let days_left = this.days_left;
                        this.percentage = parseInt((days_left * 100) / days);
                    } else {
                        if (this.formData) {
                            this.formData.grant_received = " - ";
                            this.end_project = " - "
                            this.days_left = 180;
                            this.percentage = 100;
                        }
                    }
                    this.loading = false;
                })
                .catch((error) => console.log('Error: ', error))
        },
        methods: {
            isM2ManagerHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isM2Manager = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.id === 2
                })
            },
            showPayPopupHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isPaid = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.program_id === 1 && key.paid_confirmed !== null
                })
            },
            downLoadComunicatFinal() {
                this.downloadPDF({
                    url: config.API_ENDPOINTS.m2PdfComunicatFinal,
                    name: 'comunicat_presa_final.pdf',
                })
            },
            downloadPDF({url, name}) {
                axios
                    .get(url, {responseType: 'blob'})
                    .then((response) => {
                        const blob = new Blob([response.data], {type: 'application/pdf'})
                        const link = document.createElement('a')
                        link.href = URL.createObjectURL(blob)
                        link.download = name
                        link.click()
                        URL.revokeObjectURL(link.href)
                    })
                    .catch(console.error)
            },
            getFinalReleaseDate() {
                axiosIns.get("/m2/pdf/get_date_comunicat_final_presa")
                    .then(response => {
                        console.log(response.data);
                        this.data.dataFinala = response.data
                        !!response.data ? this.hasFinished = true : this.hasFinished = false
                    })
                    .catch(e => {
                        console.log(e);
                        this.showToast('primary', 'Eroare', 'Comunicatul final NU a putut fi generat. Te rugam reincearca.');
                        this.errors.push(e);
                    })

            },
            saveFinalReleaseDate() {
                axiosIns.post("/m2/pdf/post_date_comunicat_final_presa", this.data)
                    .then(response => {
                        console.log(response.data.message);
                        if (response.data.message) {
                            //this.showFeedbackForm();
                            this.feedbackForm = true
                            this.showToast('primary', 'Succes', 'Comunicatul final a fost generat cu succes!');
                            this.pressFinalPublished = true
                            this.hasFinished = true
                        } else {
                            this.erroareDataFinala = 'Data finalizării proiectului este necesară.'
                            this.showToast('primary', 'Eroare', 'Comunicatul final NU a putut fi generat. Te rugam reincearca.')
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.showToast('primary', 'Eroare', 'Comunicatul final NU a putut fi generat. Te rugam reincearca.');
                        this.errors.push(e);
                    })

            },
            showToast(variant, title, msg) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: 'BellIcon',
                        text: msg,
                        variant,
                    },
                })
            },
            saveReview(){
                this.loadingFeedback = true
                axiosIns.post("/review", this.review)
                    .then(response => {
                        this.showToast('primary', 'Success', 'Mesajul a fost trimis cu succes!');
                        this.showForm = false;
                    })
                    .catch(e => {
                        this.showToast('primary', 'Error', 'Mesajul nu a fost trimis. Te rugam retrimite mesajul.');
                        this.errors.push(e);
                        this.loadingFeedback = false
                    })

            },
            getReview(){
                axios
                    .get('/getAllReviews')
                    .then(response => {
						console.log('response review', response.data);
                        this.isFormSubmitted = !!response.data.data;
                    })
                    .catch((e) => console.log(e.message))
            },
            showToast(variant, title, msg) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: 'BellIcon',
                        text: msg,
                        variant,
                    },
                })
            }
        },
    }
</script>
<style scoped>
    .column {
        flex: 1;
        display: flex;
    }

    .identitate-item > * {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .identitate-item {
        border: 1px solid #01e8c6 !important;
    }

    .identitate-item-img {
        width: max-content;
        margin: 0 auto;
    }

    .daysRemaining {
        color: #63cab3;
        font-size: 60px;
        font-weight: bold;
    }

    .daysRemaining span {
        color: #9f9f9f;
        font-size: 26px;
    }

    .badge {
        font-size: 16px;
        padding: 10px;
    }

    .badge.badge-light-success {
        font-size: 17px;
        padding: 10px;
        text-align: center;
        display: block;
        white-space: pre-wrap;
    }
</style>
