import DocumentsList from '@/views/pages/documents/DocumentsList'
import DocumentsForm from '@/views/pages/documents/DocumentsForm'
import NewsList from '@/views/pages/news/NewsList'
import NewsForm from '@/views/pages/news/NewsForm'
import FaqList from '@/views/pages/faq/FaqList'
import FaqForm from '@/views/pages/faq/FaqForm'
import SuportTehnic from '@/views/pages/suport-tehnic/SuportTehnic'
import AccountSetting from '@/views/pages/account-setting/AccountSetting'
import FaqSmisList from '@/views/pages/faq-smis/FaqSmisList'
import FaqSmisForm from '@/views/pages/faq-smis/FaqSmisForm'
import ClarificationsSmisList from '@/views/pages/clarifications-smis/ClarificationsSmisList'
import ClarificationsSmisForm from '@/views/pages/clarifications-smis/ClarificationsSmisForm'

import HorecaNewsForm from '@/views/pages/horeca/horeca-news-admin/NewsForm'
import HorecaNewsList from '@/views/pages/horeca/horeca-news-admin/NewsList'

import middleware from '@/middleware'

export default [
    {
        path: '/',
        name: 'pages-account-setting',
        component: AccountSetting,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Contul meu',
            breadcrumb: [
                {
                    text: 'Contul meu',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/suport',
        name: 'suport',
        component: SuportTehnic,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Suport tehnic',
            breadcrumb: [
                {
                    text: 'Suport tehnic',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/apps-documente-utile-list',
        name: 'apps-documente-utile-list',
        component: DocumentsList,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Documente utile',
            breadcrumb: [
                {
                    text: 'Documente utile',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps-documente-utile-form',
        name: 'apps-documente-utile-form',
        component: DocumentsForm,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Documente utile',
            breadcrumb: [
                {
                    text: 'Documente utile',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps-stiri-list',
        name: 'apps-stiri-list',
        component: NewsList,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Știri și comunicate MEEMA',
            breadcrumb: [
                {
                    text: 'Știri și comunicate MEEMA',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps-stiri-form',
        name: 'apps-stiri-form',
        component: NewsForm,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Știri și comunicate MEEMA',
            breadcrumb: [
                {
                    text: 'Știri și comunicate MEEMA',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps-faq-list',
        name: 'apps-faq-list',
        component: FaqList,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Întrebări și răspunsuri',
            breadcrumb: [
                {
                    text: 'Întrebări și răspunsuri',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps-faq-form',
        name: 'apps-faq-form',
        component: FaqForm,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Întrebări și răspunsuri',
            breadcrumb: [
                {
                    text: 'Întrebări și răspunsuri',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps-faq-smis-list',
        name: 'apps-faq-smis-list',
        component: FaqSmisList,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Întrebări și răspunsuri Smis',
            breadcrumb: [
                {
                    text: 'Întrebări și răspunsuri Smis',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps-faq-smis-form',
        name: 'apps-faq-smis-form',
        component: FaqSmisForm,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Întrebări și răspunsuri Smis',
            breadcrumb: [
                {
                    text: 'Întrebări și răspunsuri Smis',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps-clarificari-smis-list',
        name: 'apps-clarificari-smis-list',
        component: ClarificationsSmisList,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Clarificari Smis',
            breadcrumb: [
                {
                    text: 'Clarificari Smis',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/apps-clarificari-smis-form',
        name: 'apps-clarificari-smis-form',
        component: ClarificationsSmisForm,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Clarificari Smis',
            breadcrumb: [
                {
                    text: 'Clarificari Smis',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-stiri-list',
        name: 'horeca-stiri-list',
        component: HorecaNewsList,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Știri procedura HORECA',
            breadcrumb: [
                {
                    text: 'Știri procedura HORECA',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/horeca-stiri-form',
        name: 'horeca-stiri-form',
        component: HorecaNewsForm,
        beforeEnter: middleware.auth,
        meta: {
            pageTitle: 'Știri procedura HORECA',
            breadcrumb: [
                {
                    text: 'Știri procedura HORECA',
                    active: true,
                },
            ],
        },
    },
]
