<template>
    <div>
        <div class="row">
            <div class="col-xl-12">
                <b-alert class="mt-0" variant="danger" show v-if="!isM2Manager">
                    <div class="alert-body">
                  <span>
                    ATENȚIE! Nu ai selectat pachetul M2 Manager. Pentru a vedea toate tutorialele video alege pachetul M2 Manager accesând
                    <router-link
                            :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'serviciile-mele' },
                      }"
                    ><strong>Serviciile mele</strong></router-link>.
                  </span>
                    </div>
                </b-alert>
                <b-alert class="mt-0" variant="danger" show v-else-if="!isPaid">
                    <div class="alert-body">
                  <span>
                    Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea <router-link
                          :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'factura-contract' },
                      }"
                  ><strong>Factură si Contract</strong></router-link>. În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager)

                  </span>
                    </div>
                </b-alert>
            </div>
        </div>
        <div>
            <div class="row">
                <div class="col-md-6">
                    <YoutubeEmbed link="rNt9UqfVTLY" sectionTitle="Tutorial Modul raportare cheltuieli si Raport de progres" />
                </div>
                <div class="col-md-6">
                    <YoutubeEmbed link="Tdhuz1b9irA" sectionTitle="Efectuarea plăților" />
                </div>
                <div class="col-md-6">
                    <YoutubeEmbed link="ajwqbtdb1j0" sectionTitle="Facturi multiple de la același furnizor" />
                </div>
                <div class="col-md-6">
                    <YoutubeEmbed link="LPijzDVg1fg" sectionTitle="Cheltuieli & plăți în EURO" />
                </div>
                <div class="col-md-6">
                    <YoutubeEmbed link="g68pX0DqRjc" sectionTitle="Tutorial nedeductibilitate TVA" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { BAlert } from 'bootstrap-vue'
    import store from '@/store'
    import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'

    export default {
        data: () => ({
            isPaid: false,
            isM2Manager: false
        }),
        components: {
            BAlert,
            YoutubeEmbed
        },
        mounted() {
            this.showPayPopupHandler()
            this.isM2ManagerHandler()
        },
        methods: {
            isM2ManagerHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isM2Manager = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.id === 2
                })
            },
            showPayPopupHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isPaid = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.program_id === 1 && key.paid_confirmed !== null
                })
            },
        },
    }
</script>

