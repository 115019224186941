import M2ManagerForm from '@/views/pages/m2-manager/M2ManagerForm'
import M2ManagerIdentitateVizuala from '@/views/pages/m2-manager/M2ManagerIdentitateVizuala'
import Clarifications from '@/views/pages/clarifications/Clarifications'
import FacturaContract from '@/views/pages/factura-contract/FacturaContract'
import CatalogCheltuieli from '@/views/pages/m2-pages/CatalogCheltuieli'
import CalculatorValutar from '@/views/pages/m2-pages/CalculatorValutar'
import ModeleDocumente from '@/views/pages/m2-pages/ModeleDocumente'
import PrezentareGenerala from '@/views/pages/m2-pages/PrezentareGenerala'
import ComunicatFinal from '@/views/pages/m2-pages/ComunicatFinal'
import IntrebariM2 from '@/views/pages/m2-pages/IntrebariM2'
import MonografiaContabila from '@/views/pages/m2-pages/MonografiaContabila'
import GrantNecheltuit from '@/views/pages/m2-pages/GrantNecheltuit'
import ContacteFinantator from '@/views/pages/m2-pages/ContacteFinantator'
import Furnizori from '@/views/pages/m2-pages/Furnizori'

import middleware from '@/middleware'

export default [
    {
        path: '/grantul-meu',
        name: 'm2-manager',
        component: M2ManagerForm,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Grantul meu',
            breadcrumb: [
                {
                    text: 'Grantul meu',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/prezentare-generala',
        name: 'prezentare-generala',
        component: PrezentareGenerala,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'M2 Manager',
            breadcrumb: [
                {
                    text: 'M2 Manager',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/m2-manager/identitate-vizuala',
        name: 'm2-identitate-vizuala',
        component: M2ManagerIdentitateVizuala,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Identitate vizuală',
            breadcrumb: [
                {
                    text: 'Identitate vizuală',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/m2-clarificari',
        name: 'm2-clarificari',
        component: Clarifications,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Formulează clarificări',
            breadcrumb: [
                {
                    text: 'Formulează clarificări',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/catalog-cheltuieli',
        name: 'catalog-cheltuieli',
        component: CatalogCheltuieli,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Catalog Cheltuieli',
            breadcrumb: [
                {
                    text: 'Catalog Cheltuieli',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/contacte-finantator',
        name: 'm2-contacte-finantator',
        component: ContacteFinantator,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Contacte finanțator',
            breadcrumb: [
                {
                    text: 'Contacte finanțator',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/calculator-valutar',
        name: 'calculator-valutar',
        component: CalculatorValutar,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Calculator Valutar',
            breadcrumb: [
                {
                    text: 'Calculator Valutar',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/modele-documente',
        name: 'modele-documente',
        component: ModeleDocumente,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Modele de documente',
            breadcrumb: [
                {
                    text: 'Modele de documente',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/intrebari-m2',
        name: 'intrebari-m2',
        component: IntrebariM2,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Întrebări Măsura 2',
            breadcrumb: [
                {
                    text: 'Întrebări Măsura 2',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/monografia-contabila',
        name: 'monografia-contabila',
        component: MonografiaContabila,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Monografia Contabilă M2',
            breadcrumb: [
                {
                    text: 'Monografia Contabilă M2',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/grant-necheltuit',
        name: 'grant-necheltuit',
        component: GrantNecheltuit,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Grant necheltuit',
            breadcrumb: [
                {
                    text: 'Grant necheltuit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/furnizori',
        name: 'furnizori',
        component: Furnizori,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Furnizori',
            breadcrumb: [
                {
                    text: 'Furnizori',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/comunicat-final',
        name: 'comunicat-final',
        component: ComunicatFinal,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Comunicat de presă final',
            breadcrumb: [
                {
                    text: 'Comunicat de presă final',
                    active: true,
                },
            ],
        },
    },
    // {
    //     path: '/factura-contract',
    //     name: 'factura-contract',
    //     component: FacturaContract,
    //     beforeEnter: middleware.m2customer,
    //     meta: {
    //         pageTitle: 'Factură & contract',
    //         breadcrumb: [
    //             {
    //                 text: 'Factură & contract',
    //                 active: true,
    //             },
    //         ],
    //     },
    // },
]
