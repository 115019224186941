<template>
    <div>
        <div class="card">
            <div class="card-body">
                <h4 class="m-0">
                    Această secțiune vă permite să ne trimiteți mesaje. Vă vom răspunde în cel mai scurt timp.
                </h4>
                <validation-observer ref="loginForm" #default="{ invalid }" v-if="showForm">
                    <b-form class="auth-login-form" @submit.prevent="saveSupport">

                        <div class="column mt-1">
                            <b-form-group class="col ml-0 pl-0" label="Nume si prenume" label-for="fullName">
                                <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                >
                                    <b-form-input
                                            size="lg"
                                            id="fullName"
                                            v-model="data.fullName"
                                            :state="errors.length > 0 ? false : null"
                                            name="fullName"
                                            placeholder="Nume si prenume"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group class="col" label="Email" label-for="email">
                                <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                >
                                    <b-form-input
                                            size="lg"
                                            id="email"
                                            v-model="data.email"
                                            :state="errors.length > 0 ? false : null"
                                            name="email"
                                            placeholder="Email"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group class="col pr-0 mr-0" label="Telefon" label-for="phone">
                                <validation-provider
                                        #default="{ errors }"
                                        rules="required"
                                >
                                    <b-form-input
                                            size="lg"
                                            id="phone"
                                            v-model="data.phone"
                                            :state="errors.length > 0 ? false : null"
                                            name="phone"
                                            placeholder="Telefon"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>

                        <b-form-group label="Mesaj" label-for="phone">
                            <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                            >
                                <b-form-textarea
                                        size="lg"
                                        id="message"
                                        rows="6"
                                        v-model="data.message"
                                        :state="errors.length > 0 ? false : null"
                                        name="phone"
                                        placeholder="Lasă-ne un mesaj"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <div class="inline-flex">
                            <b-button
                                    type="submit"
                                    variant="primary"
                                    :disabled="invalid" >
                                Trimite
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
                <b-badge class="mt-2 badge badge-light-success" v-else>Mesajul a fost trimis! Îți mulțumim!</b-badge>
            </div>
        </div>
    </div>
</template>

<script>
    import axiosIns from '../../../libs/axios'
    import config from '@/config'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import {
        BRow,
        BCol,
        BCard,
        BCardText,
        BButton,
        BImg,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BPagination,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BFormFile,
        BModal,
        VBModal,
        BBadge,
        BFormCheckbox,
        BForm,
    } from 'bootstrap-vue'
    export default {
        name: 'Clarifications',
        components: {
            BRow,
            BCol,
            BCard,
            BCardText,
            BButton,
            BImg,
            BFormInput,
            BFormTextarea,
            BFormGroup,
            BPagination,
            BFormSelect,
            BFormFile,
            BModal,
            BBadge,
            BFormCheckbox,
            BForm,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                data: {
                    fullName: '',
                    email: '',
                    phone: '',
                    message: ''
                },
                showForm: true
            }
        },
        beforeCreate() {
            axiosIns.get(config.API_ENDPOINTS.userData).then(({ data }) => {
                this.data.fullName = data.data.name;
                this.data.email = data.data.email;
                this.data.phone = data.data.phone;
            })
        },
        mounted() {

        },
        created() {

        },
        destroyed() {

        },
        computed: {

        },
        watch: {

        },
        methods: {
            saveSupport(){
                axiosIns.post("/supports", this.data)
                    .then(response => {
                        this.showToast('primary', 'Success', 'Mesajul a fost trimis cu succes!');
                        this.showForm = false;
                    })
                    .catch(e => {
                        this.showToast('primary', 'Error', 'Mesajul nu a fost trimis. Te rugam retrimite mesajul.');
                        this.errors.push(e);
                    })
            },
            showToast(variant, title, msg) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: 'BellIcon',
                        text: msg,
                        variant,
                    },
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .badge{
        font-size: 16px;
        padding: 10px;
    }
</style>
