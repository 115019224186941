var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[(!_vm.isM2Manager)?_c('b-alert',{staticClass:"mt-0",attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(" ATENȚIE! Nu ai selectat pachetul M2 Manager. Pentru a vedea toate tutorialele video alege pachetul M2 Manager accesând "),_c('router-link',{attrs:{"to":{
                    path: '/',
                    name: 'pages-account-setting',
                    params: { to: 'serviciile-mele' },
                  }}},[_c('strong',[_vm._v("Serviciile mele")])]),_vm._v(". ")],1)])]):(!_vm.isPaid)?_c('b-alert',{staticClass:"mt-0",attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(" Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea "),_c('router-link',{attrs:{"to":{
                    path: '/',
                    name: 'pages-account-setting',
                    params: { to: 'factura-contract' },
                  }}},[_c('strong',[_vm._v("Factură si Contract")])]),_vm._v(". În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager) ")],1)])]):_vm._e()],1)]),_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('YoutubeEmbed',{attrs:{"link":"rNt9UqfVTLY","sectionTitle":"Tutorial Modul raportare cheltuieli si Raport de progres"}})],1),_c('div',{staticClass:"col-md-6"},[_c('YoutubeEmbed',{attrs:{"link":"Tdhuz1b9irA","sectionTitle":"Efectuarea plăților"}})],1),_c('div',{staticClass:"col-md-6"},[_c('YoutubeEmbed',{attrs:{"link":"ajwqbtdb1j0","sectionTitle":"Facturi multiple de la același furnizor"}})],1),_c('div',{staticClass:"col-md-6"},[_c('YoutubeEmbed',{attrs:{"link":"LPijzDVg1fg","sectionTitle":"Cheltuieli & plăți în EURO"}})],1),_c('div',{staticClass:"col-md-6"},[_c('YoutubeEmbed',{attrs:{"link":"g68pX0DqRjc","sectionTitle":"Tutorial nedeductibilitate TVA"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }