<template>
    <div>
        <PaginaInContructie></PaginaInContructie>
    </div>
</template>

<script>
    import PaginaInContructie from '@/views/pages/horeca/components/PaginaInConstructie.vue'

    export default {
        data: () => ({

        }),
        components: {
            PaginaInContructie
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>

</style>

