export default {
  namespaced:true,
  state: {
    project_id : null,
    projects : {},
  },
  getters: {
    currentProjectId: state => {
      return state.project_id
    },
    projects: state => {
      return state.projects
    }
  },
  mutations: {
    SET_CURRENT_PROJECT_ID(state,val){
      state.project_id = val
    },
    SET_PROJECTS(state,val){
      state.projects = val
    },
    UPDATE_PROJECT(state,val){
      state.projects[val.id] = val
    },
    DELETE_PROJECT(state, id){
      delete state.projects[id]
      if (state.project_id == id){
        state.project_id = null
      }
      state.projects = JSON.parse(JSON.stringify(state.projects))
    },
  },
  actions: {
    
  },
}