var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"m-0"},[_vm._v(" Această secțiune vă permite să ne trimiteți mesaje. Vă vom răspunde în cel mai scurt timp. ")]),(_vm.showForm)?_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form",on:{"submit":function($event){$event.preventDefault();return _vm.saveSupport($event)}}},[_c('div',{staticClass:"column mt-1"},[_c('b-form-group',{staticClass:"col ml-0 pl-0",attrs:{"label":"Nume si prenume","label-for":"fullName"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"lg","id":"fullName","state":errors.length > 0 ? false : null,"name":"fullName","placeholder":"Nume si prenume"},model:{value:(_vm.data.fullName),callback:function ($$v) {_vm.$set(_vm.data, "fullName", $$v)},expression:"data.fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col",attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"lg","id":"email","state":errors.length > 0 ? false : null,"name":"email","placeholder":"Email"},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col pr-0 mr-0",attrs:{"label":"Telefon","label-for":"phone"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"lg","id":"phone","state":errors.length > 0 ? false : null,"name":"phone","placeholder":"Telefon"},model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-form-group',{attrs:{"label":"Mesaj","label-for":"phone"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"size":"lg","id":"message","rows":"6","state":errors.length > 0 ? false : null,"name":"phone","placeholder":"Lasă-ne un mesaj"},model:{value:(_vm.data.message),callback:function ($$v) {_vm.$set(_vm.data, "message", $$v)},expression:"data.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"inline-flex"},[_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" Trimite ")])],1)],1)]}}],null,false,464681512)}):_c('b-badge',{staticClass:"mt-2 badge badge-light-success"},[_vm._v("Mesajul a fost trimis! Îți mulțumim!")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }