<template>
  <section id="card-text-alignment">
    <b-row>
      <b-col>
        <b-card class="p-0">
          <h4 class="mb-1 mt-0">
            Pentru a putea folosi aplicatia, te rog alege un pachet.
          </h4>
          <div class="inline">
            <p class="m-0">Alegerea oricărui pachet
              software (M2, POCU, HORECA) <strong>nu reprezintă o obligație de plată</strong>.
              Îți vom emite documentele de plată <strong>exclusiv la solicitarea ta</strong>, după testarea aplicațiilor. Pentru orice
              informații suplimentare ne poți contacta la:
                <a href="tel:0722144395">0722.114.395</a> / <a href="mailto:contact@smis.ro">contact@smis.ro</a> sau
                <a href="mailto:office@smis.ro">office@smis.ro</a>
            </p>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card style="background-image: linear-gradient(#0087fa, #5d02c7); text-align:center">
          <div style="color: white; font-size: 17px">
             <span class="font-weight-bolder"> Reprezinți o firma de consultanță? Contactează-ne la 0722.114.395 | </span>  Beneficiezi de costuri pentru M2manager, de
            la 90 lei (fără TVA) / beneficiar (include toate funcțiile și ambele comunicate). Ai acces la toate firmele dintr-un singur cont SMIS
            și un instrument unic prin care urmărești detaliile tuturor beneficiarilor.
          </div>
        </b-card>
      </b-col>
    </b-row>
    <div class="allPrograms">
      <div class="programItem" v-for="program in programs.slice(0, 1)" :key="program.slug">
        <div
          class="packageItem"
          v-for="pkg in program.packages"
          :key="pkg.id"
        >
          <b-card
            class="text-center"
            :class="[
              getPlans.some((plan) => plan.id === pkg.id) ? 'active-plan' : '',
            ]"
          >
            <div class="logoWrapper">
              <b-img
                class="packageImg"
                :src="pkg.icon"
                fluid
                :alt="pkg.name"
              />
            </div>
            <hr>

            <b-card-text class="mb-1 pkgDescription" v-html="pkg.description">
              {{ pkg.description }}
            </b-card-text>

            <p v-if="pkg.price && program.id === 1" class="price mb-0">{{ pkg.price }}<small>lei (fără TVA)</small></p>
            <p v-else-if="!pkg.price" class="price priceFree mt-2 mb-1">100% gratuit</p>
            <h4 class="mt-2 mb-2" v-else>Generează execuția proiectului tău <strong>100% gratuit</strong></h4>

            <p v-if="pkg.price && program.id === 1" class="subPrice m-0 text-center"><strong>cheltuieli eligibile din grant</strong></p>

            <!--<a :href="pkg.read_more_url" target="_blank" class="btn-edit">-->
              <!--<b-button-->
                <!--v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
                <!--variant="outline-primary"-->
                <!--class="m-1"-->
              <!--&gt;-->
                <!--Despre pachet-->
              <!--</b-button>-->
            <!--</a>-->

            <b-button
              @click="choosePackage(pkg.id)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              size="lg"
              class="m-1"
              :disabled="
                getPlans.some(
                  (plan) =>
                    plan.program_id === program.id && plan.tier >= pkg.tier
                )
              "
            >
              {{
                getPlans.some((plan) => plan.id === pkg.id)
                  ? `Pachet ales la ${new Date(
                    getPlans.find((plan) => plan.id === pkg.id).started_at
                    ).toLocaleString('ro-RO')}`
                  : 'Alege pachet'
              }}
            </b-button>

          </b-card>
        </div>
      </div>
      <div class="programItem" v-for="program in programs.slice(1, 3).reverse()" :key="program.slug">
        <div
            :class="pkg.program_id === 3 ? 'horeca' : ''"
            class="packageItem"
            v-for="pkg in program.packages"
            :key="pkg.id"
        >
          <b-card
                  class="text-center"
                  :class="[
              getPlans.some((plan) => plan.id === pkg.id) ? 'active-plan' : '',
            ]"
          >
            <div class="logoWrapper" v-if="pkg.program_id === 3">
              <span class="new">Nou</span>
              <b-img
                      class=" packageImg"
                      :src="require('@/assets/images/horeca/logoHorecaManager.png')"
                      fluid
                      :alt="pkg.name"
              />
            </div>
            <div class="logoWrapper" v-else>
              <b-img
                      class=" packageImg"
                      :src="pkg.icon"
                      fluid
                      :alt="pkg.name"
              />
            </div>

            <hr>

            <b-card-text class="mb-1 pkgDescription" v-html="pkg.description">
              {{ pkg.description }}
            </b-card-text>

            <p v-if="pkg.price && program.id === 1" class="price mb-0">{{ pkg.price }}<small>lei (fără TVA)</small></p>
            <p v-else-if="!pkg.price" class="price priceFree mt-2 mb-1">100% gratuit</p>
            <h4 class="mt-2 mb-2" v-else>Generează execuția proiectului tău <strong>gratuit</strong></h4>

            <p v-if="pkg.price && program.id === 1" class="subPrice m-0 text-center"><strong>cheltuieli eligibile din grant</strong></p>

            <!--<a :href="pkg.read_more_url" target="_blank" class="btn-edit">-->
            <!--<b-button-->
            <!--v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
            <!--variant="outline-primary"-->
            <!--class="m-1"-->
            <!--&gt;-->
            <!--Despre pachet-->
            <!--</b-button>-->
            <!--</a>-->

            <b-button
                    @click="choosePackage(pkg.id)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    size="lg"
                    class="m-1"
                    :disabled="
                getPlans.some(
                  (plan) =>
                    plan.program_id === program.id && plan.tier >= pkg.tier
                )
              "
            >
              {{
                getPlans.some((plan) => plan.id === pkg.id)
                    ? `Pachet ales la ${new Date(
                    getPlans.find((plan) => plan.id === pkg.id).started_at
                    ).toLocaleString('ro-RO')}`
                    : 'Alege pachet'
              }}
            </b-button>

          </b-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BButton, BImg } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import axios from '@axios'
import config from '@/config'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },

  props: ['programs'],

  computed: {
    getPlans() {
      return this.$store.state.auth.user
        ? this.$store.state.auth.user.plans
        : []
    },

    cols() {
      // consider rounding this...
      return (
        12 /
        this.programs.reduce(
          (acc, program) =>
            program.packages.length > acc ? program.packages.length : acc,
          0
        )
      )
    },
  },

  // beforeCreate() {
  //   axios.get(config.API_ENDPOINTS.packages).then(({ data }) => {
  //     this.programs = data.data
  //   })
  // },

  methods: {
    choosePackage(packageId) {
      axios
        .post(config.API_ENDPOINTS.choosePackage, {
          package: packageId,
        })
        .then(async ({ data }) => {
          await this.$store.dispatch('auth/getUserData')
          this.redirect(data.data)
        })
        .catch((e) => {
          console.error(e)
        })
    },

    redirect(redirectPath) {
      this.$router.push(redirectPath)
    },
  },
}
</script>


<style scoped>
.packageImg{
  max-height: 65px;
}
.border-1{
  border-top: 1px solid #ccc;
  width: 100%;
  margin: 0 15px;
}
.pkgDescription{
  font-size: 16px;
  text-align: center;
}
.pkgDescription span{
  color: #01e8c6;
}
.price{
  font-size: 37px;
  font-weight: 900;
  color: #01e8c6;
  line-height: 40px;
  text-align: center;
}
.price small{
  font-size: 16px;
}
.subPrice{
  font-weight: 900;
  color: #01e8c6;
}
.priceFree{
  font-size: 30px;
  line-height: 32px;
}
.programImg{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  margin-right: 15px;
  float: left;
}
.programDescription .card-body{
  display: flex;
  align-items: center;
}
.tab-content .row{
  align-items: center;
}
.active-plan {
  background: #f4fffe;
  border: 1px solid #0cadb3;
}
.inline{
  display: flex;
  align-items:center;
  justify-content: space-between;
}

/*.allPrograms{*/
  /*display: flex;*/
/*}*/
.programItem:first-child {
  width: 63%;
  float: left;
  display: flex;
  margin-right: 2%;
}
.programItem:first-child .packageItem{
  width: 48.5%;
  display: inline-block;
}
.programItem:first-child .packageItem:first-child{
  margin-right: 3%;
}
.programItem:last-child{
  width: 35%;
  float: left;
}
.logoWrapper{
  min-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}
h4{
  font-weight: normal;
}
.logoWrapper{
  position: relative;
}
.logoWrapper .new{
  position: absolute;
  top: -10px;
  left: 0;
  width: 45px;
  height: 16px;
  background: linear-gradient(to right, #ff8a00, #ed3784);
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  border-radius: 5px;
  -webkit-text-fill-color: #fff;
  text-align: center;
}
  .horeca .price,
  .horeca .pkgDescription span {
    color: #f27a5c !important;
  }
  .horeca .btn-primary {
    border-color: #f27a5c !important;
    background-color: #f27a5c !important;
  }
  .horeca .active-plan {
    border-color: #f27a5c !important;
    background-color: #f27a5c10 !important;
  }
  .horeca .btn-primary:hover {
    box-shadow: 0 8px 25px -8px #f27a5c !important;
  }
</style>
