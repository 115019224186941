<template>
  <div>
    <div class="row align-items-center">
      <div class="col-lg-8">
        <h2>{{ packg.name }}</h2>
        <h4 class="pt-2 pb-0" v-if="packg.name !== 'Horeca Manager'">Date identificare:</h4>
        <ul>
          <li
            v-for="(value, key) in plan.identification"
            :key="key"
            v-show="!!value"
          >
            <strong>{{ key }}</strong
            >: {{ value }}
          </li>
        </ul>
        <div v-if="packg.name !== 'M2 Basic' && packg.name !== 'Horeca Manager'" class="mt-2">
          <p>
            Solicită documentele tale, pentru acest proiect, accesând link-urile
            de mai jos:
          </p>
          <b-button
            variant="primary"
            class="mr-3"
            :disabled="loading || plan.invoice_requested !== null"
            @click="sendInvoiceRequest()"
          >
            <span v-if="loading">
              <b-spinner small label="Loading..."></b-spinner> &nbsp; Te rugăm
              să aștepți
            </span>
            <span v-else>{{ buttonText }}</span>
          </b-button>
        </div>
        <div v-if="packg.name === 'M2 Basic' || packg.name === 'Horeca Manager'">
          <p>Acest pachet este 100% GRATUIT</p>
        </div>

        <div class="text-danger pt-2" v-if="error">
          <small>{{ error }}</small>
        </div>
      </div>
      <div class="col-lg-4">
        <b-img
          v-if="packg.name !== 'Horeca Manager'"
          class="mb-2 mt-1"
          fluid
          right
          width="190"
          :src="packg.icon"
          :alt="packg.name"
        />
        <b-img
                v-else
                class="mb-2 mt-1"
                fluid
                right
                width="250"
                :src="require('@/assets/images/horeca/logoHorecaManager.png')"
                :alt="packg.name"
        />
      </div>
    </div>

    <div class="row" v-if="plan.invoice_requested !== null">
      <div class="col-12">
        <h5 class="pt-2">Confirmă plata</h5>
        <div class="mt-2" v-if="uploadedOPs && uploadedOPs.length > 0">
          <p>Fișiere încărcate anterior</p>
          <ul>
            <li v-for="uploadedOP in uploadedOPs" :key="uploadedOP.id">
              <a
                href=""
                download
                @click.prevent="
                  downloadOPFile({
                    url: `/download_op/${uploadedOP.uuid}`,
                    name: uploadedOP.original_filename,
                  })
                "
                >{{ uploadedOP.original_filename }}
              </a>
            </li>
          </ul>
        </div>
        <b-card-text class="p-2 mt-2 border">
          <b-form-group label="Încarcă un nou ordin de plată:">
            <b-form-file
              placeholder="Alege OP pentru încărcat"
              drop-placeholder="Trage aici OP-ul..."
              size="md"
              v-model="OPFile"
            ></b-form-file>
          </b-form-group>
          <b-form-group>
            <b-button variant="primary" class="mr-3" @click="uploadOP()">
              <span v-if="loadingUpload">
                <b-spinner small label="Loading..."></b-spinner> &nbsp; Vă rugăm
                așteptați
              </span>
              <span v-else>Încarcă OP</span>
            </b-button>
          </b-form-group>
        </b-card-text>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import config from '@/config'
import axiosIns from '@axios'
import { BButton, BCardText, BImg, BSpinner, BFormGroup, BFormFile } from 'bootstrap-vue'
export default {
  components: {
    BButton,
    BCardText,
    BImg,
    BSpinner,
    BFormGroup,
    BFormFile,
  },

  data() {
    return {
      loading: false,
      loadingUpload: false,
      error: null,
      OPFile: null,
      uploadedOPs: [],
    }
  },
  props: ['plan', 'packg'],

  computed: {
    buttonText() {
      return this.plan.invoice_sent
        ? `Contract trimis la ${this.plan.invoice_requested}`
        : this.plan.invoice_requested
        ? `Solicitat la ${this.plan.invoice_requested}`
        : `Solicită contract și factura`
    },
  },

  mounted(){
      console.log('this.plan', this.plan.invoice_requested);

      if(this.plan.invoice_requested)
        this.loadOPs()
  },

  methods: {
    sendInvoiceRequest() {
      this.loading = true
      axiosIns
        .post(config.API_ENDPOINTS.requestInvoice, {
          package: this.packg.id,
        })
        .then(({ data }) => {
          console.log('data', data.invoice_requested);
          this.plan.invoice_requested = data.invoice_requested
          store.dispatch('auth/getUserData')
          this.error = null
        })
        .catch((err) => {
          this.error = err.response.data.message
        })
        .finally(() => {
          this.loading = false
        })
    },

    uploadOP() {
      if (this.OPFile) {
        this.loadingUpload = true
        const formData = new FormData()
        formData.append('file', this.OPFile)
        formData.append('package', this.plan.id)

        axiosIns
          .post(config.API_ENDPOINTS.uploadOP, formData)
          .then(() => {
            this.loadOPs()
            this.OPFile = null
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.loadingUpload = false
          })
      }
    },

    downloadOPFile({ url, name }) {
      axiosIns
        .get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = name
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },

    loadOPs() {
      axiosIns
        .get(`${config.API_ENDPOINTS.getUploadedOPs}/${this.packg.id}`)
        .then(({ data }) => {
          this.uploadedOPs = data.data
        })
        .catch((err) => console.log(err))
    },
  },
}
</script>
