<template>
    <div class="card">
        <div class="card-body">
            <form class="mt-2">
                <div class="row">
                    <b-col sm="6">
                        <b-form-group label="Titlu" label-for="titlu">
                            <b-form-textarea
                                    v-model="data.title"
                                    name="title"
                                    placeholder="Titlu"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Descriere" label-for="Descriere">
                            <b-form-textarea
                                    v-model="data.description"
                                    name="description"
                                    placeholder="Descriere"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Selecteaza imagine" label-for="Selecteaza imagine">
                        <b-form-file
                                type="file"
                                :placeholder="this.data.picture ? this.data.picture  : 'Selecteaza imagine'"
                                id="file" ref="file"
                                v-on:change="handleFileUpload($event)"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <h5>Selecteaza data</h5>
                            <b-form-datepicker
                                placeholder="Data"
                                :start-weekday="1"
                                :date-format-options="{
                                    year: 'numeric',
                                    day: 'numeric',
                                    month: 'numeric',
                                }"
                                v-model="data.date"
                                local="ro-RO"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col sm="6">
                        <b-form-group label="URL" label-for="URL">
                            <b-form-input
                                    v-model="data.url"
                                    name="url"
                                    placeholder="URL"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-button
                                variant="primary"
                                @click="save"
                                class="mt-2 mr-1"
                        >
                            Salvare
                        </b-button>
                        <b-button
                                variant="outline-secondary"
                                class="mt-2"
                                @click="$router.push(`/apps-stiri-list`)"
                        >
                            Inapoi
                        </b-button>
                    </b-col>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import axiosIns from '@axios'
  import {
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BFormTextarea,
    BFormDatepicker
  } from 'bootstrap-vue'
  export default {
    name: 'NewsForm',
    components: {
      BButton,
      BForm,
      BImg,
      BFormFile,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BAlert,
      BCard,
      BCardText,
      BMedia,
      BMediaAside,
      BMediaBody,
      BLink,
      BFormTextarea,
      BFormDatepicker
    },
    data() {
      return {
        loading: {
          data: false,
          save: false,
        },
        data: {
          id: null,
          title: null,
          description: null,
          picture: null,
          date: null,
          url: null
        },
      }
    },
    methods: {
      handleFileUpload(event){
        let formData = new FormData();
        formData.append('file', event.target.files[0]);
        axiosIns.post( '/horeca/news-upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ) .then(response => {
          this.data.picture = response.data.name;
        })
          .catch(e => {
            this.errors.push(e)
          })
      },
      save(){
        axiosIns.post('/horeca/news-save', this.data)
          .then(response => {
            this.$router.push({
              name: 'horeca-stiri-list'
            });
          })
          .catch(error => {
            this.$router.push({
              name: 'horeca-stiri-list'
            });
          })
      }
    },
    created() {
      if (this.$route.params.id) {
        this.loading.data = true
        this.data.id = this.$route.params.id
        axiosIns.post('/horeca/news-edit', { id: this.data.id })
          .then(response => {
            this.data = response.data.data
          })
          .catch(e => {
            this.errors.push(e)
          })
      } else {
        this.loading.data = false
      }
    }
  }
</script>
