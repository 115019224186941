import TutorialeVideo from '@/views/pages/m2-pages/TutorialeVideo'
import ModulImplementare from '@/views/pages/m2-pages/ModulImplementare'
import LiveChat from '@/views/pages/m2-pages/LiveChat'
import ConsultantDedicat from '@/views/pages/m2-pages/ConsultantDedicat'
import PolitaGarantare from '@/views/pages/m2-pages/PolitaGarantare'
import NeplatitorTva from '@/views/pages/m2-pages/NeplatitorTva'
import FacilitatiM2Premium from '@/views/pages/m2-pages/FacilitatiM2Premium'
import FacilitatiM2Standard from '@/views/pages/m2-pages/FacilitatiM2Standard'
import middleware from '@/middleware'

export default [
    {
        path: '/tutoriale-video',
        name: 'tutoriale-video',
        component: TutorialeVideo,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Tutoriale video',
            breadcrumb: [
                {
                    text: 'Tutoriale video',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/modul-implementare',
        name: 'modul-implementare',
        component: ModulImplementare,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Modul implementare',
            breadcrumb: [
                {
                    text: 'Modul implementare',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/live-chat',
        name: 'live-chat',
        component: LiveChat,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Live Chat SMIS.ro',
            breadcrumb: [
                {
                    text: 'Live Chat SMIS.ro',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/consultant-dedicat',
        name: 'consultant-dedicat',
        component: ConsultantDedicat,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Consultant dedicat',
            breadcrumb: [
                {
                    text: 'Consultant dedicat',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/polita-garantare',
        name: 'polita-garantare',
        component: PolitaGarantare,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Poliță de garantare',
            breadcrumb: [
                {
                    text: 'Poliță de garantare',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/neplatitor-tva',
        name: 'neplatitor-tva',
        component: NeplatitorTva,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Neplătitor TVA',
            breadcrumb: [
                {
                    text: 'Neplătitor TVA',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/facilitati-m2-standard',
        name: 'facilitati-m2-standard',
        component: FacilitatiM2Standard,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Facilități M2 Standard',
            breadcrumb: [
                {
                    text: 'Facilități M2 Standard',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/facilitati-m2-premium',
        name: 'facilitati-m2-premium',
        component: FacilitatiM2Premium,
        beforeEnter: middleware.m2customer,
        meta: {
            pageTitle: 'Facilități M2 Premium',
            breadcrumb: [
                {
                    text: 'Facilități M2 Premium',
                    active: true,
                },
            ],
        },
    },
]
