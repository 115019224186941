<template>
    <div class="card" :class="{'loading-big' : loading.data}">
        <div class="card-body">
            <form class="mt-2">
                <div class="row">
                    <b-col sm="6">
                        <b-form-group label="Intrebare" label-for="intrebare">
                            <b-form-textarea
                                    v-model="data.question"
                                    name="question"
                                    placeholder="Intrebare"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Raspuns" label-for="raspuns">
                            <b-form-textarea
                                    v-model="data.answer"
                                    name="answer"
                                    placeholder="Raspuns"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-button
                                variant="primary"
                                @click="save"
                                class="mt-2 mr-1"
                        >
                            Salvare
                        </b-button>
                        <b-button
                                variant="outline-secondary"
                                class="mt-2"
                                @click="$router.push(`/apps-faq-smis-list`)"
                        >
                            Inapoi
                        </b-button>
                    </b-col>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
  import axiosIns from '../../../libs/axios.js'
  import {
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BFormTextarea
  } from 'bootstrap-vue'
  export default {
    name: 'FaqSmisForm',
    components: {
      BButton,
      BForm,
      BImg,
      BFormFile,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BAlert,
      BCard,
      BCardText,
      BMedia,
      BMediaAside,
      BMediaBody,
      BLink,
      BFormTextarea
    },
    data() {
      return {
        loading: {
          data: false,
          save: false,
        },
        data: {
          id: null,
          question: null,
          answer: null
        }
      }
    },
    methods: {
      save(){
        axiosIns.post('/faq-smis-save', this.data)
          .then(response => {
            this.$router.push({
              name: 'apps-faq-smis-list'
            });
          })
          .catch(error => {
            this.$router.push({
              name: 'apps-faq-smis-list'
            });
          })
      }
    },
    created() {
      if (this.$route.params.id) {
        this.loading.data = true
        this.data.id = this.$route.params.id
        axiosIns.post('/faq-smis-edit', { id: this.data.id })
          .then(response => {
            this.data = response.data.data
          })
          .catch(e => {
            this.errors.push(e)
          })
      } else {
        this.loading.data = false
      }
    }
  }
</script>
