<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-alert class="mt-0 mb-0" variant="danger" show v-if="!isM2Manager">
          <div class="alert-body">
                  <span>
                    ATENȚIE! Nu ai selectat pachetul M2 Manager. Alege pachetul accesând
                    <router-link
                            :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'serviciile-mele' },
                      }"
                    ><strong>Serviciile mele</strong></router-link>.
                  </span>
          </div>
        </b-alert>
        <b-alert class="mt-0" variant="danger" show v-else-if="!isPaid">
          <div class="alert-body">
                  <span>
                    Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea <router-link
                          :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'factura-contract' },
                      }"
                  ><strong>Factură si Contract</strong></router-link>. În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager)

                  </span>
          </div>
        </b-alert>
        <div v-else class="card">
          <div class="card-body space-between">
            <div>
              <h3 class="mb-0">
                Salut! Aici regăsești modele de documente utile în implementare
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <descarcare-document v-if="isM2Manager" :documents="documents" />
    </div>
    <YoutubeEmbed link="PWEQzWZA6xc" />
  </div>
</template>

<script>
import { BImg, BAlert } from 'bootstrap-vue'
import DescarcareDocument from './components/DescarcareDocument'
import store from '@/store'
import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'


export default {
  data: () => ({
    isPaid: false,
    isM2Manager: false,
    documents: [
      {
          title: 'Model M2 - Notă de justificare plată unică',
          description:
              'Nota de justificare plată unică se utilizeaza atunci când se achită mai multe facturi către un singur furnizor cu un ordin de plată unic. Acest document precede OP-ul la momentul încărcării documentului în platforma granturi.imm.gov.ro',
          imgUrl: 'word-icon',
          action: 'Descarcă model',
          dimension: 'col-xl-6',
          link: '/m2/M2-Nota-de-justificare-plata-unica.docx',
      },
      {
          title: 'Model M2 - Notă de comandă',
          description:
              'Nota de comandă este documentul prin care se execută comanda fermă către furnizor în baza contractului încheiat. Nota de comandă respectă elementele de identitate vizuală.',
          imgUrl: 'word-icon',
          action: 'Descarcă model',
          dimension: 'col-xl-6',
          link: '/m2/M2-Nota-de-comandă.docx',
      },
      {
        title: 'Model M2 - Contract servicii - furnizare',
        description:
          'Prin contractul de prestări servicii întelegem acel contract care se realizează între beneficiarul de Măsura 2 și prestatorul, cel care se obligă să presteze anumite servicii pentru beneficiar, în schimbul unei remunerații. Contractul prevede clauze precum Legea 94/1992, Regulamentul (ue) nr. 1303/2013 al parlamentului european și al consiliului 1303/2013, Legea 78/2000 pentru prevenirea, descoperirea şi sancţionarea faptelor de corupţie, Ordonanţei de Urgenţă a Guvernului nr. 77 / 2014 și altele.',
        imgUrl: 'word-icon',
        action: 'Descarcă model',
        dimension: 'col-xl-6',
        link: '/m2/M2-Contract-servicii-furnizare.docx',
      },
      {
        title: 'Model M2 - Notă justificativă procedură',
        description:
          'Pentru Măsura 2 nu se aplică nici L98/2016 și nici ordinul MFE 1284 în ceea ce privește achizițiile. În cazul achizițiilor de echipamente sau a achizițiilor ce depășesc 30.000 euro recomandăm întocmirea  unei "Note justificative privind necesitatea achiziției și alegere a procedurii - cumpărare directă - în conformitate cu procedura M2, și încadrarea corectă a cheltuielilor conform listei de cheltuieli eligibile și legătura dintre clasa CAEN și codul CPV al achiziției. ',
        imgUrl: 'word-icon',
        action: 'Descarcă model',
        dimension: 'col-xl-6',
        link: '/m2/M2-Nota-justificativă-procedură.docx',
      },
      {
          title: 'Model M2 - Instrument suport plăți în valută',
          description:
              'Intrumentul suport pentru platile in valuta permite calculul simplu si gestionarea platilor multiple catre acelasi furnizor, in valuta straina. Instrumentul genereaza automat si o notă de justificare plată unică in EURO. Nota de justificare plată unică se utilizeaza atunci când se achită mai multe facturi către un singur furnizor cu un ordin de plată unic. Acest document precede OP-ul la momentul încărcării documentului în platforma granturi.imm.gov.ro',
          imgUrl: 'excel-icon',
          action: 'Descarcă model',
          dimension: 'col-xl-6',
          link: '/m2/INSTRUMENT-SUPORT-PLATI-IN-VALUTA.xlsx',
      },
      {
        title: 'Model M2 - NIR - Notă intrare recepție',
        description:
          'Pentru Măsura 2 nu se aplică nici L98/2016 și nici ordinul MFE 1284 în ceea ce privește achizițiile. În cazul achizițiilor de echipamente sau a achizițiilor ce depășesc 30.000 euro recomandăm întocmirea unei: “Note de fundamentare cost privind determinarea valorii estimate” conținând informații și însoțită de dovezi privind cercetarea ofertelor din piață (oferte de preț solicitate, cataloage de produse etc.). ',
        imgUrl: 'excel-icon',
        action: 'Descarcă model',
        dimension: 'col-xl-6',
        link: '/m2/M2-model-NIR.xlsx',
      },
      {
        title: 'Model M2 - Notă de fundamentare cost',
        description:
          'NIR-ul este abrevierea de la Nota de Intrare și Recepție și este un document justificativ utilizat pentru recepția bunurilor achiziționate (mărfuri) și care atestă încărcarea în gestiunea entității. În momentul în care mărfurile de la furnizor sosesc în gestiunea entității în baza facturilor sau a avizului de însoțire a mărfii, atunci trebuie create NIR-uri pentru fiecare categorie de produse achiziționate. ',
        imgUrl: 'word-icon',
        action: 'Descarcă model',
        dimension: 'col-xl-6',
        link: '/m2/M2-Nota-de-fundamentare-cost.docx',
      },
      {
        title: 'Model M2 - Solicitare clarificări MEAT & AIMMAIPE',
        description:
          'În cazul în care doriți să transmiteți solicitări de clarificări către finanțator, puteți face acest lucru din contul dumneavoastră - secțiunea FORMULARE CLARIFICĂRI sau puteți edita documentul model atașat. Este indicată salvarea ca PDF și transmiterea documentului scanat / semnat digital către Ministerul Economiei, Antreprenoriatului și Turismului & AIMMAIPE.',
        imgUrl: 'word-icon',
        action: 'Descarcă model',
        dimension: 'col-xl-6',
        link: '/m2/M2-Solicitare-clarificari-MEAT&APIMM.docx',
      },
      {
        title: 'Model M2 - Caiet de sarcini / documentație achiziție',
        description:
          'Pentru Măsura 2 nu se aplică nici L98/2016 și nici ordinul MFE 1284 în ceea ce privește achizițiile. În cazul achizițiilor de echipamente sau a achizițiilor ce depășesc 30.000 euro recomandăm întocmirea unui caiet de sarcini / documentație achiziție care să stabilească parametrii tehnici și financiari ai achiziției. Recomandăm postarea acestuia pe website-ul propriu. ',
        imgUrl: 'word-icon',
        action: 'Descarcă model',
        dimension: 'col-xl-6',
        link: '/m2/M2-Caiet-sarcini-Documentantie.docx',
      },
      {
          title: 'Model M2 - Proces verbal predare - primire',
          description:
              'Se încheie în următoarele cazuri: Proces verbal de predare-primire servicii sau bunuri Proces verbal de recepție și proces verbal de punerea în funcțiune Modelul de document respectă elementele de identitate vizuală.',
          imgUrl: 'word-icon',
          action: 'Descarcă model',
          dimension: 'col-xl-6',
          link: '/m2/M2-Proces-verbal-PP.docx',
      },
    ],
  }),
  components: {
    BImg,
    DescarcareDocument,
    BAlert,
    YoutubeEmbed
  },
    mounted() {
        this.showPayPopupHandler()
        this.isM2ManagerHandler()
    },
    methods: {
        isM2ManagerHandler() {
            let allPlans = store.getters['auth/user'].plans
            this.isM2Manager = !!allPlans.find(function (key) {
                // program_id === 1 is M2 Manager Program
                // program_id === 2 is POCU Manager Program
                return key.id === 2
            })
        },
        showPayPopupHandler() {
            let allPlans = store.getters['auth/user'].plans
            this.isPaid = !!allPlans.find(function (key) {
                // program_id === 1 is M2 Manager Program
                // program_id === 2 is POCU Manager Program
                return key.program_id === 1 && key.paid_confirmed !== null
            })
        },
    },
}
</script>
