<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-alert class="mt-0" variant="danger" show v-if="!isM2Manager">
          <div class="alert-body">
                  <span>
                    ATENȚIE! Nu ai selectat pachetul M2 Manager. Alege pachetul accesând
                    <router-link
                            :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'serviciile-mele' },
                      }"
                    ><strong>Serviciile mele</strong></router-link>.
                  </span>
          </div>
        </b-alert>
        <b-alert class="mt-0" variant="danger" show v-else-if="!isPaid">
          <div class="alert-body">
                  <span>
                    Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea <router-link
                          :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'factura-contract' },
                      }"
                  ><strong>Factură si Contract</strong></router-link>. În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager)

                  </span>
          </div>
        </b-alert>
        <div v-else class="card">
          <div class="card-body space-between">
            <div>
              <h3 class="mb-0">Suport nedeductibilitate TVA</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isM2Manager">
      <descarcare-document :documents="documents" />
    </div>
    <YoutubeEmbed link="gHVt2XzVSYU" />
  </div>
</template>

<script>
import { BImg, BAlert } from 'bootstrap-vue'
import DescarcareDocument from './components/DescarcareDocument.vue'
import store from '@/store'
import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'

export default {
  data: () => ({
    isPaid: false,
    isM2Manager: false,
    documents: [
      {
        title: 'Baza legală',
        description:
          'In conformitate cu Procedura de implementare a măsurii M2 – 4.3.1 (1) lit H, precum si a Corrigendum aferent Actiunii POC 3.1.1. ordin 1475/03.12.2020, precum si în conformitate cu prevederile Legii nr. 227/2015 privind Codul fiscal si conform ORDIN Nr. 698/1425/2016 din 11 mai 2016 pentru aprobarea Instrucţiunilor de aplicare a prevederilor art. 12 din Hotarârea Guvernului nr. 399/2015 privind regulile de eligibilitate a cheltuielilor efectuate în cadrul operaţiunilor finanţate prin Fondul european de dezvoltare regională, Fondul social european şi Fondul de coeziune 2014 - 2020 - publicat in data de 28.09.2016 procedura de nedeductibilitate TVA se realizeaza prin completarea “Anexei 7 (2.4) Eligibilitate TVA” – DECLARAŢIE privind eligibilitatea TVA aferente cheltuielilor ce vor fi efectuate în cadrul operatiunii propuse spre finanţare din FEDR, FSE  & Declaraţie privind nedeductibilitatea TVA aferente cheltuielilor efectuate în cadrul operatiunii finantate din FEDR, FSE si FC 2014 – 2020 – ANEXA 2 – cu depunere la ANAF conform ORDIN Nr. 698/1425/2016 din 11 mai 2016',
        imgUrl: 'pdf-icon',
        action: 'Descarcă răspuns oficial MEAT',
        dimension: 'col-xl-12',
        link: '/m2/neplatitor-tva/Raspuns-MEAT-TVA.pdf',
      },
      {
        title: 'Model M2 - Anexa 7 (2.4)',
        description:
          'în conformitate cu prevederile Legii nr. 571/2003 privind Codul fiscal, cu modificările şi completările ulterioare (Codul fiscal), declaratie de încadrare în categoriile de persoane juridice din punctul de vedere al regimului de TVA aplicabil, respectiv persoană neînregistrată în scopuri de TVA, conform art. 153 din Codul fiscal;',
        imgUrl: 'word-icon',
        action: 'Descarcă model',
        dimension: 'col-xl-6',
        link: '/m2/neplatitor-tva/Anexa7-TVA.docx',
      },
      {
        title: 'Model M2 - Anexa 2 ANAF smart PDF',
        description:
          'Declaraţie privind nedeductibilitatea TVA aferente cheltuielilor efectuate în cadrul operaţiunii finanţate din FEDR, FSE şi FC 2014 - 2020 conform ORDIN Nr. 698/1425/2016 din 11 mai 2016 pentru aprobarea Instrucţiunilor de aplicare a prevederilor art. 12 din Hotarârea Guvernului nr. 399/2015 privind regulile de eligibilitate a cheltuielilor efectuate în cadrul operaţiunilor finanţate prin FEDR, FSE etc',
        imgUrl: 'pdf-icon',
        action: 'Descarcă model',
        dimension: 'col-xl-6',
        link: '/m2/neplatitor-tva/model-Declaratie-TVA-ANAF.pdf',
      },
      {
        title: 'Model M2 - Program asistenţă XLS',
        description:
          'Declaraţie privind nedeductibilitatea TVA aferente cheltuielilor efectuate în cadrul operaţiunii finanţate din FEDR, FSE şi FC 2014 - 2020 conform ORDIN Nr. 698/1425/2016 din 11 mai 2016 pentru aprobarea Instrucţiunilor de aplicare a prevederilor art. 12 din Hotarârea Guvernului nr. 399/2015 privind regulile de eligibilitate a cheltuielilor efectuate în cadrul operaţiunilor finanţate prin FEDR, FSE etc',
        imgUrl: 'excel-icon',
        action: 'Descarcă model',
        dimension: 'col-xl-6',
        link: '/m2/neplatitor-tva/Model-TVA.xls',
      },
    ],
  }),
  components: {
    BImg,
    DescarcareDocument,
    BAlert,
    YoutubeEmbed
  },
  mounted() {
      this.showPayPopupHandler()
      this.isM2ManagerHandler()
  },
  methods: {
      isM2ManagerHandler() {
          let allPlans = store.getters['auth/user'].plans
          this.isM2Manager = !!allPlans.find(function (key) {
              // program_id === 1 is M2 Manager Program
              // program_id === 2 is POCU Manager Program
              return key.id === 2
          })
      },
      showPayPopupHandler() {
          let allPlans = store.getters['auth/user'].plans
          this.isPaid = !!allPlans.find(function (key) {
              // program_id === 1 is M2 Manager Program
              // program_id === 2 is POCU Manager Program
              return key.program_id === 1 && key.paid_confirmed !== null
          })
      },
  },
}
</script>

