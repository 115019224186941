import store from '@/store'

export default (to, from, next) => {
  if (
    store.getters['auth/authenticated'] &&
    store.getters['auth/user'].plans.some(
      plan => plan.id === 1 || plan.id === 2
    )
  ) {
    next()
  } else {
    next({ name: 'pages-account-setting' })
  }
}
