import Vue from 'vue'
import axios from 'axios'

import config from '@/config'

const axiosIns = axios.create({
  baseURL: config.API_ROOT,
  withCredentials: true,
})

axiosIns.interceptors.request.use(request => {
  request.headers.common['Accept'] = 'application/json'
  request.headers.common['Content-Type'] = 'application/json'

  return request
})

axiosIns.interceptors.response.use(
  response => {
    return response
  },

  error => {
    if (error.response.status === 401 || error.response.status === 419) {
      localStorage.removeItem('user')
      window.location.reload()
    }

    return Promise.reject(error)
  }
)

Vue.prototype.$http = axiosIns

export default axiosIns
