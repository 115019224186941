<template>
    <div>
        <div class="row" v-if="!loading">
            <div class="col-xl-12">
                <b-alert class="mt-0" variant="danger" show v-if="!isM2Manager">
                    <div class="alert-body">
                      <span>
                        ATENȚIE! Nu ai selectat pachetul M2 Manager. Pentru a vedea această pagină alege pachetul M2 Manager accesând
                        <router-link
                                :to="{
                            path: '/',
                            name: 'pages-account-setting',
                            params: { to: 'serviciile-mele' },
                          }"
                        ><strong>Serviciile mele</strong></router-link>.
                      </span>
                    </div>
                </b-alert>
                <b-alert class="mt-0" variant="danger" show v-else-if="!isPaid">
                    <div class="alert-body">
                      <span>
                        Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea <router-link
                              :to="{
                            path: '/',
                            name: 'pages-account-setting',
                            params: { to: 'factura-contract' },
                          }"
                      ><strong>Factură si Contract</strong></router-link>. În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager)

                      </span>
                    </div>
                </b-alert>
            </div>
        </div>
        <div v-if="isM2Manager && !loading" class="row intrebariM2">
            <!--<div class="col-md-12">-->
                <!--<div class="card">-->
                    <!--<div class="card-body">-->
                        <!--<h2 class="m-0">-->
                            <!--Întrebări Măsura 2-->
                        <!--</h2>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div class="col-md-12">-->
                <!--<div style="padding-left: 10px;">-->
                    <!--<h4 class="m-0">-->
                        <!--<b>Esti client </b><strong class="mt-2"-->
                                                   <!--style="color: #01e8c6;border-radius: 5px;">M2Manager</strong>-->
                    <!--</h4>-->
                    <!--<p>Iti multumim! Ai acces la raspunsuri intrebari frectente MEAT si raspunsuri exclusive SMIS.ro!</p>-->
                <!--</div>-->
            <!--</div>-->
            <div class="col-md-7">
                <div class="card" style="background: transparent;">
                    <div class="card-body" style="border: 2px solid #01e8c6; border-radius: 0.428rem;">
                        <div class="row" style="align-items: center;">
                            <div class="col-md-8">
                                <h4 class="card-title mb-0">Întrebări și răspunsuri exclusive SMIS.ro</h4>
                                <p class="mb-0" style="font-size: 12px">Raspunsurile la majoritatea intrebarilor de mai jos sunt fundamentate prin
                                adrese MEAT oferite prin solicitari de clarificari.</p>
                            </div>
                            <div class="col-md-4">
                                <b-form-input
                                        name="query_smis"
                                        placeholder="Caută"
                                        v-model="filters.query_smis"
                                        debounce="500"
                                />
                            </div>

                        </div>
                        <div class="fixedHeightScroll mt-0" v-if="faq_smis && faq_smis.length">
                            <app-collapse
                                    id="faq-smis"
                                    accordion
                                    type="margin"
                                    class="mt-2"
                            >
                                <app-collapse-item
                                        v-for="(data, index) in faq_smis"
                                        :key="index"
                                        :title="data.question"
                                >
                                    {{ data.answer }}
                                </app-collapse-item>

                            </app-collapse>
                        </div>
                        <div class="mt-2" v-else>
                            Momentan nu sunt disponibile întrebări și răspunsuri.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card" style="background: transparent;">
                    <div class="card-body">

                        <div class="row" style="align-items: center;">
                            <div class="col-md-8">
                                <h4 class="card-title mb-0">Întrebări și răspunsuri oficiale MEAT</h4>
                            </div>
                            <div class="col-md-4">
                                <b-form-input
                                        name="query"
                                        placeholder="Caută"
                                        v-model="filters.query"
                                        debounce="500"
                                />
                            </div>
                        </div>

                        <div class="fixedHeightScroll mt-2" v-if="faq && faq.length">
                            <app-collapse
                                    id="faq-payment-qna"
                                    accordion
                                    type="margin"
                                    class="mt-2"
                            >
                                <app-collapse-item
                                        v-for="(data, index) in faq"
                                        :key="index"
                                        :title="data.question"
                                >
                                    {{ data.answer }}
                                </app-collapse-item>

                            </app-collapse>
                        </div>
                        <div class="mt-2" v-else>
                            Momentan nu sunt disponibile întrebări și răspunsuri.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body" style="background-color: #01e8c6; color: #FFFFFF; border-radius: 0.428rem;">
                        <feather-icon
                                :style="'color:#FFFFFF'"
                                icon="SendIcon"
                                class="mr-50"
                                size="28"
                        /> <strong>Nu găsești răspuns la întrebarea ta?</strong> Formulează o solicitare de clarificări către Ministerul Economiei, Antreprenoriatului și Turismului & AIMMAIPE și o trimiți cu un singur click din platformă din secțiunea <router-link to="/m2-clarificari" style="color: #2c2c2c"><strong>Formulare clarificări</strong></router-link>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div style="padding-left: 10px;font-weight: 600;">
                    <p><strong>Descarcă răspunsuri la solicitări de clarificări oficiale de la Ministerul Economiei, Antreprenoriatului și Turismului & AIMMAIPE</strong></p>
                </div>
            </div>
            <template v-for="clarification in clarifications_smis">
                <div class="col-md-3" style="cursor: pointer">
                    <div class="card" @click="downloadDoc(clarification)">
                        <div class="card-body pt-1 pb-1 downloadBtn">
                            <b-img
                                    class="pdf-download mr-2"
                                    style="filter: brightness(0) invert(1);"
                                    :src="require('@/assets/images/m2/pdf-download.png')"
                            />
                            <span style="font-size: 16px; position: relative; top: 2px;" class="mt-1"><strong>Descarcă </strong>{{ clarification.doc_answer }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="card">
                        <div class="card-body" style="font-size: 16px; background-color:#fff; border-radius: 0.428rem; min-height:80px; display: flex; align-items: center;">
                            <strong style="color: black; margin-right: 5px;">Speță: </strong>{{ clarification.question }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <YoutubeEmbed v-if="!loading" link="9yHR3KO0EGc" />
    </div>
</template>

<script>
    import {
        BAlert, BImg, BFormInput
    } from 'bootstrap-vue'
    import store from '@/store'
    import axiosIns from '../../../libs/axios'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'
    export default {
        components: {
            BAlert,
            BImg,
            AppCollapse,
            AppCollapseItem,
            BFormInput,
            YoutubeEmbed
        },
        data() {
            return {
                isPaid: false,
                isM2Manager: false,
                faq: null,
                loading: false,
                faq_smis: [],
                clarifications_smis: [],
                filters: {
                  query: null,
                  query_smis: null
                }
            }
        },
        mounted() {
            this.showPayPopupHandler()
            this.isM2ManagerHandler()
        },
        methods: {
            isM2ManagerHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isM2Manager = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.id === 2
                })
            },
            showPayPopupHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isPaid = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.program_id === 1 && key.paid_confirmed !== null
                })
            },
            getData() {
                axiosIns.get("/dashboard", {params: this.filters})
                    .then(response => {
                        this.faq = response.data.faq;
                        this.faq_smis = response.data.faq_smis.sort((a, b) => b.id - a.id);
                        this.clarifications_smis = response.data.clarifications_smis;
                        this.loading = false;
                    })
                    .catch(e => {
                        //this.errors.push(e);
                        this.loading = true;
                    })
            },
            downloadDoc(item) {
            axiosIns
              .post('/clarification-smis-download', item, { responseType: 'blob' })
              .then((response) => {
                let blob = new Blob([response.data])
                let a = document.createElement('a')
                a.style = 'display: none'
                document.body.appendChild(a)
                let url = window.URL.createObjectURL(blob)
                a.href = url
                a.download = item.doc_answer
                a.click()
                window.URL.revokeObjectURL(url)
              })
              .catch((error) => {})
          },
        },
        created() {
            this.loading = true;
            this.getData();
        },
        watch:{
            'filters.query_smis'(newVal){
              this.getData();
            },
            'filters.query'(newVal){
              this.getData();
            }
        }
    }
</script>
<style>
    .intrebariM2 .downloadBtn{
        transition: .3s;
        background-color: #eb9d99;
        color: #fff;
        border-radius: 0.428rem;
    }
    .intrebariM2 .downloadBtn:hover{
        background-color: #c5534d !important;
    }
    [class*=collapse-] .collapse-title {
      font-weight: 500;
      font-size: 1rem;
    }
    [class*=collapse-] .card .card-body {
      font-size: 0.9rem;
    }
</style>
