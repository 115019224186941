<template>
	<div>
		<div class="row">
			<div class="col-xl-12">
				<div class="card">
					<div class="card-body">
						<div class="w-100 d-flex justify-content-between">
							<HorecaPageTitle></HorecaPageTitle>
							<div style="align-self: center">
								<p class="m-0 align-right text-eligibilitate">eligibilitate</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-xl-12">
				<div class="card">
					<div class="card-body">
						<div>
							<h5 class="m-0 horeca-title">
								Tipuri de activităţi eligibile (CAEN Rev 2):
							</h5>
							<div class="coduriCaenList mt-2">
								<h5
									class="tipuri-activitati"
									v-for="(tip, index) in tipuriActivitati"
									:key="index"
								>
									{{ tip.name }}
									<span v-if="tip.tooltip" class="tooltiptext">{{ tip.tooltip }}</span>
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xl-4">
				<div class="card tab-height">
					<div class="card-body">
						<h5 class="card-title box-title"><span class="text-title">CAEN</span> - Eligibile</h5>
						<div>
							<p class="mb-0 caen-eligibilitatea-sectie">
								Agenţii de turism, care desfăşoară activităţi conform codurilor CAEN:
							</p>
							<p class="mb-0">7911 - Activitati ale agentiilor turistice</p>
							<p class="mb-0">7912 - Activitati ale tur-operatorilor</p>
							<p>7990 - Alte servicii de rezervare si asistenta turistica</p>
						</div>
						<div>
							<p class="mb-0 caen-eligibilitatea-sectie">
								Structuri de primire turistice cu funcţiuni de cazare, care desfăşoară
								activităţi conform codurilor CAEN:
							</p>
							<p class="mb-0">5510 - Hoteluri si alte facilitati de cazare similare</p>
							<p class="mb-0">
								5520 - Facilitati de cazare pentru vacante si perioade de scurta durata
							</p>
							<p class="mb-0">5530 - Parcuri pentru rulote, campinguri si tabere</p>
							<p>5590 - Alte servicii de cazare</p>
						</div>
						<div>
							<p class="mb-0 caen-eligibilitatea-sectie">
								Structuri de alimentaţie, care desfăşoară activităţi conform codurilor
								CAEN:
							</p>
							<p class="mb-0">5610 - Restaurante</p>
							<p class="mb-0">
								5621 - Activitati de alimentatie (catering) pentru evenimente
							</p>
							<p class="mb-0">5629 - Alte activitati de alimentatie n.c.a.</p>
							<p>5630 - Baruri si alte activitati de servire a bauturilor</p>
						</div>
						<div>
							<p class="mb-0 caen-eligibilitatea-sectie">
								Organizatori de evenimente care desfăşoară activităţi conform codului
								CAEN:
							</p>
							<p>
								8230 - Activitati de organizare a expozitiilor, târgurilor si congreselor
							</p>
						</div>
						<div>
							<p class="mb-0 caen-eligibilitatea-sectie">
								Ghizii de turism care desfăşoară activităţi conform codului CAEN:
							</p>
							<p class="mb-0">7990 - Alte servicii de rezervare si asistenta turistica</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3">
				<div class="card tab-height">
					<div class="card-body">
						<div>
							<h5 class="card-title box-title">
								<span class="text-title">Scăderea ciferi de afaceri în 2020</span>
							</h5>
							<div class="text-center mb-2">
								<b-img
									class="grafic-2020"
									:src="require('@/assets/images/horeca/graficScadereaCifreiAfaceri.png')"
								/>
							</div>
							<div>
								<p>
									Pentru a aplica la Procedura HORECA fiecare solicitant trebuie să fi
									suferit o scădere a CA in anul 2020 față de 2019.
								</p>
								<div>
									<p class="text-center font-weight-bolder font-size-90 my-5">20%</p>
								</div>
								<p>
									Ajutorul se acordă beneficiarilor sub forma unor granturi în cuantum de
									20% din pierderea cifrei de afaceri din 2020, față de 2019, dar max.
									800.000 euro, rezultată exclusiv din desfăşurarea activităţilor aferente
									codurilor CAEN eligibile.
								</p>
								<p class="text-center font-weight-bolder font-size-43 mt-4">800.000 €</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-5">
				<div class="card tab-height">
					<div class="card-body">
						<div>
							<div class="d-flex justify-content-between">
								<h5 class="card-title box-title mb-0">
									<span class="text-title">Condiții de eligibilitate</span> - HORECA
								</h5>
								<b-img
									class="warning-sign"
									:src="require('@/assets/images/horeca/warningSign.png')"
								/>
							</div>
							<div class="tipuri-activitati-lista mt-2">
								<p class="caen-eligibilitatea-sectie">
									<span class="font-weight-normal">Proedura art 3.5. </span>Beneficiarii
									eligibili trebuie să îndeplinească și următoarele condiții cumulative:
								</p>
								<p>
									a) nu sunt întreprinderi în dificultate în sensul definiţiei de la art.
									2 pct. 18 din Regulamentul (UE) nr. 651/2014 al Comisiei din 17 iunie
									2014 de declarare a anumitor categorii de ajutoare compatibile cu piaţa
									internă în aplicarea articolelor 107 şi 108 şi/sau nu erau în
									dificultate la 31 decembrie 2019, dar care s-au confruntat cu
									dificultăţi sau au intrat în dificultate ulterior, din cauza epidemiei
									de COVID-19.
								</p>
								<p>
									b) nu depăşesc plafonul de 1.800.000 Euro conform secțiunii 3.1. din
									Comunicarea Comisiei Europene - Cadru temporar pentru măsuri de ajutor
									de stat de sprijinire a economiei în contextul actualei epidemii de
									COVID-19
								</p>
								<p>
									c) nu fac obiectul unei decizii emise de către Comisia Europeană/alt
									furnizor de ajutor de stat/Consiliul Concurenţei de recuperare a unui
									ajutor de stat/de minimis sau, în cazul în care au făcut obiectul unei
									astfel de decizii, aceasta a fost deja executată şi creanţa integral
									recuperată, cu dobânzile aferente.
								</p>
								<p>
									d) nu se află în insolvenţă la momentul înscrierii în cadrul schemei de
									ajutor de stat.
								</p>
								<p>
									e) își mențin activitatea pentru care au obţinut finanţare pentru cel
									puţin 6 luni de la data primei plăţi a ajutorului de stat pentru
									valoarea grantului mai mică sau egală cu echivalentul in lei al sumei de
									200000 euro, sau 12 de luni de la data primei plăţi a ajutorului de
									stat, în cazul în care valoarea grantului este mai mare decât
									echivalentul în lei al sumei de 200.000 euro
								</p>
								<p>
									f) au depus situaţiile financiare anuale aferente ultimelor două
									exerciţii financiare încheiate (2019 și 2020), pentru care s-a împlinit
									termenul legal de depunere, prevăzut la art. 36 alin. (1) din Legea nr.
									82/1991
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-xl-4">
				<div class="card tab-height">
					<div class="card-body">
						<h5 class="card-title box-title mb-0">
							<span class="text-title">HORECA</span> - Eligibilitatea solicitanților
						</h5>
						<p>conform procedura art. 3.4.</p>
						<div class="mt-2">
							<p>
								Granturile se acordă pe bază de contract de finanțare încheiat cu
								următoarele categorii de beneficiari: întreprinderi care desfăşoară
								activităţi autorizate de agenţii de turism conform codurilor CAEN Rev 2
								7911, 7912, 7990, întreprinderi care desfăşoară activităţi autorizate de
								structuri de primire turistice cu funcţiuni de cazare conform codurilor
								CAEN Rev 2 5510, 5520, 5530, 5590, întreprinderi care desfăşoară
								activităţi autorizate de structuri/unități de alimentaţie, conform
								codurilor CAEN Rev 2 5610, 5621, 5629, 5630, întreprinderi care desfășoară
								activități autorizate de organizare de evenimente conform codului CAEN Rev
								2 8230 , precum şi întreprinderi care desfăşoară activităţi autorizate de
								ghid de turism conform codului CAEN Rev 2 7990, întreprinderi care sunt
								înregistrate/înregistrați în scopuri fiscale pe teritoriul României.
								Activităţile sunt eligibile dacă sunt desfăşurate în baza unor licenţe de
								turism emise de autoritatea publică centrală responsabilă în domeniul
								turismului pentru agențiile de turism, certificate de clasificare emise de
								autoritatea publică centrală responsabilă în domeniul turismului pentru
								structurile de cazare, autorizaţii de funcţionare emise de către
								autoritățile publice locale sau certificate de clasificare emise de
								autoritatea publică centrală responsabilă în domeniul turismului pentru
								structurile/unitățile de alimentație publică, autorizatiile emise de catre
								directiile sanitar – veterinare pentru unitățile de alimentație publică
								mobile, <s>certificate constatatoare eliberate de Oficiul Național Registrul
								Comerțului pentru organizatorii de evenimente</s> <i>(*Acest document nu mai este necesar (update 22 iunie) MEAT, STS si ONRC  au identificat soluția tehnică pentru verificarea automată a istoricului autorizării codului CAEN.)</i> sau atestate de ghid de
								turism emise de autoritatea publică centrală responsabilă în domeniul
								turismului. Dacă o întreprindere deține mai multe puncte de lucru
								(structuri de cazare/structuri de alimentație/ agenții de turism) se vor
								încărca certificatele de clasificare/autorizațiile/licențele pentru
								fiecare punct de lucru. Licențele/certificatele/autorizațiile/atestatele
								trebuie să fie valabile la momentul transmiterii formularului de înscriere
								în cadrul Schemei. Codul/codurile CAEN Rev 2 pentru care aplicantul
								solicită finanțare trebuie să fi fost autorizat/autorizate pe perioada
								aferentă bazei de calcul în cadrul prezentei Scheme.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-3">
				<div class="card tab-height">
					<div class="card-body">
						<div>
							<h5 class="card-title box-title mb-0">
								<span class="text-title">Documente necesare eligibilitate (2019 - 2020)</span>
							</h5>
							<p>Procedura art 5.1 b)</p>
							<div class="text-left mb-1">
								<b-img
									class="documente-necesare-icon"
									:src="require('@/assets/images/horeca/procedura51.png')"
								/>
							</div>
							<div>
								<p class="mb-0 caen-eligibilitatea-sectie">
									Aplicanții vor încărca in platforma:
								</p>
								<ul class="formulare-incarcate">
									<li>Certificat de clasificare</li>
									<p>pentru structurile de primire cu funcțiuni de cazare</p>
									<li>Autorizația de funcționare sau certificatul de clasificare</li>
									<p>pentru structurile de alimentație publică,</p>
									<li>Autorizația sanitar- veterinară</li>
									<p>pentru unitățile de alimentație publică mobile</p>
									<li>
										<s>
										Certificatul constatator eliberat de Oficiul Național Registrul
										Comerțului
										</s>
									</li>
									<p>pentru organizatorii de evenimente<br>
									Acest document nu mai este necesar (update 22 iunie) MEAT, STS si ONRC  au identificat soluția tehnică pentru verificarea automată a istoricului autorizării codului CAEN.</p>
									<li>Atestat ghizid turistic, respectiv licență</li>
									<p>pentru agențiile de turism.</p>
									<div class="mb-1">
										<b-img
											class="documente-necesare-icon"
											:src="require('@/assets/images/horeca/ghidTuristic.png')"
										/>
										<li>
											Raportul de expertiză contabilă, sau raportul de audit împreună cu
											anexa 7 la procedură.
										</li>
									</div>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-xl-5">
				<div class="card">
					<div class="card-body">
						<div>
							<div class="d-flex justify-content-between">
								<h5 class="card-title box-title mb-1">
									<span class="text-title">Cheltuieli eligibile</span> - HORECA
								</h5>
							</div>
							<div class="tipuri-activitati-lista mt-2">
								<p class="caen-eligibilitatea-sectie">
									<span class="font-weight-normal">Proedura art 3.5. </span>Beneficiarii
									eligibili trebuie să îndeplinească și următoarele condiții cumulative:
								</p>
								<p>
									Pentru etapa implementării acestei scheme de ajutor, procedura de
									implementare nu include o listă de cheltuieli eligibile. Beneficiarii
									schemei HORECA au posibilitatea de a achita cheltuieli curente si
									restante care au legătură cu activitatea pe care o desfășoară. Atenție!
									În cazul în care la momentul aplicării, beneficiarul de schema de grant
									are datorii catre bugetul de stat acesta își va lua angajamentul plății
									acestor datorii. Deasemenea grantul poate fi utilizat pentru achiziții
									de materii prime și materiale, servicii diverse, investiții, lucrări de
									modernizare, achiziție de echipamente și dotări sau materiale sanitare,
									datorii la bugetul statului, salarii nete etc.
								</p>
								<p>
									Operatorii economici beneficiari de ajutor în cadrul Schemei, vor ţine o
									evidenţă specifică a ajutoarelor primite conform prezentei Scheme, a
									altor scheme de stat şi a altor ajutoare de stat. Aceştia sunt obligaţi
									să arhiveze evidenţa specifică şi să o păstreze o perioadă de 10 ani.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<div>
							<div class="d-flex justify-content-between">
								<h5 class="card-title box-title mb-1">
									<span class="text-title">Recomandare</span> - HORECA
								</h5>
							</div>
							<div class="tipuri-activitati-lista mt-2">
								<p>
									<router-link
										:to="{
											path: '/horeca-documente-procedura',
											name: 'horeca-documente-procedura',
										}"
									>Descarcă</router-link>
									si analizeaza cu atentie “Procedura de implementare a schemei
									de ajutor de stat instituită prin Ordonanţa de urgenţă nr. 224/2020
									privind unele măsuri pentru acordarea de sprijin financiar pentru
									întreprinderile din domeniul turismului, alimentaţiei publice și
									organizării de evenimente, a căror activitate a fost afectată în
									contextul pandemiei de COVID-19”
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="card" style="height: 27.1%">
					<div class="card-body">
						<div>
							<div class="d-flex justify-content-center logo-horeca">
								<HorecaLogo></HorecaLogo>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HorecaPageTitle from './components/HorecaPageTitle'
import { BCardBody, BImg, BCard } from "bootstrap-vue";
	import HorecaLogo from "@/views/pages/horeca/components/HorecaLogo.vue";

	export default {
		data: () => ({}),
		components: {
    HorecaPageTitle,
			BCardBody,
			BImg,
			BCard,
			HorecaLogo,
		},
		data() {
			return {
				tipuriActivitati: [
					{
						name: "7911",
						tooltip: "7911 - Activitati ale agentiilor turistice",
					},
					{
						name: "7912",
						tooltip: "7912 - Activitati ale tur-operatorilor",
					},
					{
						name: "7990",
						tooltip: "7990 - Alte servicii de rezervare si asistenta turistica",
					},
					{
						name: "5510",
						tooltip: "5510 - Hoteluri si alte facilitati de cazare similare",
					},
					{
						name: "5520",
						tooltip: "5520 - Facilitati de cazare pentru vacante si perioade de scurta durata",
					},
					{
						name: "5530",
						tooltip: "5530 - Parcuri pentru rulote, campinguri si tabere",
					},
					{
						name: "5590",
						tooltip: "5590 - Alte servicii de cazare",
					},
					{
						name: "5610",
						tooltip: "5610 - Restaurante",
					},
					{
						name: "5621",
						tooltip: "5621 - Activitati de alimentatie (catering) pentru evenimente",
					},
					{
						name: "5629",
						tooltip: "5629 - Alte activitati de alimentatie n.c.a.",
					},
					{
						name: "5630",
						tooltip: "5630 - Baruri si alte activitati de servire a bauturilor",
					},
					{
						name: "8230",
						tooltip: "8230 - Activitati de organizare a expozitiilor, târgurilor si congreselor",
					},
					{
						name: "7990",
						tooltip: "7990 - Alte servicii de rezervare si asistenta turistica",
					},
				],
			};
		},
		mounted() {},
		methods: {},
	};
</script>

<style scoped>
	p {
		font-size: 13px;
		text-align: justify;
		color: #898a93;
		font-weight: 500;
	}
	.caen-eligibilitatea-sectie {
		color: #38d7bf;
		font-weight: bold;
		font-size: 13px !important;
	}
	h5 {
		color: #898a93 !important;
	}
	.text-title {
		font-weight: 700;
	}
	.box-title {
        font-size: 13px !important;
    }
	.coduriCaenList {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
		text-align: center;
	}
	.tipuri-activitati-lista p {
		font-size: 12px;
	}
	.tab-height {
		height: 98% !important;
	}
	.top-title {
		display: flex;
		justify-content: space-between;
	}
	.horeca-title {
		max-width: 760px;
		align-self: center;
	}
	.grafic-2020 {
		width: 90%;
		height: auto;
	}
	.warning-sign {
		width: 45px;
		height: auto;
		margin-top: -10px;
	}
	.documente-necesare-icon {
		width: 65px;
		height: auto;
	}
	.font-size-90 {
		font-size: 90px;
		color: #d2d2d2;
	}
	.font-size-43 {
		font-size: 43px;
		color: #d2d2d2;
	}
	.flex-end {
		align-items: flex-end;
	}
	.logo-horeca {
		padding: 58px 0;
	}

	.formulare-incarcate {
		padding: 0;
	}
	.formulare-incarcate li {
		color: #5c5c5c;
		font-weight: 700;
		font-size: 13px;
		display: list-item;
		list-style-type: disc;
		list-style-position: inside;
		margin-top: 30px;
		line-height: 1.6rem;
	}
	.formulare-incarcate p {
		font-size: 11px;
		margin: 5px 0 30px 0;
	}

	.text-eligibilitate,
	.tipuri-activitati-lista h5,
	.coduriCaenList h5 {
		color: #38d7bf;
		font-weight: bolder;
		font-size: 38px;
	}
	.tipuri-activitati-lista h5 {
		color: #898a93 !important;
		margin-right: 20px;
	}
	.coduriCaenList h5:hover {
		color: #38d7bf !important;
		position: relative;
	}
	.tooltiptext {
		position: absolute;
		background: rgba(255, 255, 255, 0.8);
		color: #898a93;
		font-size: 15px;
		border: solid 1px #dedede;
		border-radius: 3px;
		padding: 5px;
		white-space: nowrap;
		left: 64px;
		top: -15px;
		display: none;
		font-weight: lighter;
	}
	.tipuri-activitati:nth-last-child(-n+3) .tooltiptext {
		position: absolute;
		background: rgba(255, 255, 255, 0.8);
		color: #898a93;
		font-size: 15px;
		border: solid 1px #dedede;
		border-radius: 3px;
		padding: 5px;
		white-space: nowrap;
		left: -250px;
		display: none;
		font-weight: lighter;
	}
	.coduriCaenList h5:hover .tooltiptext {
		display: block;
		z-index: 99;
	}

	.col-xl-8,
	.col-xl-4 {
		margin-bottom: 0;
	}
</style>
