<template>
    <div>
        <div class="row">
            <div class="col-xl-12">
                <b-alert class="mt-0" variant="danger" show v-if="!isM2Manager">
                    <div class="alert-body">
                  <span>
                    ATENȚIE! Nu ai selectat pachetul M2 Manager. Pentru a vedea această pagină alege pachetul M2 Manager accesând
                    <router-link
                            :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'serviciile-mele' },
                      }"
                    ><strong>Serviciile mele</strong></router-link>.
                  </span>
                    </div>
                </b-alert>
                <b-alert class="mt-0" variant="danger" show v-else-if="!isPaid">
                    <div class="alert-body">
                  <span>
                    Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea <router-link
                          :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'factura-contract' },
                      }"
                  ><strong>Factură si Contract</strong></router-link>. În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager)

                  </span>
                    </div>
                </b-alert>
            </div>
        </div>
        <div v-if="isM2Manager">
            <b-row class="row customTwoColumn">
                <b-col class="column" cols="12" sm="12" md="12" xl="7">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="mb-3 mt-1">Pași de urmat:</h4>
                            <p><span>1.</span>După finalizarea raportului de progres, sumele rămase neutilizate vor fi plătite de către beneficiari, prin ordin de plată, din contul personal dedicat măsurii în contul Agenției pentru IMM, Atragere de Investiții și Promovare a Exportului (AIMMAIPE) de care aparține beneficiarul. Lista conturilor aferente AIMMAIPE de care aparțin beneficiarii se regăsește pe site-ul www.imm.gov.ro, ataşat acestui comunicat, precum și la nivelul băncii partenere, pentru fiecare dintre cele două măsuri de sprijin. <br><br>

                               <span>2.</span>Banca parteneră poate informa suplimentar beneficiarul asupra coordonatelor conturilor AIMMAIPE în care urmează a fi virate sumele rămase necheltuite. Pentru a nu exista situații în care beneficiarii virează în alt cont sumele rămase necheltuite, după identificarea contului aferent AIMMAIPE de care aparțin pe lista postată mai jos, pe site-ul www.imm.gov.ro, rugăm beneficiarii să confirme contul cu instituția de credit parteneră.<br><br>

                               <span>3.</span>După efectuarea plății sumei neutilizate și în momentul în care va fi operaționalizat modulul informatic, beneficiarii vor încărca în aplicație ordinul de plată.<br><br>

                               <span>4.</span>Ultimul pas este acela de închidere a contului dedicat, în funcție de procedura internă a băncii partenere.</p>
                        </div>
                    </div>
                </b-col>
                <b-col class="column" cols="12" sm="12" md="12" xl="5">
                    <div class="card">
                        <div class="card-body" v-if="!this.agentiiData_imm">
                            <h4 class="mt-1">Această secțiune este disponibilă după adăugarea companiei. Adaugă o companie din secțiunea
                                <router-link to="/grantul-meu"><strong>Grantul meu</strong></router-link>.
                            </h4>
                        </div>
                        <div class="card-body" v-else>
                            <div class="row">
                                <b-form-group
                                        class="col-md-7"
                                        label="Agentia ta pentru IMM"
                                        label-for="agency"
                                        size="lg"
                                >
                                    <b-form-input
                                            size="lg"
                                            id="agency"
                                            v-model="this.agentiiData_imm.name"
                                            placeholder="Agentia ta pentru IMM"
                                            disabled
                                    />
                                </b-form-group>

                                <b-form-group class="col-md-5" label="CIF" label-for="cui">
                                    <b-form-input
                                            size="lg"
                                            id="cui"
                                            v-model="this.agentiiData_imm.cif"
                                            placeholder="CUI"
                                            disabled
                                    />
                                </b-form-group>
                            </div>
                            <div class="border mt-1 ibanItem" v-for="(agentie, agentieID) in this.agentiiData_imm.iban" :key="agentie.id">
                                <div class="bankLogo">
                                    <span :class="agentieID"></span>
                                </div>
                                {{agentie}}
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <YoutubeEmbed link="WWmSsowFB50" />
    </div>
</template>

<script>
    import { BAlert, BImg, BCol, BRow, BFormGroup, BFormInput } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import config from '@/config'
    import axios from '@axios'
    import agentii_imm from '../m2-manager/m2-agencies.json'
    import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'

    export default {
        data: () => ({
            agentii_imm,
            agentiiData_imm: {
                name: null,
                cif: null,
                iban: null
            },
            agencyName: '',
            isPaid: false,
            isM2Manager: false,
            immCui: null,
            formData: {
                m2_agency_id: null,
            },
        }),
        components: {
            BAlert,
            BImg,
            BCol,
            BRow,
            BFormGroup,
            vSelect,
            BFormInput,
            YoutubeEmbed
        },
        mounted() {
            this.showPayPopupHandler()
            this.isM2ManagerHandler()
            this.showImm()
        },
        methods: {
            isM2ManagerHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isM2Manager = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.id === 2
                })
            },
            showPayPopupHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isPaid = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.program_id === 1 && key.paid_confirmed !== null
                })
            },
            showImm() {
                axios
                    .get(config.API_ENDPOINTS.getM2data)
                    .then(({ data }) => {
                        this.formData = data.data;
                        this.agentiiData_imm = this.agentii_imm.find(agentie =>  agentie.id === data.data.m2_agency_id)

                    })
                    .catch((error) => console.log('Error: ', error))
            },
        },
    }
</script>

<style scoped>
    .coloredText {
        color: #01e8c6;
    }
    .card-body p span{
        margin-right: 15px;
    }
    .column {
        flex: 1;
        display: flex;
    }
    .card {
        width: 100%;
    }
    .ibanItem{
        display: flex;
        align-items: center;
        padding: 5px 15px;
        font-size: 16px;
    }
    .bankLogo{
        min-width: 35%;
        margin-right: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bt, .unicredit, .cec, .otp, .brom,
    .brd, .raiffeisen, .bcr
    { display: inline-block; background: url('../../../assets/images/m2/banksLogos.png') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }

    .bt { background-position: -0px -0px; width: 142px; height: 31px; }
    .unicredit { background-position: -0px -31px; width: 135px; height: 18px; }
    .cec { background-position: -0px -49px; width: 110px; height: 21px; }
    .otp { background-position: -0px -70px; width: 86px; height: 19px; }
    .brom { background-position: -0px -89px; width: 85px; height: 20px; }
    .brd { background-position: -0px -109px; width: 82px; height: 19px; }
    .raiffeisen { background-position: -0px -128px; width: 76px; height: 20px; }
    .bcr { background-position: -86px -70px; width: 54px; height: 20px; }
    @media(max-width: 1450px) {
        .customTwoColumn{
            display: block;
        }
        .customTwoColumn .column{
            max-width: 100%;
        }
    }
</style>

