<template>
  <div>
    <div v-if="isM2Manager">
      <b-alert class="mt-0" variant="danger" show v-if="!isPaid">
        <div class="alert-body">
                  <span>
                    Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea <router-link
                      :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'factura-contract' },
                      }"
                  ><strong>Factură si Contract</strong></router-link>. În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager)

                  </span>
        </div>
      </b-alert>
        <b-card class="mb-1">
            <b-img
                    class="warning mr-2"
                    width="50"
                    left
                    :src="require('@/assets/images/m2/warning.png')"
            />
            <p class="mb-0">
                Catalogul cheltuielilor este întocmit de experți SMIS.ro în colaborare cu experți și consultanți în domeniul fiscal, în baza procedurii de implementare și a actualizărilor transmise de Ministerul Economiei.
                Cheltuielile eligibile sunt în strânsă legatură cu codul CAEN pentru care s-a aplicat. Vă rugăm să acordați o atenție sporită procedurii de implementare.
            </p>
        </b-card>
        <b-card>
            <b-row>
                <b-col cols="7">
                    <!-- search -->
                    <b-form-group>
                        <b-button
                                class="mr-1"
                                :variant="(filterActive === 'TOATE' || filterActive === undefined ? 'primary' : 'outline-primary')"
                                @click="categoryFilter()"
                        >TOATE</b-button>
                        <b-button
                                class="mr-1"
                                :variant="(filterActive === 'PRODUSE' ? 'primary' : 'outline-primary')"
                                @click="categoryFilter('PRODUSE')"
                        >Produse</b-button>
                        <b-button
                                class="mr-1"
                                :variant="(filterActive === 'SERVICII' ? 'primary' : 'outline-primary')"
                                @click="categoryFilter('SERVICII')"
                        >Servicii</b-button>

                        <b-button
                                class="mr-1"
                                :variant="(filterActive === 'TAXE SI IMPOZITE CATRE STAT' ? 'primary' : 'outline-primary')"
                                @click="categoryFilter('TAXE SI IMPOZITE CATRE STAT')"
                        >Taxe și impozite către stat</b-button>
                        <b-button
                                :variant="(filterActive === 'CHELTUIELI NEELIGIBILE' ? 'danger' : 'outline-danger')"
                                @click="categoryFilter('CHELTUIELI NEELIGIBILE')"
                        >Cheltuieli neeligibile</b-button>
                    </b-form-group>
                </b-col>
                <b-col cols="5">
                    <!-- search -->
                    <b-form-group>
                        <b-input-group size="md">
                            <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Caută..."
                            ></b-form-input>

                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">Șterge</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-table
                    class="text-center"
                    @row-clicked="rowClicked"
                    striped
                    hover
                    :fields="fields"
                    :items="cheltuieli"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
            >
                <template slot="Cheltuiala" slot-scope="row">
                    {{ row.item.Cheltuiala }}aaa
                </template>
                <template #row-details="row">
                    {{row.item.Descriere}}
                </template>
            </b-table>

<!--            <b-row class="mt-2">-->
<!--                <b-col cols="6">-->
<!--                &lt;!&ndash; page length &ndash;&gt;-->
<!--                    <b-form-group-->
<!--                            label="Per Pagină"-->
<!--                            label-cols="2"-->
<!--                            label-align="left"-->
<!--                            label-size="sm"-->
<!--                            label-for="sortBySelect"-->
<!--                            class="text-nowrap mb-md-0 mr-1 per_page_label"-->
<!--                    >-->
<!--                        <b-form-select-->
<!--                                id="perPageSelect"-->
<!--                                v-model="perPageSelect"-->
<!--                                size="sm"-->
<!--                                inline-->
<!--                                :options="pageOptions"-->
<!--                        />-->
<!--                    </b-form-group>-->
<!--                    </b-col>-->
<!--                &lt;!&ndash; pagination &ndash;&gt;-->
<!--                <b-col cols="6" >-->
<!--                    <b-pagination-->
<!--                            v-model="currentPage"-->
<!--                            :total-rows="rows"-->
<!--                            :per-page="perPage"-->
<!--                            first-number-->
<!--                            last-number-->
<!--                            prev-class="prev-item"-->
<!--                            next-class="next-item"-->
<!--                            class="float-right mb-0"-->
<!--                    >-->
<!--                        <template #prev-text>-->
<!--                            <feather-icon-->
<!--                                    icon="ChevronLeftIcon"-->
<!--                                    size="18"-->
<!--                            />-->
<!--                        </template>-->
<!--                        <template #next-text>-->
<!--                            <feather-icon-->
<!--                                    icon="ChevronRightIcon"-->
<!--                                    size="18"-->
<!--                            />-->
<!--                        </template>-->
<!--                    </b-pagination>-->
<!--                </b-col>-->
<!--            </b-row>-->
        </b-card>
    </div>
    <div v-else>
        <b-alert class="mt-0" variant="danger" show v-if="!isM2Manager">
            <div class="alert-body">
                  <span>
                    ATENȚIE! Nu ai selectat pachetul M2 Manager. Alege pachetul accesând
                    <router-link
                            :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'serviciile-mele' },
                      }"
                    ><strong>Serviciile mele</strong></router-link>.
                  </span>
            </div>
        </b-alert>
    </div>
  <YoutubeEmbed link="NEpfvjLZpJ0" />
  </div>
</template>

<script>
    import axios from '@axios'
    import store from '@/store'
    import {
        BImg, BRow, BCol, BCard, BTable, BFormGroup, BPagination, BInputGroup, BFormSelect, BFormInput, BInputGroupAppend, BButton, BAlert,
    } from 'bootstrap-vue'
    import cheltuieliData from './components/cheltuieli.json'
    import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BTable,
            BFormGroup,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BFormSelect,
            BImg,
            BAlert,
            YoutubeEmbed
        },
        data() {
            return {
                filterActive: 'TOATE',
                isPaid: false,
                isM2Manager: false,
                filter: null,
                filterOn: [],
                cheltuieli: cheltuieliData.cheltuieli,
                // perPage: 50,
                // perPageSelect: 50,
                // pageOptions: [5, 10, 20, 50, 100, 'Toate'],
                //currentPage: 1,
                fields: [
                    {
                        key: 'Cheltuiala',
                        label: 'Cheltuială',
                        tdClass: 'cheltuiala'
                    },
                    {
                        key: 'Subcategorie',
                        label: 'Subcategorie cheltuială'
                    },
                    {
                        key: 'Categorie',
                        label: 'Categorie cheltuială'
                    },
                ],
            }
        },
        computed: {
            rows() {
                return this.cheltuieli.length
            }
        },
        mounted() {
            this.showPayPopupHandler()
            this.isM2ManagerHandler()
        },
        // watch: {
        //     perPageSelect(newVal, oldVal){
        //         if (newVal === 'Toate') {
        //             this.perPage = this.cheltuieli.length
        //         } else {
        //             this.perPage = newVal
        //         }
        //     },
        // },
        methods: {
            isM2ManagerHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isM2Manager = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.id === 2
                })
            },
            showPayPopupHandler() {
                let allPlans = store.getters['auth/user'].plans
                this.isPaid = !!allPlans.find(function (key) {
                    // program_id === 1 is M2 Manager Program
                    // program_id === 2 is POCU Manager Program
                    return key.program_id === 1 && key.paid_confirmed !== null
                })
            },
            rowClicked(item,index){
                this.$set(item, '_showDetails', !item._showDetails)
            },
            // onFiltered(filteredItems) {
            //     this.totalRows = filteredItems.length
            //     this.currentPage = 1
            // },
            categoryFilter(e) {
                //this.currentPage = 1
                this.filterActive = e;
                this.cheltuieli = cheltuieliData.cheltuieli;
                if(e) {
                    this.cheltuieli = this.cheltuieli.filter(item => item.Categorie === e);
                } else {
                    this.cheltuieli = cheltuieliData.cheltuieli;
                }
            },
            onFiltered(filteredItems) {
              console.log('this.filterActive', this.filterActive)
              this.filterActive = 'TOATE'
              this.categoryFilter()
            }
        },
    }
</script>

<style>
    .table-success td{
        background-color: #25ebce !important;
        font-weight: bold;
    }
    .table-info td{
        background-color: #baece5 !important;
    }
    .table-neeligibil td{
        background-color: #f66363 !important;
        color: #fff;
        font-weight: bold;
    }
    .cheltuiala {
        width: 50%;
    }
    .content-body .table thead th{
        font-size: 16px;
        text-transform: initial;
    }
    .b-table-details, .b-table-details td{
        text-align: left;
        background-color: #fff !important;
        border: 1px solid;
    }
    .b-table-details{
        border: none;
        overflow: hidden;
        padding-top: 20px !important;
    }
    .b-table-details td{
        background-color: #e6e6e6 !important;
        border: none;
        position: relative;
        font-weight: normal;
        color: #6e6b7b;
    }
    .b-table-has-details{
        font-weight: bold;
    }
    .content-body .table tbody tr{
        outline: none;
    }
    .b-table-details td:before{
        content:"";
        position: absolute;
        top: -10px;
        left: 23.5%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 25px 20px;
        border-color: transparent transparent #e6e6e6 transparent;
    }
    #perPageSelect{
        width: 80px;
    }
    .per_page_label{
        align-items: center;
    }
    .per_page_label > label{
        max-width: initial !important;
        width: auto;
        flex: initial;
    }
</style>
