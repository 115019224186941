import Vue from 'vue'
import { ToastPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/vue-select'
import '@/libs/tour'
import VueGoodTablePlugin from 'vue-good-table'
import VueGtag from "vue-gtag";

// Configuration Vue Google Analytics
if(process.env.NODE_ENV === 'production') {
    Vue.use(VueGtag, {
        config: {id: "G-61Q87RMTF4"}
    }, router);
}

// import the styles
import 'vue-good-table/dist/vue-good-table.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
// Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueGoodTablePlugin)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Vue.config.productionTip = false

var moment = require('moment')
require('moment/locale/ro.js')
moment.locale('ro')

import VueEvents from 'vue-events'
Vue.use(VueEvents)

moment.updateLocale('ro', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'acum',
    ss: '%d sec',
    m: 'un min',
    mm: '%d min',
    h: 'o ora',
    hh: '%d ore',
    d: 'o zi',
    dd: '%d zile',
    M: 'o luna',
    MM: '%d luni',
    y: 'un an',
    yy: '%d ani',
  },
})

// Vue.filter('moment', function(value, format) {
//   return moment(value).format(format)
// })

Vue.filter('truncate', function(value, length) {
  if (value.length < length) {
    return value
  }

  return value.substring(0, length) + '...'
})

Vue.filter('moment', function(value, format) {
  return moment(value).format(format)
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
