<template>
  <div class="logo">
    <b-img
            :src="appLogoImage"
            :alt="appName"
    />
    <!--<div v-if="!fromNavMenu">-->
      <!--<h1 class="ml-1 mb-0" v-if="!fromNavMenu">{{ appName }}</h1>-->
    <!--</div>-->
    <!--<div v-else>-->
      <!--<h2 class="ml-2 mb-0 logoName">{{ appName }}</h2>-->
      <!--<h6 class="ml-2 mb-0 logoSlogan">structural management innovative system</h6>-->
    <!--</div>-->
  </div>
</template>

<script>
    import { BLink, BImg } from 'bootstrap-vue'
    import { $themeConfig } from '@themeConfig'

    export default {
        components: {
            BLink,
            BImg,
        },
        props: ['fromNavMenu'],
        setup() {
            // App Name
            const { appName, appLogoImage } = $themeConfig.app
            return {
                appName,
                appLogoImage,
            }
        },
    }
</script>
<style>
  .logo{
    display: flex;
    align-items: center;
  }
  .logo h1{
    font-size: 4rem;
    font-weight: 100;
  }
  .logo h2{
    font-size: 3.5rem;
    font-weight: 100;
  }
  .logo .logoName{
    font-size: 20px;
    font-weight: 400;
    border-bottom: 1px solid;
    display: inline-block;
  }
  .logo .logoSlogan{
    font-size: 10px;
    white-space: break-spaces;
    max-width: 90px;
    margin-top: 10px;
  }
</style>