<template>
    <div class="row blog-list-wrapper" v-if="!loading">
        <div class="col-xl-4 col-lg-4 col-md-6"
             v-if="news.title"
             v-for="(news,index) in sortedNewsItems"
             :key="news.id"
        >
            <article class="card">
                <div class="card-body p-0">
                    <div>
                        <b-media no-body>
                            <a class="newsImgContainer" :href="news.url" target="_blank">
                                <b-img
                                        class="newsImg"
                                        :src="news.picture_url"
                                        fluid
                                        rounded
                                />
                            </a>
                        </b-media>
                    </div>
                    <div class="p-2">
                        <h4 class="card-title mb-1">
                            <a class="blog-title-truncate text-body-heading" :href="news.url" target="_blank" v-if="news.title">{{ news.title | truncate(150) }}</a>
                        </h4>
                        <div class="text-muted mb-1" v-if="news.date">
                            {{ news.date | moment('DD.MM.YYYY') }}
                        </div>
                        <div v-if="news.description">{{ news.description | truncate(170) }}</div>
                    </div>

                </div>
            </article>

        </div>
        <div class="mt-2" v-else>
            Momentan nu sunt disponibile știri și comunicate MEEMA.
        </div>
    </div>
</template>

<script>
    import axiosIns from '@axios'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import {
        BRow, BLink, BCol, BCard, BCardText, BButton, BImg, BAlert, BMedia, BMediaAside, BMediaBody
    } from 'bootstrap-vue'
    import VueApexCharts from 'vue-apexcharts'
    import { $themeColors } from '@themeConfig'
    import store from '@/store/index'
    import config from '@/config'
    import Logo from '@core/layouts/components/Logo.vue'

    export default {
        data() {
            return {
                loading: true,
                news: [],
                companies: [],
            }
        },
        components: {
            Logo, VueApexCharts, BRow, BCol, BLink, BCard, BCardText, BButton, BImg, BAlert, AppCollapseItem, AppCollapse, BMedia, BMediaAside, BMediaBody
        },
        mounted() {

        },
        methods: {
            getData(){
                axiosIns.get("/horeca/news-list")
                    .then(response => {
                        this.news = response.data.data;
                        this.loading = false;
                    })
                    .catch(e => {
                        this.errors.push(e);
                        this.loading = false;
                    })
            },
        },
        computed: {
            sortedNewsItems: function() {
                return this.news.sort( ( a, b) => new Date(b.date) - new Date(a.date))
            }
        },
        created() {
            this.getData();
        },
    }
</script>

<style scoped>
    .newsImgContainer {
        position: relative;
        padding-bottom: 56.2%;
        width: 100%;
    }
    .newsImg{
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .blog-title-truncate{
        min-height: 43px;
        display: flex;
    }
</style>

