import Cookies from 'js-cookie'

import config from '@/config'
import store from '@/store'
import axios from '@axios'

/**
 * Initialize new session on front-end
 */
export const startSession = () => {
  if (Cookies.get('XSRF-TOKEN')) return
  return axios.get(config.API_ENDPOINTS.csrf)
}

export const isLoggedIn = () => {
  return store.state.auth.authenticated
}

export const getUserData = () => {
  axios
    .get(config.API_ENDPOINTS.userData)
    .then(() => {
      this.$store.dispatch('auth/getUserData')
    })
    .catch(e => {
      console.log(e)
      //TODO: Handle different errors => 403 -> redirect to login with message
    })
}
