<template>
	<div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="top-title">
              <HorecaPageTitle></HorecaPageTitle>
              <div>
                <h3 class="mb-0">calculează ajutor</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-12">
        <div class="card box-height" style="height: 100%">
          <div class="card-body">
            <div>
              <h5 class="card-title mb-0">
                <span class="font-weight-bolder cifra-afaceri cyan">Cifra de afaceri 2019</span>
              </h5>
              <p class="text-line-hight font-weight-bolder mb-0">
                (introduceți suma in lei, conform bilanț - pentru veniturile din activități eligbile*)
              </p>
              <b-input-group id="fieldset-1" append="LEI" class="input-cyan">
                <b-form-input
                    id="input-1"
                    trim
                    v-model="ca2019"
                    @input="handleInput"
                ></b-form-input>
              </b-input-group>
            </div>

            <div>
              <h5 class="card-title mt-2 mb-0 flexed">
                <span class="font-weight-bolder cifra-afaceri orange">Cifra de afaceri 2020</span>
                <b-img
                  class=""
                  :src="require('@/assets/images/horeca/corona.png')"
                />
              </h5>
              <p class="text-line-hight font-weight-bolder mb-0">
                (introduceți suma in lei, conform bilanț - pentru veniturile din activități eligbile*)
              </p>
              <b-input-group id="fieldset-2" append="LEI" class="input-orange">
                <b-form-input
                    id="input-2"
                    trim
                    v-model="ca2020"
                    @input="handleInput"
                ></b-form-input>
              </b-input-group>
            </div>

            <div>
              <h5 class="card-title mt-3 mb-1 gray">
                <span class="font-weight-bolder">Veniturile companiei s-au diminuat cu:</span>
              </h5>
              <b-input-group id="fieldset-3" append="LEI" class="input-black">
                <b-form-input
                    id="input-3"
                    trim
                    disabled
                    :value="diferentaVenituriLei < 0 ? 0 : diferentaVenituriLei"
                ></b-form-input>
              </b-input-group>
              <b-input-group id="fieldset-4" append="€" class="input-gray mt-1">
                <b-form-input
                    id="input-4"
                    trim
                    disabled
                    :value="diferentaVenituriEur < 0 ? 0 : diferentaVenituriEur"
                ></b-form-input>
              </b-input-group>
              <!--<b-img-->
                  <!--class="grafic-calc"-->
                  <!--:src="require('@/assets/images/horeca/grafic-calculator.jpg')"-->
              <!--/>-->

              <canvas id="planet-chart"></canvas>

              <p class="mb-0 mt-1">*s-a luat in calcul un curs Euro mediu de 4.9 lei</p>
              <p class="mb-0">**sumele prezentate reprezinta valori estimate</p>
              <p class="mb-0">*activități eligibile CAEN:</p>
              <p class="mb-0">7911    7912    7990    5510    5520    5530    5590    5610    5621    5629    5630    8230    7990</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-12 col-md-6">
        <div class="card box-height mb-0">
          <div class="card-body">
            <h5 class="card-title font-small" style="font-size: 13px;">
              <span class="font-black">Ești eligibil pentru o finanțare</span> - HORECA
            </h5>

            <div class="finantare-one pb-1">
              <h5 class="euro-lei"><span class="cyan">{{eligibilEuro < 0 ? 0 : eligibilEuro}} €</span>  / {{eligibilLei < 0 ? 0 : eligibilLei}} lei</h5>
              <p class="mb-0">În cazul în care bugetul alocat este mai mare sau egal decât totalul aplicațiilor.
                (in limita a 200.000.000 €)</p>
            </div>

            <div class="finantare-two mt-1 pb-1">
              <h5 class="euro-lei mb-0"><span class="grayer">{{eligibilEuro < 0 ? 0 : eligibilEuro > 800000 ? 800000 : eligibilEuro}} €</span>  / {{eligibilLei < 0 ? 0 : eligibilLei > 3920000 ? 392000 : eligibilLei}} lei</h5>
              <p class="mt-0">dintre care:</p>
              <h5 class="euro-lei-small"><span class="cyan">{{eligibilGreenEur < 0 ? 0 : eligibilGreenEur}} €</span>  / {{eligibilGreenLei < 0 ? 0 : eligibilGreenLei}} lei</h5>
              <h5 class="euro-lei-small"><span class="orange">{{eligibilRedEur < 0 ? 0 : eligibilRedEur}} €</span>  / {{eligibilRedLei < 0 ? 0 : eligibilRedLei}} lei</h5>
              <p class="two-colors mb-0">În cazul în care bugetul alocat este mai mare sau egal decât totalul aplicațiilor.
                (in limita a 500.000.000 € / dintre care 200.000.000 €  - buget prevăzut de MEAT <span class="cyan">€</span>
                și 300.000.000 € suplimentare decisă de aprobarea unei rectificări bugetare) <span class="orange">€</span></p>
            </div>

            <div class="finantare-three mt-0 pt-1">
              <div class="header-finantare">
                <p>Simulează pentru cereri totale depuse de către toți aplicanții, în valoare de:</p>
                <b-input-group id="fieldset-5" append="€" class="input-gray mt-1">
                  <b-form-input
                      id="input-5"
                      trim
                      v-model="cereriTotaleInput"
                      @input="handleInputCereriTotale"
                  ></b-form-input>
                </b-input-group>
              </div>
              <h5 class="euro-lei mb-0"><span class="grayer">{{cereriTotaleEur < 0 ? 0 : cereriTotaleEur > 800000 ? 800000 : cereriTotaleEur}} €</span>  / {{cereriTotaleLei < 0 ? 0 : cereriTotaleLei > 3920000 ? 392000 : cereriTotaleLei}} lei</h5>
              <p class="two-colors mt-1">În cazul în care  valoarea însumată a cererilor de finanţare aprobate depăşeşte valoarea creditului de angajament sau bugetar plata către beneficiar se va face proporţional, prin raportarea sumei aprobate pentru fiecare beneficiar la suma totală a cererilor de finanţare aprobate.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-5 col-md-6">
        <div class="card heightfull">
          <div class="card-body">
            <div class="spacer"></div>
              <p class="buget-titlu-bold text-line-hight">Buget aprobat Comisia Europeană</p>
              <p class="buget-disponibil-bold cyan">500.000.000 €</p>
            <div class="spacer"></div>
              <p class="buget-titlu-bold text-line-hight">Buget alocat de Guvernul României</p>
              <p class="buget-disponibil-bold">200.000.000 €</p>
            <div class="spacer"></div>
              <p class="buget-titlu-bold text-line-hight">Credite de angajament</p>
              <p class="buget-disponibil-bold">300.000.000 €</p>
            <b-img
                class="grafic-calc"
                :src="require('@/assets/images/horeca/info-icon.png')"
            />
            <p class="text-bold">Prin implementarea măsurii se estimează acordarea
              de ajutor de stat unui număr maxim de 74.000 de beneficiari.</p>

          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-xl-12">
        <div class="card mb-1">
          <div class="card-body">
            <div class="top-title">
              <h5 class="m-0 horeca-title">
                <span class="font-black"> IMPLEMENTARE </span> (calendar estimat SMIS.ro)
              </h5>
              <div>
              </div>
            </div>
            <b-img
                class="grafic-calc"
                :src="require('@/assets/images/horeca/implementare-horeca.png')"
            />
          </div>
        </div>
        <p class="mt-0">*s-a luat in calcul un curs Euro mediu de 4.9 lei</p>
      </div>
    </div>
	</div>
</template>

<script>
	import HorecaPageTitle from './components/HorecaPageTitle'
    import { BCardBody, BImg, BCard, BFormGroup, BFormInput, BInputGroup } from "bootstrap-vue";
    import Chart from 'chart.js'

	export default {
      components: {
          HorecaPageTitle,
          BCardBody,
          BImg,
          BCard,
          BFormGroup,
          BFormInput,
          BInputGroup,
          Chart
      },
      data() {
          return {
              ca2019: '',
              ca2020: '',
              diferentaVenituriLei: '',
              diferentaVenituriEur: '',
              cursEur: 4.9,
              eligibilEuro: 0,
              eligibilLei: 0,
              eligibilGreenEur: 0,
              eligibilGreenLei: 0,
              eligibilRedEur: 0,
              eligibilRedLei: 0,
              cereriTotaleEur: 0,
              cereriTotaleLei: 0,
              cereriTotaleInput: 750000000,
          }
      },
      mounted() {

      },
      methods: {
          chartConstructor() {
              const chartElement = document.querySelector("canvas");
              const chart = new Chart(chartElement, {
                  type: "line",
                  data: {
                      labels: [2019, 2020],
                      datasets: [
                        {
                            label: '',
                            data: [this.ca2019, this.ca2020],
                            backgroundColor: "transparent",
                            borderColor: "#ff8674",
                            lineTension: 0,
                            pointBackgroundColor: "#ff8674",
                        },
                      ],
                  }
              });
          },
        handleInput() {
          if (this.ca2020 !== '') {
              this.diferentaVenituriLei = (this.ca2019 - this.ca2020).toFixed(1);
              this.diferentaVenituriEur = (this.diferentaVenituriLei / this.cursEur).toFixed(1);
              this.eligibilEuro = (20 / 100 * this.diferentaVenituriEur).toFixed(1);
              this.eligibilLei = (20 / 100 * this.diferentaVenituriLei).toFixed(1);
              this.eligibilGreenEur = (2 / 5 * this.eligibilEuro).toFixed(1);
              this.eligibilGreenLei = (this.eligibilGreenEur * this.cursEur).toFixed(1);
              this.eligibilRedEur = (3 / 5 * this.eligibilEuro).toFixed(1);
              this.eligibilRedLei = (this.eligibilRedEur * this.cursEur).toFixed(1);
              this.cereriTotaleEur = (this.eligibilEuro * 500000000 / this.cereriTotaleInput).toFixed(1);
              this.cereriTotaleLei = (this.cereriTotaleEur * this.cursEur).toFixed(1)

              this.chartConstructor()

              if(this.eligibilEuro > 800000){
                  this.eligibilEuro = 800000;
                  this.eligibilLei = 3920000;
              }

            }
        },
        handleInputCereriTotale() {
          if(this.eligibilEuro !== '') {
            this.cereriTotaleEur = (this.eligibilEuro * 500000000 / this.cereriTotaleInput).toFixed(1);
            this.cereriTotaleLei = (this.cereriTotaleEur * this.cursEur).toFixed(1)
          }
        }
      },
	};
</script>

<style scoped>
  #boxViziteaza .card-body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  p{
    font-size: 12px;
  }
  .heightfull{
    height: 100%;
  }
  .gray span{
    color: #898a93;
    font-size: 23px;
  }
  .input-cyan input[type=text]{
    font-size: 18px;
    font-weight: bold;
    color: #38d7bf;
  }
  .input-orange input[type=text]{
    font-size: 18px;
    font-weight: bold;
    color: #ff5c4a;
  }
  .input-black input[type=text]{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
  }
  .input-gray input[type=text]{
    font-size: 18px;
    font-weight: 600;
    color: #898a93;
  }
  .euro-lei{
    font-size: 30px;
    font-weight: lighter;
    color: #e6e6e6;
  }
  .euro-lei-small{
    font-size: 21px;
    font-weight: lighter;
    color: #e6e6e6;
  }
  .euro-lei-small span{
    font-weight: bold;
  }
  .text-bold{
    font-weight: bold;
    color: #a3a3a3;
    margin-top: 10px;
  }
  .euro-lei span{
    font-weight: bold;
  }
  .finantare-one, .finantare-two{
    border-bottom: 1px solid #dbdbdb;
  }
  .header-finantare{
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
  .header-finantare p{
    max-width: 47%;
    font-weight: 600;
  }
  .header-finantare .input-gray{
    max-width: 47%;
    font-weight: 600;
    max-height: 30px;
  }

  .finantare-two span{
    font-weight: 900;
  }
  .two-colors span.cyan{
    font-weight: 600;
  }
  .two-colors span.orange{
    font-weight: 600;
  }
  ::-webkit-input-placeholder{
    text-align: right;
  }
  .cifra-afaceri{
    font-size: 24px;
    margin-right: 10px;
  }
  .grafic-calc{
    max-width: 100%;
    margin-top: 15px;
  }
  .font-black{
    font-weight: 700;
    color: #898a93;
    font-size: 13px;
  }
  .flexed{
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
  .cyan{
    color: #38d7bf;
  }
  .orange{
    color: #ff5c4a;
  }
  .grayer{
    color: #828282;
  }
  .top-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .top-title h3{
    font-size: 38px;
    font-weight: bold;
    color: #a3a3a3;
  }
  .buget-disponibil-bold {
    font-weight: bold;
    font-size: 40px;
  }
  .spacer{
    height: 100px;
  }
  .buget-titlu-bold {
    color: #a3a3a3;
    font-weight: bold;
    font-size: 19px;
  }

  .guvernulWidget p {
    word-break: break-word;
    text-align: center;
    font-size: 16px;
  }

  .box-height {
    height: 100%;
  }
  .text-line-hight {
    line-height: 1.8rem;
  }
  .horeca-title {
    max-width: 760px;
    align-self: center;
  }
  p {
    font-size: 13px;
    text-align: justify;
    color: #898a93;
  }
  h6 {
    color: #898a93 !important;
  }
  @media only screen and (max-width: 1450px) {
    .buget-disponibil-bold {
      font-weight: bolder;
      font-size: 27px;
    }
    .top-title h3{
      font-size: 30px;
    }
    .euro-lei{
      font-size: 20px;
    }
    .cifra-afaceri{
      font-size: 17px;
    }
    .text-bold{
      font-size: 13px;
    }
    .horeca-title{
      max-width: 560px;
    }
  }
</style>