<template>
  <div class="calculatorValutar">
    <div class="row">
      <div class="col-xl-12">
        <b-alert class="mt-0 mb-0" variant="danger" show v-if="!isM2Manager">
          <div class="alert-body">
                  <span>
                    ATENȚIE! Nu ai selectat pachetul M2 Manager. Alege pachetul accesând
                    <router-link
                            :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'serviciile-mele' },
                      }"
                    ><strong>Serviciile mele</strong></router-link>.
                  </span>
          </div>
        </b-alert>
        <b-alert class="mt-0" variant="danger" show v-else-if="!isPaid">
          <div class="alert-body">
                  <span>
                    Atenție! Această pagină este disponibilă pentru utilizatorii M2 Manager. Te rugăm să achiți licența pentru pachetul ales. În cazul în care ai plătit factura te rugăm să ignori acest mesaj. Poți confirma plata accesând secțiunea <router-link
                          :to="{
                        path: '/',
                        name: 'pages-account-setting',
                        params: { to: 'factura-contract' },
                      }"
                  ><strong>Factură si Contract</strong></router-link>. În cazul în care plata nu este confirmată automat în termen de 3 zile lucrătoare și vezi în continuare acest mesaj te rugăm să ne contactezi la 0722.114.395. Îți mulțumim pentru utilizarea M2manager! (*neplata licenței de utilizare limitează accesul dumneavoastră la contul M2manager)

                  </span>
          </div>
        </b-alert>
        <div v-else class="card">
          <div class="card-body space-between">
            <div>
              <h3 class="mb-0">Achiziție în valută străină</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isM2Manager">
      <div class="monede-container mt-1 mb-3">
        <b-form-group
          v-slot="{ ariaDescribedby }"
          label="Moneda în care este emisă factura:"
        >
          <div v-for="(moneda, index) in monede" :key="index">
            <b-form-radio
              v-model="selected"
              :aria-describedby="ariaDescribedby"
              name="Monede"
              :value="moneda.name"
              @change="errorMoneda = ''"
            >
              <div class="card mb-0" :id="moneda.id">
                <div>
                  <p class="mb-0">{{ moneda.name }}</p>
                </div>
              </div>
              <p
                v-if="moneda.cursPrestabilit != ''"
                class="text-center curs-prestabilit mb-0"
              >
                {{ moneda.cursPrestabilit }}
              </p>
              <b-form-input
                class="inputCurs"
                v-if="moneda.cursPrestabilit === ''"
                v-model="cursPrestabilit"
                :aria-describedby="ariaDescribedby"
                trim
                @change="errorMoneda = ''"
              >
              </b-form-input>
            </b-form-radio>
          </div>
        </b-form-group>

        <p class="red">{{ errorMoneda }}</p>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="info-container">
            <div>
              <b-form-group label="Valoarea totală a facturii:">
                <b-input-group id="fieldset-1" :append="selected">
                  <b-form-input
                    id="input-1"
                    v-model="valoareFactura"
                    @change="errorValoareFactura = ''"
                    trim
                  ></b-form-input>
                </b-input-group>
              </b-form-group>

              <p class="red">{{ errorValoareFactura }}</p>
            </div>

            <div class="data-emiterii-facturii">
              <b-form-group label="Data emiterii facturii:">
                <b-form-datepicker
                  :date-format-options="{
                    year: 'numeric',
                    day: 'numeric',
                    month: 'numeric',
                  }"
                  local="ro-RO"
                  :start-weekday="1"
                  id="datepicker"
                  v-model="dataFactura"
                  @input="erroareDataFactura = ''"
                ></b-form-datepicker>
              </b-form-group>

              <p class="red">{{ erroareDataFactura }}</p>
            </div>

            <div class="curs-valutar-bnr">
              <b-form-group label="Cursul convenit pentru plata:">
                <b-input-group append="LEI">
                  <b-form-input
                          :value="cursBNR"
                          v-model="cursBNR"
                          @input="errorBnrValue = ''"
                          trim
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <p class="red">{{ errorBnrValue }}</p>
            </div>

            <div class="button-container">
              <b-button variant="primary" @click="getCursBNR">Calculează</b-button>
            </div>

          </div>

          <div class="info-container mt-2">
            <div>
              <b-form-group label="Valoarea totală a achiziției:">
                <b-input-group id="fieldset-2" append="LEI">
                  <b-form-input
                    id="input-2"
                    v-model="valoareInLei"
                    trim
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>

            <div class="valoarea-totala-eligibila">
              <b-form-group label="Valoarea totală eligibilă* a achiziției">
                <b-input-group id="fieldset-3" append="LEI">
                  <b-form-input
                    id="input-3"
                    v-model="valoareEligibilaInLei"
                    trim
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>

          <div class="info-container mt-2">
            <div>
              <b-form-group
                label="Cheltuieli neeligibile generate de diferența de schimb valutar:"
                description="Se va achita din contul curent (nu din contul de grant) - dacă se achită din contul de grant se va rambursa suma neeligibilă din contul curent în contul de grant "
              >
                <b-input-group id="fieldset-4" append="LEI">
                  <b-form-input
                    id="input-4"
                    :class="
                      this.valoareInLei > this.valoareEligibilaInLei
                        ? 'redInput'
                        : ''
                    "
                    v-model="diferentaSchimbValutar"
                    trim
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <div class="mt-3">
                <h5>
                  *Valoarea totală eligibilă reprezintă valoarea maximă ce poate fi
                  achitată din contul de grant. Această valoare se va diminua în
                  funcție de cazurile specifice:
                </h5>
                <ul>
                  <li>în factură există produse/servicii neeligibile</li>
                  <li>
                    în funcție de tipul de beneficiar trebuie avută în vedere
                    cheltuiala cu TVA
                  </li>
                  <li>
                    în factură există cheltuieli neeligibile (penalizări, comisioane
                    etc)
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <YoutubeEmbed link="MWqxssprVtM" />
  </div>
</template>

<script>
import {
  BAlert,
  BImg,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BFormDatepicker,
  BButton,
  BInputGroup,
} from 'bootstrap-vue'
import axios from '@axios'
import store from '@/store'
import https from 'https';
import YoutubeEmbed from '@/views/pages/m2-pages/components/YoutubeEmbed.vue'

export default {
  data: () => ({
    isPaid: false,
    isM2Manager: false,
    selected: '',
    valoareFactura: '',
    dataFactura: '',
    cursBNR: '',
    errorMoneda: '',
    cursPrestabilit: '',
    errorValoareFactura: '',
    erroareDataFactura: '',
    errorBnrValue: '',
    focused: false,
    valoareInLei: '',
    valoareEligibilaInLei: '',
    diferentaSchimbValutar: '',
    monede: [
      { name: 'EUR', id: 'eur-bg', cursPrestabilit: 4.8395 },
      { name: 'USD', id: 'usd-bg', cursPrestabilit: 4.0616 },
      { name: 'GBP', id: 'gbp-bg', cursPrestabilit: 5.4092 },
      { name: 'HUF', id: 'huf-bg', cursPrestabilit: 0.0136 },
      { name: 'BGN', id: 'bgn-bg', cursPrestabilit: 2.4744 },
      { name: 'CNY', id: 'cny-bg', cursPrestabilit: 0.592 },
      { name: 'MDL', id: 'mdl-bg', cursPrestabilit: 0.2462 },
      { name: 'CHF', id: 'chf-bg', cursPrestabilit: 4.4985 },
      //   { name: '', id: 'choose-moneda', cursPrestabilit: '' },
    ],
  }),
  components: {
    BAlert,
    BImg,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BFormDatepicker,
    BButton,
    BInputGroup,
    YoutubeEmbed
  },
  mounted() {
      this.showPayPopupHandler()
      this.isM2ManagerHandler()
  },
  methods: {
      isM2ManagerHandler() {
          let allPlans = store.getters['auth/user'].plans
          this.isM2Manager = !!allPlans.find(function (key) {
              return key.id === 2
          })
      },
      showPayPopupHandler() {
          let allPlans = store.getters['auth/user'].plans
          this.isPaid = !!allPlans.find(function (key) {
              return key.program_id === 1 && key.paid_confirmed !== null
          })
      },
    checkValue() {
      return (this.errorMoneda =
        'Selecteaza o moneda sau introdu cursul prestabilit')
    },
    checkValueFactura() {
      if (this.valoareFactura === '') {
        return (this.errorValoareFactura = 'Introdu valoarea facturii')
      }
    },
    checkBnrValue() {
        if (this.cursBNR === '') {
            return (this.errorBnrValue = 'Introdu cursul BNR')
        }
    },
    checkDataFactura() {
      if (this.dataFactura === '') {
        return (this.erroareDataFactura = 'Introdu data facturii')
      }
    },
    getCursBNR() {
      if (this.selected === '') this.checkValue()
      if (this.valoareFactura === '') this.checkValueFactura()
      if (this.dataFactura === '') this.checkDataFactura()
      if (this.cursBNR === '') this.checkBnrValue()
      if (
        this.selected != '' &&
        this.valoareFactura != '' &&
        this.dataFactura != '' &&
        this.cursBNR != ''
      ) {
        const newDate = this.dataFactura.replaceAll('-', '/')
        const newSelected = this.selected.toLowerCase()
        const urlBNR = `http://www.infovalutar.ro/bnr/${newDate}/${newSelected}`
        var self = this
        const agent = new https.Agent({
            rejectUnauthorized: false
        });

        axios({
          baseURL: urlBNR,
          url: '',
          timeout: 20000,
          withCredentials: false,
          responseType: 'json',
          method: 'get',
        }, { httpsAgent: agent })
          .then(function (response) {
            self.cursBNR = response.data
            self.getValueLei()
            self.getValueEligibilaLei()
            self.getDiferentaCurs()
          })
          .catch(function (response) {
            console.log(self.cursBNR, 'self.cursBNR')
              self.checkBnrValue()
              self.getValueLei()
              self.getValueEligibilaLei()
              self.getDiferentaCurs()
          })
      }
    },
    getValueLei() {
      let valoareTotalaAchizitie = parseFloat(this.valoareFactura) * this.cursBNR
      return (this.valoareInLei = valoareTotalaAchizitie.toFixed(4))
    },
    getValueEligibilaLei() {
      let monedaAleasa = this.monede.find(
        (moneda) => moneda.name === this.selected
      )
      let valoareTotalaAchizitieEligibila =
        parseFloat(this.valoareFactura) * monedaAleasa.cursPrestabilit
      return (this.valoareEligibilaInLei = valoareTotalaAchizitieEligibila.toFixed(
        4
      ))
    },
    getDiferentaCurs() {
      if (this.valoareInLei > this.valoareEligibilaInLei) {
        let difSchimbValutar = this.valoareInLei - this.valoareEligibilaInLei
        return (this.diferentaSchimbValutar = difSchimbValutar.toFixed(4))
      }
      return (this.diferentaSchimbValutar = 0)
    },
  },
}
</script>

<style>
.calculatorValutar .card p:not(.red) {
  text-align: center;
  margin-top: 34px;
}
.calculatorValutar .curs-prestabilit {
  font-size: 13px;
}
.calculatorValutar .monede-container .form-group > div {
  display: flex;
  flex-flow: wrap;
}
.calculatorValutar #eur-bg,
.calculatorValutar #usd-bg,
.calculatorValutar #gbp-bg,
.calculatorValutar #huf-bg,
.calculatorValutar #bgn-bg,
.calculatorValutar #cny-bg,
.calculatorValutar #mdl-bg,
.calculatorValutar #chf-bg,
.calculatorValutar #choose-moneda {
  width: 66px;
  height: 55px;
  background: url(./img/country-flags.png);
}
.calculatorValutar #eur-bg {
  background-position: -10px -10px;
}
.calculatorValutar #usd-bg {
  background-position: -193px -10px;
}
.calculatorValutar #gbp-bg {
  background-position: -365px -10px;
}
.calculatorValutar #huf-bg {
  background-position: -538px -10px;
}
.calculatorValutar #bgn-bg {
  background-position: -707px -10px;
}
.calculatorValutar #cny-bg {
  background-position: -879px -10px;
}
.calculatorValutar #mdl-bg {
  background-position: -1051px -10px;
}
.calculatorValutar #chf-bg {
  background-position: -1222px -10px;
}
.calculatorValutar #choose-moneda {
  background-position: -1354px -5px;
  margin-bottom: 5px;
}
.calculatorValutar input.inputCurs.form-control {
  height: 25px;
  width: 66px;
  margin-top: 5px;
}
.calculatorValutar .custom-control.custom-radio {
  margin-right: 20px;
}
.calculatorValutar .info-container {
  display: flex;
  flex-flow: wrap;
  align-items: center;
}
.calculatorValutar .info-container > div {
  margin-right: 50px;
}
.calculatorValutar .data-emiterii-facturii,
.calculatorValutar #input-1,
.calculatorValutar #fieldset-2,
.calculatorValutar #fieldset-3,
.calculatorValutar .curs-valutar-bnr {
  width: 250px;
}
.calculatorValutar #fieldset-4 {
  max-width: 450px;
}
.calculatorValutar .button-container {
  margin: 24px 0 10px;
}
.calculatorValutar .red {
  width: 100%;
  font-size: 0.857rem;
  color: #ea5455;
  position: absolute;
  margin-top: -15px;
}
.calculatorValutar .redInput,
.calculatorValutar .redInput + .input-group-append .input-group-text {
  border-color: #ea5455 !important;
}
</style>

