<template>
    <b-img
            :src="require('./img/live-chat.png')"
            alt="M2 Manager"
            fluid
    />
</template>

<script>
    import {
        BImg
    } from 'bootstrap-vue'
    export default {
        components: {
            BImg
        }
    }
</script>

